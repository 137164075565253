import React, {useEffect, useState} from "react";
import "../App.css";
import {makeStyles} from "@material-ui/core/styles";
import smile from "../bannerImage/Page_top_banner/smile_top.png";
import smileEducation from "../bannerImage/dbsSmile.jpg";
import {fetchPage} from "./hooks/inputHooK";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import Container from "@material-ui/core/Container";
import DraftEdit from "./admin/draftEdit";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    genBody: {
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            padding: '2%',
            width: '95%',
        },
    },
    gridy: {
        paddingLeft:'30px',
        paddingRight:'30px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft:'0px',
            paddingRight:'0px',
        },
    },
    img: {
        height:'100%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },
}));

export default function Smile(props) {
    const classes = useStyles();

    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'smile';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------

    return (
        <>
            <div>
                <img src={smile} alt='smale' className='topBanner'/>
            </div>
            <Container maxWidth="xl" className={classes.genBody}>
                <Grid container className={classes.gridy}>
                    <Grid item lg={12} xs={12} style={{marginTop: '0px'}}>
                        <h1 className='heading' style={{textAlign: 'center'}}>
                            {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                            {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                    <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                        <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                             onClick={() => {
                                                 handelEditor(getPageDb);
                                                 setBlock(getPageDb[0].block)
                                             }}
                                        >
                                            <Edit/>
                                        </Fab>
                                    </Tooltip>
                                </Box>
                                : <></>}
                        </h1>
                    </Grid>
                    <Grid item lg={6} xs={12} style={{marginTop: '0px'}}>
                        {/*<div className={classes.smileImg}/>*/}
                        <img src={smileEducation} alt='smile' className={classes.img}/>
                    </Grid>
                    <Grid item lg={6} xs={12} style={{marginTop: '0px'}}>
                        <text className='welcomeBody'
                              dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></text>
                    </Grid>
                </Grid>
                {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
            </Container>
        </>
    );
}