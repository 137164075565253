import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from "@material-ui/core/Box";
import TextButtons, {StyledMenuItem} from "./menuBar";
import AdminLogin from "./admin/adminLogin";
import Logo1 from '../bannerImage/dbs-logo.png'
import LogoutIcon from '@mui/icons-material/Logout';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// import Container from "@material-ui/core/Container";
// import TextField from "@material-ui/core/TextField";
// import Sidenav from "./Sidebar";
// import Gallery from "@material-ui/icons/Apps";
// import Button from "@material-ui/core/Button";
// import HomeIcon from "@material-ui/icons/Home";
// import Container from "@material-ui/core/Container";
// import Information from "./detailBody";
// import Logo from '../bannerImage/dbs-logo.png'
// import Modal from "@material-ui/core/Modal";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
// import Grid from "@material-ui/core/Grid";
// import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        '& .MuiAppBar-colorPrimary': {
            color: '#fff',
            backgroundColor: '#446600',
        },
        // [theme.breakpoints.up('sm')]: {
        //     width:'100%',
        // },
    },

    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            display: 'none !important',
        },
    },
    dbsLogo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    title: {
        flexGrow: 1,
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
            fontSize: '12px',
        },
    },
    logo: {
        width: '45px',
    }
}));


export default function MenuAppBar(props) {
    const classes = useStyles();
    // sessionStorage.setItem("language",'Eng');
    const [anchorEl, setAnchorEl] = useState(null);
    // const [getLanguage, setLanguage] = useState(localStorage.getItem('language'));
    if(localStorage.getItem('language')){localStorage.getItem('language');}else{localStorage.setItem('language','Eng');}

    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        props.appBarCallback(false)
    }

    function adminSessionClose() {
        setAnchorEl(null);
        props.appBarCallback('xx');
        sessionStorage.setItem('okAdmin', '');
    }

    // console.log('--- app bar language on load ---', getLanguage);
    const handleLanguage = (event) => {
        props.appBarCallback(event.target.value);
        localStorage.setItem('language',event.target.value);
        // setLanguage(event.target.value);
    };

    return (
        <div maxWidth="xl" className={classes.root} >
            <AppBar style={{position: '-webkit-sticky'}}>        {/*      style={{float:'left', position: '-webkit-sticky'}}   */}
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton}
                                onClick={handleDrawerToggle}
                                color="inherit" aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Box className={classes.dbsLogo}>
                        <img src={Logo1} alt="logo" className={classes.logo}/>
                    </Box>
                    <Typography variant="h6" className={classes.title}>
                        {/*style={{backgroundColor: 'red', width: '100'}}*/}
                        Dhanvantary Bioscience
                    </Typography>
                    <TextButtons/>
                    {/*</div>*/}
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            // color="inherit"
                        >
                            <AccountCircle style={{color: '#caa136'}}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            {/*<MenuItem onClick={loginOpen}>Login</MenuItem>*/}
                            {((sessionStorage.getItem('okAdmin')) === 'okAdmin')
                                ? (<StyledMenuItem>
                                    <LogoutIcon fontSize="small" style={{color: '#caa136'}}/>
                                    <MenuItem onClick={adminSessionClose}>Logout</MenuItem>
                                </StyledMenuItem>)
                                : <AdminLogin/>}
                        </Menu>
                    </div>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 90}} style={{float: 'right'}}>
                        <InputLabel id="demo-simple-select-standard-label"
                                    style={{color: '#fff', fontSize: '12px'}}>Language</InputLabel>
                        <Select style={{color: '#fff', fontSize: '13px'}}
                                // defaultValue={getLanguage?getLanguage:'Eng'}
                            value={localStorage.getItem('language')}
                                onChange={handleLanguage}
                        >
                            <MenuItem value={'Eng'}>English</MenuItem>
                            <MenuItem value={'Ben'}>Bengali</MenuItem>
                            <MenuItem value={'Hin'}>Hindi</MenuItem>
                        </Select>
                    </FormControl>
                    {/*)}*/}
                    {/*{(auth) ? (<Sidenav sNav={'yes yes yes '}/>) : (<Sidenav sNav={'No No No '}/>)}*/}
                </Toolbar>
            </AppBar>
        </div>
    );
}
