import React from 'react';
import "../App.css";
import noPage from "../bannerImage/animated-under-construction.gif";

export default function NoPage() {
    return (
        <div>
            <h2 align='center'>This page is</h2>
            <img src={noPage} alt={'noPage'} width='50%' height='auto' style={{marginTop:'20px', marginLeft:'30%' }}/>
        </div>
    );
}