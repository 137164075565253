import React from 'react';
import "../App.css";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import apnaApp from '../bannerImage/apna app banner1.jpg';
import whyDownload from '../bannerImage/whyDownloadClient.jpg';
import cctv from '../bannerImage/appBanner/cctv_survellience_banner.png';
import comp from '../bannerImage/appBanner/computer_service_banner.png';
import ebus from '../bannerImage/appBanner/e_business_banner.png';
import eStore from '../bannerImage/appBanner/electronics_store_banner.png';
import homeMaker from '../bannerImage/appBanner/housewife_banner.png';
import mobSrore from '../bannerImage/appBanner/mobile_store_banner.png';
import petGroom from '../bannerImage/appBanner/pet_grooming_banner.png';
import electrician from '../bannerImage/appBanner/electrician_banner.png';
import tour from '../bannerImage/appBanner/tour_travel_banner.png';


export default function ApnaApp() {
    return (
        // <div className={classes.root}>
        <>
            <Container maxWidth="xl">
                <div>
                    <img src={apnaApp} alt={'apnaApp'} style={{marginLeft: '-25px', marginTop: '64px', width: '104%'}}/>
                </div>
            </Container>

            <Container maxWidth="lg" style={{marginTop: '50px'}}>
                <Grid container spacing={0}>
                    {/*<Grid item lg={4} xs={12}>*/}
                    {/*    <MoreAyur/>*/}
                    {/*    /!*<HerbsList/>*!/*/}
                    {/*</Grid>*/}
                    <Grid item lg={12} xs={12} align={'center'}>
                        <div style={{
                            backgroundColor: '#446600',
                            padding: '8px',
                            borderRadius: '8px',
                            color: 'white',
                            fontWeight: 'bold'
                        }}>YOUR SUCCESS IS OUR RESPONSIBILITY
                        </div>
                    </Grid>
                    <Grid container spacing={2} style={{marginTop: '20px'}}>
                        <Grid item lg={5} xs={12} align={'center'}>
                            <div className='heading' style={{
                                padding: '10px',
                                borderRadius: '1px 25px',
                                background: 'linear-gradient(to right, green , yellow)'
                            }}>Do you have Dreams?
                            </div>
                        </Grid>
                        <Grid item lg={7} xs={12} align={'center'}>
                            <div className='heading' style={{
                                padding: '10px',
                                borderRadius: '1px 25px',
                                background: 'linear-gradient(to right, green , yellow)'
                            }}>Do you want to be an entrepreneur?
                            </div>
                        </Grid>
                        <Grid item lg={5} xs={12} align={'center'}>
                            <div className='heading' style={{
                                padding: '10px',
                                borderRadius: '1px 25px',
                                background: 'linear-gradient(to right, green , yellow)'
                            }}>Are you a Housewife-Teacher-Student- Doctor or a business owner?
                            </div>
                        </Grid>
                        <Grid item lg={7} xs={12} align={'center'}>
                            <div className='heading' style={{
                                padding: '10px',
                                borderRadius: '1px 25px',
                                background: 'linear-gradient(to right, green , yellow)'
                            }}>Are you not getting a direction to reach to many customers where you are having lots of
                                new business ideas?
                            </div>
                        </Grid>
                        <Grid item lg={5} xs={12} align={'center'}>
                            <div className='heading' style={{
                                padding: '10px',
                                borderRadius: '1px 25px',
                                background: 'linear-gradient(to right, green , yellow)'
                            }}>Are you unemployed and want a platform to excel in life?
                            </div>
                        </Grid>
                        <Grid item lg={7} xs={12} align={'center'}>
                            <div className='heading' style={{
                                padding: '10px',
                                borderRadius: '1px 25px',
                                background: 'linear-gradient(to right, green , yellow)'
                            }}>Are you a manufacturer and want to Trade your products more easily?
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container style={{marginTop: '20px'}}>
                        <Grid item lg={5} xs={12} align={'center'} >
                            <img src={whyDownload} alt={'whyDownload'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={7} xs={12} align={'center'} style={{padding: '30px'}}>
                            <h2>Dhanvantary Bio Science is offering your own Customised App to fulfill your dreams in
                                life through our own IT system.
                            </h2>
                            <div className='welcomeBody' align={'justify'} style={{padding: '40px'}}>
                                If you do not have enough business capital with you and still you want to run your
                                business-
                                Contact us. Just paying Rs. One Thousand you will get an APP worth Rs. One Lakh. Over
                                and above
                                we will give you continuous business support with a cash discount from 10% to 50% on our
                                different range of products and on month by month business performance you can earn up
                                to Crores
                                of rupees.
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginTop: '20px'}}>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={ebus} alt={'ebus'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={homeMaker} alt={'homeMaker'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={mobSrore} alt={'mobSrore'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={cctv} alt={'cctv'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={comp} alt={'comp'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={eStore} alt={'eStore'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={electrician} alt={'electrician'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={petGroom} alt={'petGroom'} style={{width: '100%'}}/>
                        </Grid>
                        <Grid item lg={4} xs={12} align={'center'}>
                            <img src={tour} alt={'tour'} style={{width: '100%'}}/>
                        </Grid>
                    </Grid>


                </Grid>
            </Container>
        </>
// </div>
    );
}