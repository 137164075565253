import product_top from "../bannerImage/Page_top_banner/product_top.png";
import Container from "@material-ui/core/Container";
import ProdCatList from "./productCatList";
import React from "react";

export default function ShowCase(props) {
    return (
        <>
            <div>
                <img src={product_top} alt={'image'} className={'topBanner'}/>
            </div>
            <Container maxWidth="xl">
                <ProdCatList/>
            </Container>
        </>
    )
}