import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import fs from "fs";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Pagination from '@mui/material/Pagination';
import usePagination from "./hooks/pagination";
import useWindowDimensions from "./hooks/utilityHooK";
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 250,
        minHeight: '290px',
        maxHeight: '290px',
        '& .MuiTypography-h5': {
            height: 35,
            fontSize: 14,
            fontFamily: "Helvetica",
            fontWeight: "bold",
            color: '#4d4d4d',
            [theme.breakpoints.down('sm')]: {
                fontSize: 11,
            },
        },
        '& .MuiTypography-body1': {
            fontSize: 12,
            fontFamily: "Roboto",
        },
        '& .CardMedia-root': {
            backgroundSize: '100%',
            flex: 1,
            transform: "scale(-50%, -50%)",
            minHeight: '100px',
            maxHeight: '100px',
            display: 'block',
            // backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
        },
    },
    media: {
        height: 0,
        paddingTop: '60%', // 16:9
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '98%',
        },
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductCard(props) {
    const classes = useStyles();
    const [getData, setData] = useState('');
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [getImg, setImg] = useState('');
    const [getName, setName] = useState('');
    // const [getCat, setCat] = useState(props.pCat);
    const [getDescription, setDescription] = useState('');
    const {height, width} = useWindowDimensions();
    const PER_PAGE = 16;
    const count = Math.ceil(getData.length / PER_PAGE);
    const _DATA = usePagination(getData, PER_PAGE);
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };
    let url = '';
    // let rem = 0;
    // if (getData.length > 4) {
    //     rem = getData.length % 4;
    // } else {
    //     rem = 4 - getData.length;
    // }
// console.log('ddddddddddddd',rem)
    async function fetchDB(cat) {
        sessionStorage.setItem('currCat', cat);
        try {
            if (cat) {
                console.log('111')
                url = `https://node.dhanvantary.com/query/dbs_products?q=[{"field":"category","opt":"==","value":"${cat}","id":true}]`;
            } else {
                url = `https://node.dhanvantary.com/query/dbs_products`;
            }
            const response = await fetch(`${url}`);

            const json = await response.json();
            setData(json);
            // console.log('aaaaaaaaaaaaaaaaaaaa',url);
        } catch (err) {
            throw err;
            // console.log(err);
        }
    }

    useEffect(() => {
        fetchDB(props.pCat);
    }, [props.pCat]);
    if(sessionStorage.getItem('currCat')!=props.pCat){
        fetchDB(props.pCat);
    }
    // if (props.pCat) {
    //     fetchDB(props.pCat);
    //     props.callFunction(false);
    // }
    if (getData.length > 0) {
        return (
            <Grid container style={{marginTop: '22px'}}>
                {_DATA.currentData().map((dt) => {
                    function image(imgObj) {
                        let img = '';
                        for (let i = 0; i < 3 && img === ''; i++) {
                            return imgObj[i];
                        }
                    }

                    let imgUrl = '';
                    // if (dt.images !== undefined) {
                    //     imgUrl = image(dt.images);
                    //     console.log(dt.images)
                    // }else {
                    //     imgUrl = 'https://node.dhanvantary.com/images/dbsweb/general_pages/1649661863252_noImage.png';
                    // }

                    try {
                        if (dt.images !== undefined) {
                            // if (fs.existsSync(dt.images)) {
                        //     //file exists
                            imgUrl = image(dt.images);
                        //     // console.log(imgUrl)
                        } else {
                            imgUrl = 'https://node.dhanvantary.com/images/dbsweb/general_pages/1649661863252_noImage.png';
                        }
                    } catch (err) {
                        console.error(err);
                    }

                    var nm = dt.name?.substring(0, 20) || '';
                    var desc = dt.description?.substring(0, 30) || '';

                    return (
                        <>
                            <Grid item xl={3} xs={6} spacing={2} style={{padding: '5px'}}>
                                <Card className={classes.root} style={{
                                    // padding:'5px',
                                    backgroundColor: '#efe'
                                }}>
                                    <CardHeader
                                        title={nm}
                                        // subheader={dateFormat(dt.updated,"mmmm dS, yyyy")}
                                    />
                                    <CardMedia
                                        className={classes.media}
                                        image={imgUrl}
                                        onClick={() => {
                                            setOpen(true);
                                            setName(dt.name);
                                            setDescription(dt.description);
                                            setImg(imgUrl);
                                        }}
                                        // title="Paella dish"
                                    />
                                    <CardContent>
                                        <Typography className={classes.root} variant="body2" color="textSecondary" component="p">
                                            {desc + '...'}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                // keepMounted
                                onClose={() => setOpen(false)}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title">{getName}</DialogTitle>
                                <div style={{margin: '20px'}}>
                                    <img src={getImg} alt={'Image not found'} style={{width: '90%',}}/>
                                </div>
                                <p style={{fontSize: '14px', padding: '0px 15px 5px 15px'}}>{getDescription}</p>
                            </Dialog>
                        </>
                    );
                })}
                <Grid item lg={12} xs={12}>
                {width > 800 ? <Pagination
                    count={count}
                    size="large"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                /> : <Pagination
                    count={count}
                    size="small"
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                />}
                </Grid>
            </Grid>

        );
    }else{
        return (
            <></>
        )
    }
}