import React from 'react';
import "./hexagon.css";
import Container from "@material-ui/core/Container";
import goc from "../bannerImage/Page_top_banner/group_of_companies.png";
import img01 from '../bannerImage/GrOfCo/logo_oishani_health_care.png';
import img02 from '../bannerImage/GrOfCo/logo_bima_duniya.png';
import img03 from '../bannerImage/GrOfCo/logo_dbs_rath.png';
import img04 from '../bannerImage/GrOfCo/logo_dbs_doctor.png';
import img05 from '../bannerImage/GrOfCo/logo_dbs_education.png';
import img06 from '../bannerImage/GrOfCo/logo_dbs_laxmi.png';
import img07 from '../bannerImage/GrOfCo/logo_dbs_it_solutions.png';
import img08 from '../bannerImage/GrOfCo/logo_dbs_smile.png';
import img09 from '../bannerImage/GrOfCo/Dhanvantary Pathsala.png';
import img10 from '../bannerImage/GrOfCo/logo_dbs_medicine.png';
// import "../App.css";
// import Grid from "@material-ui/core/Grid";
// import {makeStyles} from "@material-ui/core/styles";
export default function GroupOfComp() {
    // const classes = useStyles();
    return (
        <div className={'body'}>

            <div>
                <img src={goc} alt={'goc'} className={'topBanner'}/>
            </div>
            <Container maxWidth="md" style={{marginTop: '-10px'}}>
                <div className="gallery">
                    <div className="clipped-border" style={{padding: '5px'}}>
                        <img src={img01} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img02} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img03} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img04} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img05} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img06} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img07} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img08} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img09} className={'img'} id="clipped"/>
                    </div>
                    <div className="clipped-border">
                        <img src={img10} className={'img'} id="clipped"/>
                    </div>
                </div>
            </Container>
        </div>
    );
}