// import Container from "@material-ui/core/Container";
import {Editor} from "react-draft-wysiwyg";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
// import {makeStyles} from "@material-ui/core/styles";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// import {useInput} from "../hooks/inputHoK";
import swal from "sweetalert2";
import DBPath from "./dbPath";
import draftToHtml from "draftjs-to-html";

let dbPath = DBPath();

export default function DraftEdit(props) {
    // const [getLanguage, setLanguage] = useState(sessionStorage.getItem("language"));
    const [getLanguage, setLanguage] = useState(props.info.lang);
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [getHead, setHead] = useState('');

    const [open, setOpen] = useState(props.info.state);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        // alert(getLanguage)
        async function fetchDB(block) {
            try {
                let url = `${dbPath}/${props.info.collection}?q=[{"field":"${props.info.findSt}","opt":"==","value":"${props.info.page}"},{"field":"${props.info.findNd}","opt":"==","value":"${block}"}]`;
                // console.log('UUUUUUUUuu',url);
                let response = await fetch(url);
                response = await response.json();
                if (response[0][`head${getLanguage}`]) {
                    setHead(response[0][`head${getLanguage}`]);
                    if (typeof (response[0][`body${getLanguage}`]) == 'object') {
                        const contentState = convertFromRaw(response[0][`body${getLanguage}`]);
                        const editorState = EditorState.createWithContent(contentState);
                        setEditorState(editorState);
                    } else {
                        setEditorState("No data found.");
                    }
                } else {
                    setEditorState("")
                }
            }catch(e){
                console.error(e);
            }
        }
        fetchDB(props.block);
    }, []);

    {/*
    const saveChange=async(evt)=>{
        let parameter = [];
        let detail = {};
        let json = {};
        let title = `head${props.language}`;
        let text = `body${props.language}`;
        let param = {
            field: "page",
            opt: "==",
            value: 'welcome'
        }

        json = {
            [title]: Name,
            [text]: convertToRaw(getNewEditor.getCurrentContent())
        }

        try {
            const response = await fetch(`${dbPath}/pages?q=[${JSON.stringify(parameter)}]`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            });
            // const data = response;
            swal.fire({ icon: 'success', title: 'Successfully Updated',timer: 2000});
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
    }
    */
    }
    // {/*
    const saveChange = async(evt) => {
        // let searcha = [];
        // let searchb = [];
        // let detail = {};
        let json = {};
        let title = `head${getLanguage}`;
        let text = `body${getLanguage}`;
        let parama = {
            field: props.info.findSt,
            opt: "==",
            value: props.info.page
        }
        let paramb = {
            field: props.info.findNd,
            opt: "==",
            value: props.block
        }
        json = {
            [title]: getHead,
            [text]: convertToRaw(editorState.getCurrentContent())
        }
        let url = `${dbPath}/pages?q=[${JSON.stringify(parama)},${JSON.stringify(paramb)}]`;
        let body= JSON.stringify(json);
        // console.log('xxxxxxxxxxx',url);
        try {
            let url = `${dbPath}/pages?q=[${JSON.stringify(parama)},${JSON.stringify(paramb)}]`;
            const response = fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            });
            // const data = response;
            setOpen(false);
            // swal.fire({icon: 'success', title: 'Successfully Saved', timer: 1000});
        }
        catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
        handleClose()
    }
// */}
    const handleClose = () => {
        props.successCallBack(false,props.block,getHead,editorState);
    };
    let onStateChange = editorState => {
        setEditorState(editorState);
        console.log('last ',draftToHtml(editorState))
    };

    let changeName =(e) => {
        setHead(e.target.value);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title"> {`Edit ${getLanguage}`} </DialogTitle>
            <Grid item xs={12} lg={12} style={{marginLeft: '30px'}}>
                <TextField value={getHead} onChange={changeName} required={true} style={{fontSize: '10px'}}/>
            </Grid>
            <DialogContent>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={onStateChange}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={saveChange}>
                    Save Changes
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Discard
                </Button>
            </DialogActions>
        </Dialog>
    );
}