import React, {useEffect, useState} from 'react';
import "../App.css";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import management from "../bannerImage/Page_top_banner/management_web.jpg";
import {makeStyles} from "@material-ui/core/styles";
import Ashutosh from '../bannerImage/managers/Ashutosh.jpeg';
import Chandan from '../bannerImage/managers/Chandan.jpeg';
import pradeep from '../bannerImage/managers/pradeep.jpeg';
import ankit from '../bannerImage/managers/Ankit.jpg';
import santosh from '../bannerImage/managers/Santosh.jpg';
import {fetchPage} from "./hooks/inputHooK";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import DraftEdit from "./admin/draftEdit";
import Scroll from "./scrollTop";
// import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles((theme) => ({
    headingBox: {
        borderRadius: '8px',
        padding: '10px 0px 5px 30px',
        marginTop: '50px',
        fontFamily: 'Old Standard TT',
        fontSize: '25px',
        background: 'linear-gradient(to right, #caa136, #eeffcc)',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
            padding: '10px 0px 5px 10px',
            fontSize: '14px',
        },
    },
    name: {
        fontSize: '18px',
        paddingLeft: '20px', fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            display: 'block',
        },
    },
    mgmImage: {
        borderRadius: '100px',
        width: '180px',
        marginTop: '-40px',
        marginLeft: '50px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '22%',
        },
    },
    // imgBak: {
    //     borderRadius: '100px',
    //     backgroundColor: '#4e9525',
    //     opacity: '.302',
    //     position: 'absolute',
    //     // top: '-35px',
    //     left: '130px',
    //     zIndex: '-1',
    //     width: '15%',
    //     height: '27%',
    //     [theme.breakpoints.down('sm')]: {
    //         display: 'none',
    //     },
    // },
}));


export default function Mgmt(props) {
    const classes = useStyles();
    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'management';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }
    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
                // console.log(json)
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------
    return (
        <>
            <div>
                {/*<Grid item xs={12}>*/}
                <img src={management} alt={'management'} className={'topBanner'}/>
                {/*</Grid>*/}
            </div>

            <div>
                <Container maxWidth="lg" style={{marginTop: '30px'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <h3 className={classes.headingBox}>
                                {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                        <Tooltip title={'Edit (' + getLanguage+')'} aria-label="Edit">
                                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                 onClick={() => {
                                                     handelEditor(getPageDb);
                                                     setBlock(getPageDb[0].block)
                                                 }}
                                            >
                                                <Edit/>
                                            </Fab>
                                        </Tooltip>
                                    </Box>
                                    : <></>}
                            </h3>
                            <p align={'left'}  style={{padding: '20px', fontWeight:'bold'}}
                                  dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></p>
                        </Grid>
                        {/*-----------------------------------------------------------------------------------*/}
                        <Grid item lg={12} xs={12}>
                            <div className={classes.headingBox}>General Manager
                                <span className={classes.name}>
                                    {getPageDb[1] ? getPageDb[1][`head${getLanguage}`] : ''}
                                    {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                        <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                            <Tooltip title={'Edit (' + getLanguage+')'} aria-label="Edit">
                                                <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                     onClick={() => {
                                                         handelEditor(getPageDb);
                                                         setBlock(getPageDb[1].block)
                                                     }}
                                                >
                                                    <Edit/>
                                                </Fab>
                                            </Tooltip>
                                        </Box>
                                        : <></>}
                                </span></div>
                        </Grid>
                        <Grid item lg={4} xs={12} style={{marginTop: '50px'}}>
                            <img src={Ashutosh} alt={'Ashutosh'} className={classes.mgmImage} align={'center'}/>
                        </Grid>
                        <Grid item lg={7} xs={12} style={{paddingTop: '20px'}}>
                            <span align={'left'} dangerouslySetInnerHTML={{__html: `${getPageDb[1] ? draftToHtml(getPageDb[1][`body${getLanguage}`]) : ''}`}}></span>
                        </Grid>
                        {/*-----------------------------------------------------------------------------------*/}
                        <Grid item lg={12} xs={12}>
                            <div className={classes.headingBox}>Deputy General Manager
                                <span className={classes.name}>
                                    {getPageDb[4] ? getPageDb[4][`head${getLanguage}`] : ''}
                                    {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                        <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                            <Tooltip title={'Edit (' + getLanguage+')'} aria-label="Edit">
                                                <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                     onClick={() => {
                                                         handelEditor(getPageDb);
                                                         setBlock(getPageDb[4].block)
                                                     }}
                                                >
                                                    <Edit/>
                                                </Fab>
                                            </Tooltip>
                                        </Box>
                                        : <></>}
                                </span></div>
                        </Grid>
                        <Grid item lg={4} xs={12} style={{marginTop: '50px'}}>
                            <img src={ankit} alt={'Ankit'} className={classes.mgmImage} align={'center'}/>
                        </Grid>
                        <Grid item lg={7} xs={12} style={{paddingTop: '20px'}}>
                            <span align={'left'} dangerouslySetInnerHTML={{__html: `${getPageDb[4] ? draftToHtml(getPageDb[4][`body${getLanguage}`]) : ''}`}}></span>
                        </Grid>
                        {/*-----------------------------------------------------------------------------------*/}
                        <Grid item xs={12} md={12} style={{marginTop: '30px'}}>
                            <div className={classes.headingBox}>Country Head (Marketing)
                                <span className={classes.name}>
                                    {getPageDb[2] ? getPageDb[2][`head${getLanguage}`] : ''}
                                    {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                        <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                            <Tooltip title={'Edit (' + getLanguage+')'} aria-label="Edit">
                                                <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                     onClick={() => {
                                                         handelEditor(getPageDb);
                                                         setBlock(getPageDb[2].block)
                                                     }}
                                                >
                                                    <Edit/>
                                                </Fab>
                                            </Tooltip>
                                        </Box>
                                        : <></>}
                                </span></div>
                        </Grid>
                        <Grid item lg={4} xs={12} style={{marginTop: '50px'}}>
                            <img src={Chandan} alt={'Chandan'} className={classes.mgmImage} align={'center'}/>
                        </Grid>
                        <Grid item lg={7} xs={12} style={{paddingTop: '20px'}}>
                            <span align={'left'} dangerouslySetInnerHTML={{__html: `${getPageDb[2] ? draftToHtml(getPageDb[2][`body${getLanguage}`]) : ''}`}}></span>
                        </Grid>
                        {/*-----------------------------------------------------------------------------------*/}
                        <Grid item xs={12} md={12} style={{marginTop: '30px'}}>
                            <div className={classes.headingBox}>Product Development Manager & Trainer
                                <span className={classes.name}>
                                    {getPageDb[3] ? getPageDb[3][`head${getLanguage}`] : ''}
                                    {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                        <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                            <Tooltip title={'Edit (' + getLanguage+')'} aria-label="Edit">
                                                <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                     onClick={() => {
                                                         handelEditor(getPageDb);
                                                         setBlock(getPageDb[3].block)
                                                     }}
                                                >
                                                    <Edit/>
                                                </Fab>
                                            </Tooltip>
                                        </Box>
                                        : <></>}
                                </span></div>
                        </Grid>
                        <Grid item lg={4} xs={12} style={{marginTop: '50px'}}>
                            <img src={pradeep} alt={'pradeep'} className={classes.mgmImage} align={'center'}/>
                        </Grid>
                        <Grid item lg={7} xs={12} style={{paddingTop: '20px'}}>
                            <span align={'left'} dangerouslySetInnerHTML={{__html: `${getPageDb[3] ? draftToHtml(getPageDb[3][`body${getLanguage}`]) : ''}`}}></span>
                        </Grid>
                        {/*-----------------------------------------------------------------------------------*/}
                        <Grid item xs={12} md={12} style={{marginTop: '30px'}}>
                            <div className={classes.headingBox}>Dhanvantary Pathshala Guru
                                <span className={classes.name}>
                                    {getPageDb[5] ? getPageDb[5][`head${getLanguage}`] : ''}
                                    {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                        <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                            <Tooltip title={'Edit (' + getLanguage+')'} aria-label="Edit">
                                                <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                     onClick={() => {
                                                         handelEditor(getPageDb);
                                                         setBlock(getPageDb[5].block)
                                                     }}
                                                >
                                                    <Edit/>
                                                </Fab>
                                            </Tooltip>
                                        </Box>
                                        : <></>}
                                </span></div>
                        </Grid>
                        <Grid item lg={4} xs={12} style={{marginTop: '50px'}}>
                            <img src={santosh} alt={'santosh'} className={classes.mgmImage} align={'center'}/>
                        </Grid>
                        <Grid item lg={7} xs={12} style={{paddingTop: '20px'}}>
                            <span align={'left'} dangerouslySetInnerHTML={{__html: `${getPageDb[5] ? draftToHtml(getPageDb[5][`body${getLanguage}`]) : ''}`}}></span>
                        </Grid>
                        {/*-----------------------------------------------------------------------------------*/}
                    </Grid>
                    {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
                    <Scroll showBelow={1000} />
                </Container>
            </div>
        </>
    );
}