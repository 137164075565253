import React, {useEffect, useState} from 'react';
import "../App.css";
import {Link} from "react-router-dom";
import List from "@mui/material/List";
import GetSortOrder, {toTitleCase} from "./hooks/inputHooK";
import DBPath from "./admin/dbPath"
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import useWindowDimensions from "./hooks/utilityHooK";

// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
let dbPath = DBPath();

export default function YogaList(props) {

    // const classes = useStyles();
    // const [gotDocName, getDocName] = useState('');
    const [newvalue, setNewValue] = useState('');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const currentURL = window.location.pathname;
    let collection = 'yoga';
    // function getDetails(param) {
    //     getDocName(param);
    // }


    useEffect(() => {
        // setLanguage(localStorage.getItem('language'));
        async function fetchMyAPI(collection) {
            // let url = `${dbPath}/${c}`;
            try{
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                response = await response.json();
                let menu_sorted = response.sort(GetSortOrder("name"));
                setNewValue(menu_sorted);
                if (localStorage.getItem("objId")) {
                    props.callListYoga(JSON.stringify(menu_sorted.filter(item => item._id == localStorage.getItem("objId"))[0]));
                    // console.log('xxxxxxxxxxx',JSON.stringify(menu_sorted[0]))
                } else {
                    props.callListYoga(JSON.stringify(menu_sorted[0]));
                    // console.log('yyyyyyyyyyyy',JSON.stringify(menu_sorted[0]))
                }
            }catch (e) {
                console.error(e);
            }
        }
        fetchMyAPI(collection);
    }, []);

    return (
        <>
            <div>
                <p className="heading" style={{
                    margin: "0% 10% 5% 5%",
                    padding: "2% 0% 2% 5%",
                    backgroundColor: "#8cd674",
                }}>Yoga</p>
                <List className='sideList'
                      style={{marginLeft: '5%',
                          marginTop: '-5%',
                          paddingRight: '5px',
                          maxHeight: "400px",}}>
                    {newvalue ? newvalue.map((data) => {
                        return (
                            <ul>
                                {/*<Button className={classes.slideLink} onClick={() => getDetails(data.name)}>{data.name}</Button>*/}
                                <li style={{
                                    padding: '3px 0px 3px 0px',
                                    margin: '6px 0px 6px 0px',
                                    backgroundColor: "#fff",
                                }}
                                >
                                    {/*<Link className='sideLink' to={{pathname: "/more"}}>{toTitleCase(data.name)}</Link>*/}
                                    <Link
                                        className='sideLink'
                                        to={{
                                            pathname: "/yoga",
                                            // state: {data:data.name}
                                            // lang: getLanguage,
                                        }}
                                        style={localStorage.getItem('objId') === data._id ? {
                                            color: "black", fontWeight: "bold" } : {color: "#446600"}}
                                        onClick={() => {
                                            props.callListYoga(JSON.stringify(newvalue.filter(par => par._id === data._id)[0]));
                                            localStorage.setItem('objId', data._id)
                                            // localStorage.setItem('yogaDetails', JSON.stringify(newvalue.filter(par => par._id === data._id)[0]));
                                            // localStorage.setItem('yogaName', data.name)
                                        }}
                                        // onClick={() => {
                                        //     localStorage.setItem('yoga', data.name)
                                        // }}
                                    >{data.name}</Link>
                                </li>
                            </ul>
                        );
                    }) : <></>}
                </List>
            </div>
        </>
    );
}

export function YogaDropList(props) {
    // const classes = useStyles();
    const [getList, setList] = useState('');
    const [getDoc, setDoc] = useState('');
    // const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang :localStorage.getItem('language'));
    let collection = 'yoga';
    collection = collection.concat(getLanguage);

    useEffect(() => {
        async function fetchList(collection) {
            let url = `${dbPath}/yoga`;
            let response = await fetch(url);
            const json = await response.json();
            let menu_sorted = json.sort(GetSortOrder("name"));
            setList(menu_sorted);
            if(localStorage.getItem("objId")) {
                props.callDropYoga(JSON.stringify(menu_sorted.filter(item => item._id == localStorage.getItem("objId"))[0]));
            }else{
                props.callDropYoga(JSON.stringify(menu_sorted[0]));
            }
        }
        fetchList(collection);
    }, [collection]);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {getList.length > 0 ?
                        <FormControl sx={{width: 1}}>
                            <Autocomplete
                                // freeSolo
                                value={localStorage.getItem("objId") ? getList.filter(par => par._id === String(localStorage.getItem("objId")))[0] : {name: ''}}
                                options={getList}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    // localStorage.setItem('colName', 'yoga');
                                    // localStorage.setItem('yoga', value.name)
                                    // window.location.assign("/yoga");
                                    localStorage.setItem('objId', value._id)
                                    if(value){
                                        // setDoc(JSON.stringify(getList.filter(par => par._id === value._id)[0]));
                                        props.callDropYoga(JSON.stringify(getList.filter(par => par._id === value._id)[0]));
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Yoga"
                                    />
                                )}
                            />
                        </FormControl> :
                        <></>
                    }
                </Grid>
            </Grid>
        </Container>
    );
}