import React, {useEffect, useState} from "react";
// import swal from "sweetalert2";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
// import TextField from "@material-ui/core/TextField";
// import {Editor} from "react-draft-wysiwyg";
// import Button from "@material-ui/core/Button";
import DBPath from "../admin/dbPath";

let dbPath = DBPath();

export const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const str = /^[0-9a-zA-Z(),][0-9a-zA-Z-(), ]*$/;
    const num = /^[0-9]$/;
    return {
        value,
        setValue,
        reset: () => setValue(""),
        bind: {
            value,
            onChange: event => {
                if ((value.length === 0) || str.test(value)) {
                    if (event.target.name == 'string') {
                        let value = String(event.target.value);
                        if (str.test(value)) {
                            setValue(event.target.value);
                        }
                    } else if (event.target.name == 'number') {
                        if (num.test(value)) {
                            setValue(event.target.value);
                        }
                    } else {
                        setValue(event.target.value);
                    }
                }
            }
        }
    };
};

export const toTitleCase = str => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export async function fetchPage(fildValue) {
    let url = `${dbPath}/pages?q=[{"field":"page","opt":"==","value":"${fildValue}"}]`;
    let response = await fetch(url);
    return (response);
}

export default function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}


// export async function fetchList(collection) {
//     let url = `${dbPath}/${collection}`;
//     let response = await fetch(url);
//     return (response);
//     // response = await response.json()
//     // if (response.length > 0) {
//     //     findDoc(response[0].name);
//     // }
//     // setNewValue(response);
// }