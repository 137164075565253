import React, {useEffect, useState} from "react";
import "../App.css";
import {makeStyles} from "@material-ui/core/styles";
import faqs from "../bannerImage/Page_top_banner/faq.jpg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import List from "@mui/material/List";
import {Link} from "react-router-dom";
import FaqQusAns from './faqQusAns';
import DBPath from "./admin/dbPath";
import GetSortOrder, {toTitleCase} from "./hooks/inputHooK";
import kyb from "../bannerImage/kyb.jpg";
import useWindowDimensions from "./hooks/utilityHooK";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import FaqList from "./faqList";
// import MoreAyur from "./moreAyur";
// import DiseasesList from "./diseasesList";
// import HerbsList from "./herbsList";
// import DietChartList from "./dietChartList";
// import KnoWhat from "./knowhat";
// import Information from "./detailBody";
// import diseases from "../bannerImage/disease-virology.jpg";
// import herbs from "../bannerImage/herbs.jpg";
// import diet from "../bannerImage/Diet1.jpg";

let dbPath = DBPath();
const useStyles = makeStyles((theme) => ({
    head: {
        fontFamily: 'Old Standard TT',
        fontSize: '25px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
}));

export default function FAQ(props) {
    const classes = useStyles();
    const [getSubject, setSubject] = useState('Diseases');
    const [getObject, setObject] = useState('');
    const [getSubList, setSubList] = useState('');
    const [getvalue, setValue] = useState('');
    const {height, width} = useWindowDimensions();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    let distinctSubject = '';
    let collection = 'faq';
    collection = (collection.concat(getLanguage));
    localStorage.setItem('colName', collection);

    useEffect(() => {
        async function fetchFaq(c) {
            try{
                let url = `${dbPath}/${c}`; //dist?q=subject`; ?q=[{"field":"subject","opt":"==","value":"${getSubject}"}]
                let response = await fetch(url);
                response = await response.json();
                distinctSubject = [...new Set(response.map(data => data.subject))];
                setSubList(distinctSubject);
                fetchSubject(getSubject);
            }catch (e) {
                console.error(e);
            }
        }
        fetchFaq(collection);
    }, [collection]);

    function fetchSubject(par) {
        setSubject(par);
        async function fetchSub(c) {
            let urls = `${dbPath}/${c}?q=[{"field":"subject","opt":"==","value":"${par}"}]`;
            let responses = await fetch(urls);
            responses = await responses.json();
            responses = responses.sort(GetSortOrder("name"));
            setObject(responses);
        }
        fetchSub(localStorage.getItem('colName'));
        // setValue(par.replace(/ /g, '').toLowerCase());
        // sessionStorage.setItem('colName', (par.replace(/ /g, '').toLowerCase()));
    }

    const handleSubject = (event) => {
        setSubject(event.target.value);
        sessionStorage.setItem('subject', event.target.value);
    }

    const [getFaqs, setFaqs] = useState('');
    async function fetchDb(collection,sub,name) {
        try{
            let url = `${dbPath}/${collection}?q=[{"field":"subject","opt":"==","value":"${sub}"},{"field":"name","opt":"==","value":"${name}"}]`;
            let response = await fetch(url)
            response = await response.json()
            if (response.length > 0) {
                setFaqs(response[0].details);
            }
        }catch (e) {
            console.error(e);
        }
    }

    function getDetails(param) {
        // setValue(param);
        setFaqs(getObject.filter(item=>item.name==param)[0].details)
    }

    function DetailFaq(props) {
        return (
            <FaqQusAns obj={props.obj} lang={getLanguage}/>
        )
    }

    return (
        <>
            <div>
                <img src={faqs} alt={faqs} className={'topBanner'}/>
            </div>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12}>
                        <FormControl variant="standard" sx={{width: 1, maxWidth: '280px'}}
                                     style={{marginTop: '10px', marginBottom: '20px', marginLeft: '6%'}}>
                            <InputLabel id="demo-simple-select-standard-label">Select Subject</InputLabel>
                            <Select
                                defaultValue={'Diseases'}
                                onChange={handleSubject}
                            >
                                {getSubList ? getSubList.map((data, index) => (
                                    <MenuItem value={data}
                                              onClick={() => [fetchSubject(data)]}>{toTitleCase(data)}</MenuItem>
                                )) : <></>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={7} xs={12} align={'center'}>
                        {getLanguage === 'Eng' ?
                            <h3 className={classes.head} style={{marginRight: '30px'}}>Frequently Asked Questions</h3> :
                            (getLanguage === 'Ben' ?
                                <h3 className={classes.head} style={{marginRight: '30px'}}>প্রায়শই জিজ্ঞাসিত প্রশ্ন</h3> :
                                <h3 className={classes.head} style={{marginRight: '30px'}}>अक्सर पूछे जाने वाले
                                    प्रश्न</h3>)}
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12}>
                        {width > 800 ?
                            <div>
                                <p className="heading" style={{
                                    margin: "7% 10% 5% 5%",
                                    padding: "2% 0% 2% 5%",
                                    backgroundColor: "#8cd674",
                                }}>All {toTitleCase(getSubject)}</p>
                                <List className='sideList' style={{marginLeft: '5%', marginTop: '-5%',}}>
                                    {getObject ?
                                        getObject.map(
                                            (objectName) => <ul>
                                                <li style={{
                                                    padding: '3px 0px 3px 0px',
                                                    margin: '6px 0px 6px 0px',
                                                    backgroundColor: "#fff",
                                                }}><Link
                                                    className='sideLink' to={{
                                                    pathname: "/faq",
                                                    // state: {docName: objectName.name, colName: 'diseases'}
                                                }}
                                                    onClick={() => getDetails(objectName.name)}>{objectName.name}</Link>
                                                </li>
                                            </ul>
                                        ) : <></>
                                    }
                                </List>
                                <div style={{marginTop: '20px'}}>
                                    <a href={'/prakriti'}><img src={kyb} alt={'kyb'}
                                                               style={{marginLeft: '25px', width: '85%'}}/></a>
                                </div>
                            </div> :
                            <>
                                {getObject ?
                                    <FormControl sx={{width: '85%', ml: '7%', mt: '10px'}}>
                                        <Autocomplete
                                            // freeSolo
                                            value={sessionStorage.getItem("objId") ? getObject.filter(par => par._id === String(sessionStorage.getItem("objId")))[0] : {name: ''}}
                                            options={getObject}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, value) => {
                                                // localStorage.setItem('colName', collection);
                                                // localStorage.setItem('faqDoc', value.name);
                                                getDetails(value.name);
                                                // alert(value.name);
                                                // window.location.assign("/faq");
                                                sessionStorage.setItem('objId', value._id);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Faq About"
                                                />
                                            )}
                                        />
                                    </FormControl> :
                                    <></>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item lg={7} xs={12}>
                        <DetailFaq obj={getFaqs}/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}