import imageCompression from "browser-image-compression";
import React, {useEffect, useState} from "react";

const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
const uploadImageToserver = async (e,pathname,typ)=> {
    let imss= '';
    if (e) {
        // console.log(e);
        let filename = e.name;
        // console.log(filename);
        return sleep(1000).then( async responsesss => {
            const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            let compressedFile='';
            // console.log("----upload-----------");
            if(typ==="mp3"){
                compressedFile = e;
            }else{
            compressedFile = await imageCompression(e, options);
            }
            let file = new File([compressedFile], filename, {type: "image/jpeg", lastModified: Date.now()});
            const formData = new FormData();
            formData.append('photo', file);
            if(pathname[1]==="clientapp"){pathname[1]="papaapp"}
            try {
                // console.log(`https://node.dhanvantary.com/3/upload?database=${pathname[1]}&folder=${pathname[0]}`);
                let resp = await fetch(`https://node.dhanvantary.com/3/upload?database=${pathname[1]}&folder=${pathname[0]}`, {
                        method: 'POST',
                        body: formData,
                    }
                );
                imss = await resp.json();
                return imss.url;
            } catch (e) {
                console.log(e);
                return '';
            }
        });
    }
}
export default uploadImageToserver;