import * as React from "react";
import './imageMenu.css';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// import about_us_01 from '../bannerImage/imageMenu/about_us_01.png';
// import about_us_02 from '../bannerImage/imageMenu/about_us_02.png';
// import about_us_03 from '../bannerImage/imageMenu/about_us_03.png';
// import ayurveda_01 from '../bannerImage/imageMenu/ayurveda_01.png';
// import ayurveda_02 from '../bannerImage/imageMenu/ayurveda_02.png';
// import ayurveda_03 from '../bannerImage/imageMenu/ayurveda_03.png';
// import business_opportunity_01 from '../bannerImage/imageMenu/business_opportunity_01.png';
// import business_opportunity_02 from '../bannerImage/imageMenu/business_opportunity_02.png';
// import business_opportunity_03 from '../bannerImage/imageMenu/business_opportunity_03.png';
// import certification_01 from '../bannerImage/imageMenu/certification_01.png';
// import certification_02 from '../bannerImage/imageMenu/certification_02.png';
// import certification_03 from '../bannerImage/imageMenu/certification_03.png';
// import classical_formula_01 from '../bannerImage/imageMenu/classical_formula_01.png';
// import classical_formula_02 from '../bannerImage/imageMenu/classical_formula_02.png';
// import classical_formula_03 from '../bannerImage/imageMenu/classical_formula_03.png';
// import distributor_01 from '../bannerImage/imageMenu/distributor_01.png';
// import distributor_02 from '../bannerImage/imageMenu/distributor_02.png';
// import distributor_03 from '../bannerImage/imageMenu/distributor_03.png';
// import doctor_01 from '../bannerImage/imageMenu/doctor_01.png';
// import doctor_02 from '../bannerImage/imageMenu/doctor_02.png';
// import doctor_03 from '../bannerImage/imageMenu/doctor_03.png';
// import identify_disease_01 from '../bannerImage/imageMenu/identify_disease_01.png';
// import identify_disease_02 from '../bannerImage/imageMenu/identify_disease_02.png';
// import identify_disease_03 from '../bannerImage/imageMenu/identify_disease_03.png';
// import know_your_body_01 from '../bannerImage/imageMenu/know_your_body_01.png';
// import know_your_body_02 from '../bannerImage/imageMenu/know_your_body_02.png';
// import know_your_body_03 from '../bannerImage/imageMenu/know_your_body_03.png';
// import offers_01 from '../bannerImage/imageMenu/offers_01.png';
// import offers_02 from '../bannerImage/imageMenu/offers_02.png';
// import offers_03 from '../bannerImage/imageMenu/offers_03.png';
// import order_01 from '../bannerImage/imageMenu/order_01.png';
// import order_02 from '../bannerImage/imageMenu/order_02.png';
// import order_03 from '../bannerImage/imageMenu/order_03.png';
// import product_01 from '../bannerImage/imageMenu/product_01.png';
// import product_02 from '../bannerImage/imageMenu/product_02.png';
// import product_03 from '../bannerImage/imageMenu/product_03.png';
// import product_catalouge_01 from '../bannerImage/imageMenu/product_catalouge_01.png';
// import product_catalouge_02 from '../bannerImage/imageMenu/product_catalouge_02.png';
// import product_catalouge_03 from '../bannerImage/imageMenu/product_catalouge_03.png';
// import smile_01 from '../bannerImage/imageMenu/smile_01.png';
// import smile_02 from '../bannerImage/imageMenu/smile_02.png';
// import smile_03 from '../bannerImage/imageMenu/smile_03.png';
// import support_01 from '../bannerImage/imageMenu/support_01.png';
// import support_02 from '../bannerImage/imageMenu/support_02.png';
// import support_03 from '../bannerImage/imageMenu/support_03.png';
// import terms_conditions_01 from '../bannerImage/imageMenu/terms_conditions.png_01.png';
// import terms_conditions_02 from '../bannerImage/imageMenu/terms_conditions.png_02.png';
// import terms_conditions_03 from '../bannerImage/imageMenu/terms_conditions.png_03.png';
// import usefull_herbs_01 from '../bannerImage/imageMenu/usefull_herbs_01.png';
// import usefull_herbs_02 from '../bannerImage/imageMenu/usefull_herbs_02.png';
// import usefull_herbs_03 from '../bannerImage/imageMenu/usefull_herbs_03.png';
// import why_dhanvantary_01 from '../bannerImage/imageMenu/why_dhanvantary_01.png';
// import why_dhanvantary_02 from '../bannerImage/imageMenu/why_dhanvantary_02.png';
// import why_dhanvantary_03 from '../bannerImage/imageMenu/why_dhanvantary_03.png';

// import Grid from "@material-ui/core/Grid";

export default function imageMenu() {
    return (
        <>
            <Container maxWidth="xl" style={{marginTop: '50px',
                textAlign: 'center',
                backgroundColor:'#446600',
                minWidth:'97%',
                maxWidth:'97%',}}>
                <p style={{padding: '8px', color: 'white', fontWeight: 'bold'}}>Explore Dhanvantary Bioscience</p>
            </Container>

            <Container maxWidth="lg">
                <Grid container spacing={0}>
                    {itemData.map((item) => (
                    <Grid item lg={2} md={4} xs={6}>
                        <div className="cube-container">
                            <div className="photo-cube">
                                <img className="front" src={`${item.img1}`} alt={""}/>
                                <div className="back photo-desc"
                                     onClick={() => {localStorage.setItem('colName', `${item.tab}`)}}>
                                    <h3>{`${item.title}`}</h3>
                                    <p>{`${item.caption}`}</p>
                                    <a href={item.lnk} className="button">View Details</a>
                                </div>
                                <img className="left" src={`${item.img2}`} alt={""}/>
                                <img className="right" src={`${item.img3}`}/>
                            </div>
                        </div>
                    </Grid>
                    ))}
                </Grid>
            </Container>
            {/*
            <div className="container">
                <div className="content clearfix">
                    {itemData.map((item) => (
                    <div className="cube-container">
                        <div className="photo-cube">
                            <img className="front" src={`${item.img1}`} alt={""}/>
                            <div className="back photo-desc">
                                <h3>{`${item.title}`}</h3>
                                <p>{`${item.caption}`}</p>
                                <a href={item.lnk} className="button">View Details</a>
                            </div>
                            <img className="left" src={`${item.img2}`} alt={""}/>
                            <img className="right" src={`${item.img3}`}/>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            */}
        </>
    );
}

const itemData = [
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/smile_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/smile_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/smile_03.png',
        title: 'Dhanvantary',
        caption: 'Smile',
        lnk: '/smile',
        featured: true,
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/about_us_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/about_us_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/about_us_03.png',
        title: 'About',
        caption: 'Dhanvantary',
        lnk: '/about',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/support_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/support_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/support_03.png',
        title: 'Support',
        caption: 'Customer',
        lnk: '/',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/ayurveda_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/ayurveda_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/ayurveda_03.png',
        title: 'Know',
        caption: 'Ayurveda',
        lnk: '/more',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/distributor_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/distributor_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/distributor_03.png',
        title: 'Dhanvantary',
        caption: 'Distributor',
        lnk: '/',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/dbsPathshala_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/dbsPathshala_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/dbsPathshala_03.png',
        title: 'DBS',
        caption: 'Pathshala',
        lnk: '/pathshala',
        featured: true,
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/doctor_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/doctor_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/doctor_03.png',
        title: 'Doctor`s',
        caption: 'Enquiry',
        lnk: '/',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/usefull_herbs_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/usefull_herbs_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/usefull_herbs_03.png',
        title: 'Usefull',
        caption: 'Herbs',
        lnk: '/knowhat',
        tab: 'herbs',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/why_dhanvantary_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/why_dhanvantary_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/why_dhanvantary_03.png',
        title: 'Why',
        caption: 'Dhanvantary',
        lnk: '/whydbs',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/yoga_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/yoga_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/yoga_03.png',
        title: 'Yoga',
        caption: 'Benefits',
        lnk: '/yoga',
        tab: '',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/certification_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/certification_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/certification_03.png',
        title: 'Certification',
        caption: 'Dhanvantary',
        lnk: '/crtf',
    },

    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/identify_disease_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/identify_disease_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/identify_disease_03.png',
        title: 'Know About',
        caption: 'Diseases',
        lnk: '/knowhat',
        tab: 'diseases',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/offers_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/offers_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/offers_03.png',
        title: 'Offers',
        caption: '& News',
        lnk: '/',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/know_your_body_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/know_your_body_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/know_your_body_03.png',
        title: 'Prakriti',
        caption: 'Analysis',
        lnk: '/prakriti',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/product_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/product_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/product_03.png',
        title: 'Product',
        caption: 'Catalogue',
        lnk: '/',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/classical_formula_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/classical_formula_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/classical_formula_03.png',
        title: 'Classical',
        caption: 'Fourmula',
        lnk: '/ayushKwath',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/terms_conditions_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/terms_conditions_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/terms_conditions_03.png',
        title: 'Terms &',
        caption: 'Conditions',
        lnk: '/',
    },
    {
        img1: 'https://node.dhanvantary.com/images/dbsweb/image_menu/business_opportunity_01.png',
        img2: 'https://node.dhanvantary.com/images/dbsweb/image_menu/business_opportunity_02.png',
        img3: 'https://node.dhanvantary.com/images/dbsweb/image_menu/business_opportunity_03.png',
        title: 'Business',
        caption: 'Opportunity',
        lnk: '/',
    },

    // {
    // img1: 'https://www.cairowestmag.com/wp-content/uploads/2021/03/make-someone-smile-orlando-espinosa-681x454.png',
    // https://node.dhanvantary.com/images/dbsweb/image_menu/
    //     img: 'https://images.hindustantimes.com/img/2021/09/30/1600x900/NQTIQ6MWFNOARD4EU4JYOTWMCY_1632975281376_1632975361563.jpg',
    //     title: 'Factory',
    //     caption: 'Dhanvantory',
    //     lnk: '/',
    // },

];

