import React, {Component} from "react";
// import ReactDOM from "react-dom";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
// import swal from "sweetalert2";
import DBPath from "../admin/dbPath";

let dbPath = DBPath();
// fake data generator
const getItems = count =>
    Array.from({length: count}, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});

class SortImageDb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.images
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }

    sortedBannerUploadTop = async () => {
        let asarr = [];
        this.state.items.forEach(function (element) {
            asarr.push(element.content);
        });

        if (asarr.length > 0) {
            let obj = {};
            obj[this.props.imagePath[0]] = asarr;
            let rawbody = JSON.stringify(obj);
            let jsons = {
                field: "_id",
                opt: "==",
                value: this.props.docId, id: true
            };
            jsons = JSON.stringify(jsons);

            try {
                fetch(`${dbPath}/${this.props.collectionName}?q=[${jsons}]`, {
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json'},
                    body: rawbody
                }).then(res => res.json()).then((result) => {
                    // console.log(result)
                    if (result) {
                        let url = `${dbPath}/${this.props.collectionName}?q=[{"field":"pageName","opt":"==","value":"webMain"}]`;
                        let response = fetch(url).then(res => res.json()).then((result) => {
                                this.props.successCallBack(result[0]);
                                // console.log(result[0])
                            }
                        );
                    }
                    let y = Math.floor(Math.random() * 100);
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <Dialog
                open={true}
                onClose={() => {
                    this.setState({dialogShort: false});
                }}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">Sort Banners</DialogTitle>
                <DialogContent style={{alignContent: "center"}}>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {this.state.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    {/*{item.content}*/}
                                                    <img src={item.content} width='200px'/>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                </DialogContent>
                <DialogActions>
                    <Button color="primary" autoFocus onClick={this.sortedBannerUploadTop}>
                        Save <save/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

// Put the thing into the DOM!
// ReactDOM.render(<SortImageDb />, document.getElementById("root"));

export default SortImageDb;