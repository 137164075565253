import React, {useEffect, useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, convertFromRaw} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/button";
import Modal from "@material-ui/core/Modal";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert2";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import GetSortOrder, {toTitleCase, useInput} from "../hooks/inputHooK";
import uploadImageToserver from "../imageUpload";
import Dialog from "@mui/material/Dialog";
import DBPath from "./dbPath";
import Scroll from "../scrollTop";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DialogActions from "@mui/material/DialogActions";
import ImageListItemBar from "@mui/material/ImageListItemBar";
// import draftToHtml from "draftjs-to-html";
// import {DeleteForever} from "@mui/icons-material";
// import Autocomplete from "@mui/material/Autocomplete";
// import Stack from "@mui/material/Stack";
// import OpenImageGallery from "../hooks/openImgGallery"
// import Information from "../detailBody";
// import draftToHtml from "draftjs-to-html";

let dbPath = DBPath();

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1200,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    galaryImgPop_deleteBtn: {
        position: 'absolute',
        top: '10px',
        color: 'red',
        fontsize: '15px',
        backgroundColor: '#fff'
    },
}));

function getModalStyle() {
    const top = 30;
    const left = 30;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflowY: 'scroll',
        height: '700px',
    };
}

export default function SaveDraftDataToCollection(props) {
    // let collection = (props.col.replace(/ /g, '').toLowerCase());
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    let [getChangeState, setChangeState] = useState(EditorState.createEmpty());
    let [gotNewEditor, setNewEditor] = useState(EditorState.createEmpty());
    // localStorage.clear();
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    // --------------
    const [GalleryImgOpen, setGalleryImgOpen] = useState(false);
    const [GalleryImg, setGalleryImg] = useState(false);
    // const [imgDialogue, setimgDialogue] = useState(false);
    // ------------

    const [getSubject, setSubject] = useState('');
    const [getDocument, setDocument] = useState('');
    const [getImgUploadTag, setImgUploadTag] = useState(true);
    const [getDocName, setDocName] = useState('');
    const [getBannerUrl, setBannerUrl] = useState('');
    const [getNewBannerUrl, setNewBannerUrl] = useState('');
    const [getTitle, setTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();
    // const [getUrl, setUrl] = useState();

    const {value: Name, bind: bindName, reset: resetName} = useInput('');
    // const {value: Title, bind: bindTitle, reset: resetTitle} = useInput('');
    const {value: Url, bind: bindUrl, reset: resetUrl} = useInput('');
    const handleOpen = () => {
        setNewEditor('');
        resetName('');
        resetUrl('');
        setBannerUrl('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let collectionName = '';
    if (localStorage.getItem('subject') && getLanguage) {
        if (localStorage.getItem('subject') === 'more') {
            localStorage.setItem('collection', 'more');
            collectionName = 'more';
        } else if (localStorage.getItem('subject') === 'yoga') {
            localStorage.setItem('collection', 'yoga');
            collectionName = 'yoga';
        } else {
            collectionName = localStorage.getItem('subject').concat(getLanguage);
            localStorage.setItem('collection', collectionName);
        }

    }
    {/*        collectionName = localStorage.getItem('subject').concat(getLanguage);
        localStorage.setItem('collection', collectionName);
    } else {
        localStorage.setItem('collection', 'moreEng');
        collectionName = 'moreEng';
    }

    if (!localStorage.getItem('document')) {
        localStorage.setItem('document', 'WHAT IS AYURVEDA');
    }
 */
    }

    async function findDocList(props) {
        let url = `${dbPath}/${props}`;
        let response = await fetch(url);
        response = await response.json();
        response = response.sort(GetSortOrder("name"));
        setDocument(response);
        // console.log('list---------', response)
        // if (response[0].name) {
        //     setDocName(response[0].name);
        // }
        // return response;
    }

    if (!getDocument) {
        findDocList(localStorage.getItem('collection'));
        fetchDB(getDocName);
    }
    const saveNew = (evt) => {
        let body = {};
        let json = '';
        // let detail = `detail${getLanguage}`;
        if (localStorage.getItem('subject') === 'more') {
            json = {
                name: Name,
                banner: getBannerUrl,
                title: {Eng: getTitle, Ben: getTitle, Hin: getTitle},
                detailEng: convertToRaw(gotNewEditor.getCurrentContent()),
                detailBen: {blocks: [], entityMap: {},},
                detailHin: {blocks: [], entityMap: {},}
            }
        } else if (localStorage.getItem('subject') === 'yoga') {
            json = {
                name: Name,
                banner: getBannerUrl,
                title: {Eng: getTitle, Ben: getTitle, Hin: getTitle},
                detailEng: convertToRaw(gotNewEditor.getCurrentContent()),
                detailBen: {blocks: [], entityMap: {},},
                detailHin: {blocks: [], entityMap: {},}
            }
        } else {
            json = {
                name: Name,
                details: convertToRaw(gotNewEditor.getCurrentContent())
            }
        }
        body = Object.assign(json);
        try {
            // const response = fetch(`http://localhost:3001/query/${collectionName}`, {
            const response = fetch(`${dbPath}/${collectionName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            setOpen(false);
            swal.fire({icon: 'success', title: 'Successfully Saved', timer: 2000});
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
    }

    // function manageImage(pathName) {
    // console.log(pathName);
    // }

    const saveEdit = async () => {
        let parameter = [];
        let bJSON = {};
        let title = `title.${getLanguage}`;
        let detail = `detail${getLanguage}`;

        let json = {
            field: "name",
            opt: "==",
            value: localStorage.getItem('document')
        }
        parameter.push(json);
        if (collectionName === 'more' || collectionName === 'yoga') {
            bJSON = {
                banner: getBannerUrl,
                [title]: getTitle,
                [detail]: convertToRaw(editorState.getCurrentContent())
            }
        } else {
            bJSON = {
                details: convertToRaw(editorState.getCurrentContent())
            }
        }
        try {
            const response = await fetch(`${dbPath}/${collectionName}?q=${JSON.stringify(parameter)}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bJSON)
            });
            // console.log('uuuuuuuuuuuuuuuuu', url)
            // const data = response;
            setChangeState(editorState);
            setNewBannerUrl(getBannerUrl);
            swal.fire({icon: 'success', title: 'Successfully Updated', timer: 2000});
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
    }

    let onStateChange = editorState => {
        setNewEditor(editorState);
    };
    let discart = () => {
        setEditorState(getChangeState);
        setBannerUrl(getNewBannerUrl);
    };

// create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        // setPreview('');
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    const uploadImageToserverCallTop = async (path) => {
        const retyernr = await uploadImageToserver(selectedFile, path);
        setBannerUrl(retyernr);
        setImgUploadTag(false);
    }

    let imgFolder = '';
    if (localStorage.getItem('subject') === 'more') {
        imgFolder = 'banner_more';
    } else if (localStorage.getItem('subject') === 'yoga') {
        imgFolder = 'yoga';
    }

    const handleGalleryOpenTop = async () => {
        try {
            let url = `https://node.dhanvantary.com/3/upload?database=dbsweb&folder=${imgFolder}`;
            // console.log('uuuuuuuuuuuu',url)
            // const paths = top_imageUpfrom;
            const responsesImg = await fetch(`${url}`).then(res => res.json())
                .then((result) => {
                    if (result.length > 0) {
                        setGalleryImg(result);
                        setGalleryImgOpen(true);
                        // console.log('xxxxxxxxx',result);
                    } else {
                        setGalleryImgOpen(false);
                        swal.fire("First Choose A App Category Then Try.");
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    const modalBody = (
        <div style={modalStyle} className={classes.paper}>
            <form onSubmit={saveNew}>
                <Container maxWidth="xl">
                    <h2 className='heading' style={{textAlign: 'center', color: '#446600'}}>Add New</h2>
                    <p align={'center'}>{localStorage.getItem('subject')} in English</p>
                    <Grid container spacing={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <TextField  {...bindName} style={{width: '90%'}} label="Name"/>
                            </Grid>

                            {localStorage.getItem('subject') === 'more' ?
                                <>
                                    <Grid item xs={7}>
                                        <TextField onChange={(e) => alert(e.target.value)} {...bindUrl}
                                                   style={{width: '100%', paddingTop: '15px'}} value={getBannerUrl}/>
                                        <div>
                                            <input type='file' onChange={onSelectFile}/><span
                                            style={{paddingLeft: '60px'}}> OR Select from</span>
                                            <Button size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleGalleryOpenTop()}
                                                // onClick={()=>(setGalleryImg(handleGalleryOpenTop), setGalleryImgOpen(handleGalleryOpenTop?true:false)) }
                                                    style={{marginLeft: '25px', marginRight: '15px', borderRadius: 4}}
                                            >
                                                From Server
                                            </Button>
                                            {selectedFile && <><img src={preview} alt={'image'} width="300px"/>
                                                <Button size="medium"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => uploadImageToserverCallTop([`${imgFolder}`, 'dbsweb'])}
                                                        style={{marginRight: '15px', borderRadius: 4}}
                                                >
                                                    Upload
                                                </Button>
                                            </>
                                            }
                                        </div>

                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <p>Title :</p>
                                        <TextField onChange={(e) => setTitle(e.target.value)}
                                                   style={{width: '100%', marginTop: '-20px'}}/>
                                    </Grid>
                                </>
                                : <>
                                    {/*{console.log('dddddddddddd',localStorage.getItem('subject'))}*/}
                                    <Grid item xs={7}>
                                        <TextField onChange={(e) => alert(e.target.value)} {...bindUrl}
                                                   style={{width: '100%', paddingTop: '15px'}} value={getBannerUrl}/>
                                        <div>
                                            <input type='file' onChange={onSelectFile}/><span
                                            style={{paddingLeft: '60px'}}> OR Select from</span>
                                            <Button size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleGalleryOpenTop()}
                                                // onClick={()=>(setGalleryImg(handleGalleryOpenTop), setGalleryImgOpen(handleGalleryOpenTop?true:false)) }
                                                    style={{marginLeft: '25px', marginRight: '15px', borderRadius: 4}}
                                            >
                                                From Server
                                            </Button>
                                            {selectedFile && <><img src={preview} alt={'image'} width="300px"/>
                                                <Button size="medium"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => uploadImageToserverCallTop([`${imgFolder}`, 'dbsweb'])}
                                                        style={{marginRight: '15px', borderRadius: 4}}
                                                >
                                                    Upload
                                                </Button>
                                            </>
                                            }
                                        </div>

                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <p>Title :</p>
                                        <TextField onChange={(e) => setTitle(e.target.value)}
                                                   style={{width: '100%', marginTop: '-20px'}}/>
                                    </Grid></>}
                        </Grid>

                        <Grid item xs={12}>
                            <h3></h3>
                            <div style={{border: "1px solid black", padding: '2px', minHeight: '400px'}}>
                                <Editor
                                    editorState={gotNewEditor}
                                    onEditorStateChange={onStateChange}
                                />
                            </div>
                            <div align="center" style={{marginTop: '15px'}}>
                                <Button size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{marginRight: '15px', borderRadius: 8}}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </div>
    )

    let onEditorStateChange = editorState => {
        setEditorState(editorState);

    };
    const handleSubject = (event) => {
        setDocument('');
        // setBannerUrl('');
        // localStorage.setItem('document','');
        if (!getDocument) {
            findDocList(localStorage.getItem('collection'));
        }
        setSubject(event.target.value);

        localStorage.setItem('subject', event.target.value);
        if (localStorage.getItem('collection')) {
            findDocList(event.target.value);
            if (localStorage.getItem('document')) {
                handleDocument(localStorage.getItem('document'));
            }
        }
    };
    // const handleLanguage = (event) => {
    //     setDocument('');
    //     if (!getDocument) {
    //         findDocList(localStorage.getItem('collection'));
    //     }
    //     setLanguage(event.target.value);
    //     localStorage.setItem('language', event.target.value);
    //     // console.log('handleLanguage----collection-----',localStorage.getItem('collection'));
    //     if (localStorage.getItem('collection')) {
    //         let returnDoc = findDocList(collectionName);
    //         if (localStorage.getItem('document')) {
    //             handleDocument(localStorage.getItem('document'));
    //         }
    //     }
    // };
    const handleDocument = (event) => {
        setSelectedFile(undefined);
        setImgUploadTag('true');
        setDocName(event);
        localStorage.setItem('document', event);
        fetchDB(event);
    };

    const handleGalleryImgSelectTop = (img) => {

        let arr = `https://node.dhanvantary.com/images/dbsweb/${imgFolder}/` + img;
        // setUrl(arr);
        setBannerUrl(arr);
    };

    async function fetchDB(d) {
        if (localStorage.getItem('document') === '') {
            d = 'WHAT IS AYURVEDA';
        } else {
            d = localStorage.getItem('document');
        }
        try {
            let url = `${dbPath}/${collectionName}?q=[{"field":"name","opt":"==","value":"${d}"}]`;
            let response = await fetch(url)
            response = await response.json()
            // console.log("response -- ",response);
            let contentState = {};
            if (response.length > 0) {
                // const contentState = convertFromRaw(response[0].details);
                if (collectionName === 'more' || collectionName === 'yoga') {
                    if (getLanguage === 'Ben') {
                        contentState = convertFromRaw(response[0].detailBen);
                        setTitle(response[0].title.Ben)
                    } else if (getLanguage === 'Hin') {
                        contentState = convertFromRaw(response[0].detailHin);
                        setTitle(response[0].title.Hin)
                    } else {
                        contentState = convertFromRaw(response[0].detailEng);
                        setTitle(response[0].title.Eng)
                    }
                } else {
                    contentState = convertFromRaw(response[0].details);
                }
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
                setChangeState(editorState);
                setBannerUrl(response[0].banner);
                setNewBannerUrl(response[0].banner);
                // console.log("detail-- ", getSubject);
            } else {
                setEditorState(EditorState.createWithText("No data to display.", ""));
                // setDetail("No data found.");
            }
        }catch(e){
            console.error(e);
        }
    }


    return (
        <Container maxWidth="lg" style={{marginTop: 80, paddingBottom: '50px'}}>
            <Grid container>
                <FormControl variant="standard" sx={{m: 1, minWidth: 270}}>
                    <Grid item lg={4} xs={12}>
                        <InputLabel id="ss">Select Subject</InputLabel>
                        <Select style={{minWidth: '250px'}}
                                onChange={handleSubject}
                                defaultValue={localStorage.getItem('subject') ? localStorage.getItem('subject') : ''}
                            // labelId="demo-simple-select-standard-label"
                            // id="demo-simple-select-standard"
                        >
                            <MenuItem value={'more'}>More About Ayurveda</MenuItem>
                            <MenuItem value={'healthyrecipe'}>Healthy Recipe</MenuItem>
                            <MenuItem value={'yoga'}>Yoga</MenuItem>
                        </Select>
                    </Grid>
                </FormControl>
                <Grid item lg={2} xs={12}>
                </Grid>
                {/*
                <FormControl variant="standard" sx={{m: 1, minWidth: 270}}>
                    <Grid item lg={3} xs={12}>
                        <InputLabel id="sl">Select Language</InputLabel>
                        <Select style={{minWidth: '200px'}}
                                onChange={handleLanguage}
                                defaultValue={localStorage.getItem('language') ? localStorage.getItem('language') : ''}
                            // labelId="demo-simple-select-standard-label"
                            // id="demo-simple-select-standard"
                        >
                            <MenuItem value={'Eng'}>English</MenuItem>
                            <MenuItem value={'Ben'}>Bengali</MenuItem>
                            <MenuItem value={'Hin'}>Hindi</MenuItem>
                        </Select>
                    </Grid>
                </FormControl>
                */}
                {((getSubject && getLanguage) || (localStorage.getItem('subject') && localStorage.getItem('language'))) ?
                    <>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 350}}>
                            <Grid item lg={5} xs={12}>
                                <InputLabel id="sl">Select Document</InputLabel>
                                <Select style={{minWidth: '380px'}}
                                        onChange={(e) => handleDocument(e.target.value)}
                                        defaultValue={localStorage.getItem('document') ? localStorage.getItem('document') : ''}
                                    // labelId="demo-simple-select-standard-label"
                                    // id="demo-simple-select-standard"
                                >
                                    {getDocument ? getDocument.map((doc) => {
                                        return (
                                            <MenuItem value={doc.name}>{toTitleCase(doc.name)}</MenuItem>
                                        );
                                    }) : <></>}
                                </Select>
                            </Grid>

                        </FormControl>
                        <Dialog
                            maxWidth="md"
                            open={GalleryImgOpen}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description">
                            <DialogTitle id="alert-dialog-slide-title">
                                <div style={{float: 'left'}}>Choose From Our Vault</div>
                                <div style={{float: 'right'}}>try</div>
                            </DialogTitle>
                            <DialogContent>
                                <ImageList cols={2}>
                                    {/*{console.log(GalleryImg)}*/}
                                    {GalleryImg ? GalleryImg.map((item, index) => (
                                        <ImageListItem key={index}
                                            // className={this.state.galleryImgPath.includes(this.state.top_imageUpfrom[1]+"/"+this.state.top_imageUpfrom[0]+"/"+item) ? classes.selected_galaryImgPop : classes.galaryImgPop}
                                        >
                                            <img src={`https://node.dhanvantary.com/images/dbsweb/${imgFolder}/${item}`}
                                                 alt={'image'}
                                                 onError={(e) => {
                                                     e.target.src = "https://node.dhanvantary.com/images/papaapp/folder_open.jpg"
                                                 }}
                                                // alt={item}
                                                 onClick={(e) => {
                                                     handleGalleryImgSelectTop(item)
                                                 }}/>
                                            <ImageListItemBar
                                                title={item}
                                            />
                                            {/*<div className={classes.galaryImgPop_deleteBtn} onClick={()=>this.gallaryImgDel(this.state.top_imageUpfrom,item)}><DeleteForever /></div> */}
                                        </ImageListItem>
                                    )) : <></>}
                                </ImageList>
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={() => {
                                    setGalleryImgOpen(!GalleryImgOpen)
                                }}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Grid item lg={2} xs={12} style={{marginTop: '20px',}}>
                            <Tooltip title="Add New" placement="left-start" style={{float: 'right'}}>
                                <Fab size="small" color="primary" aria-label="add">
                                    <AddIcon onClick={handleOpen}/>
                                    {/*-----------------------------*/}
                                    <Dialog
                                        open={GalleryImgOpen}
                                        keepMounted
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description">
                                        <DialogTitle id="alert-dialog-slide-title">Choose From Our Vault </DialogTitle>
                                        <DialogContent>
                                            {/*<ImageList cols={2}>*/}
                                            {/*    {GalleryImg.map((item) => (*/}
                                            {/*        <ImageListItem key={item._id}>*/}
                                            {/*            <img src={`https://${item.img}`} alt="Online Bussiness" onClick={()=>handleGalleryImgSelect(item.img)} />*/}
                                            {/*        </ImageListItem>*/}
                                            {/*    ))}*/}

                                            {/*    <div className={classes.galaryImgPop_deleteBtn} onClick={()=>gallaryImgDel(top_imageUpfrom,item)}><DeleteForever /></div>*/}
                                            {/*</ImageList>*/}
                                        </DialogContent>
                                        <DialogActions>
                                            {/*<Button color="primary" onClick={() => { setState({GalleryImgOpen: !GalleryImgOpen}); }}>*/}
                                            {/*    Close*/}
                                            {/*</Button>*/}
                                        </DialogActions>
                                    </Dialog>
                                    {/*--------------------*/}

                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                    >
                                        {modalBody}
                                    </Modal>
                                </Fab>
                            </Tooltip>
                        </Grid></> :
                    <></>}
                {(localStorage.getItem('subject') === 'more' || localStorage.getItem('subject') === 'yoga') ?
                    <>
                        <Grid item lg={3} xs={12}>
                            <p>Current banner :</p>

                            {/*<TextField name="string" style={{width: '90%'}} value={getBannerUrl} label="Banner URL"/>*/}
                            {/*<span>{getBannerUrl}</span>*/}
                            {/*                        https://node.dhanvantary.com/images/ */}
                            {(getDocument) ? <img style={{marginTop: '-20px'}} src={getBannerUrl} alt={'image'}
                                                  width="95%"/> : <></>}
                        </Grid>
                        <Grid item lg={9} xs={12}>
                            <p>Change banner :</p>
                            <TextField {...bindUrl} style={{width: '100%', marginTop: '-20px'}} value={getBannerUrl}
                                       required={true}/>
                            <Button size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleGalleryOpenTop()}
                                // onClick={async()=>(await setGalleryImg(handleGalleryOpenTop), await setGalleryImgOpen(handleGalleryOpenTop?true:false)) }
                                // onClick={() => manageImage(['banner_more', 'dbsweb'])}
                                    style={{marginTop: '10px', borderRadius: 4}}
                            >
                                From Server
                            </Button> OR
                            <input type='file' onChange={onSelectFile} style={{marginLeft: '10px'}}/>
                            {/*{console.log('selectedFile----', selectedFile, '---getImgUploadTag----', getImgUploadTag)}*/}
                            {(selectedFile && getImgUploadTag) && <><img src={preview} width="300px" alt={'image'}
                                                                         style={{marginTop: '10px'}}/>

                                <Button size="medium"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => uploadImageToserverCallTop([`${imgFolder}`, 'dbsweb'])}
                                        style={{marginLeft: '15px', marginTop: '-30px', borderRadius: 4}}
                                >
                                    Upload
                                </Button>
                            </>
                            }
                            {/*</Grid>*/}
                            {/*<Grid item lg={12} xs={12}>*/}
                            <p>Change Title :</p>
                            <TextField onChange={(e) => setTitle(e.target.value)}
                                       style={{width: '100%', marginTop: '-20px'}} value={getTitle} required={true}/>
                        </Grid>
                    </> : <></>
                }

                <div style={{border: "1px solid black", padding: '2px', minHeight: '400px', marginTop: '30px'}}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>
            </Grid>
            {(editorState != getChangeState) || (getNewBannerUrl != getBannerUrl) ?
                <div align="center" style={{marginTop: '15px'}}>
                    <Button size="medium"
                            variant="contained"
                            color="primary"
                            style={{marginRight: '15px', borderRadius: 8}}
                            onClick={saveEdit}
                    >
                        Save Edit
                    </Button>
                    <Button size="medium"
                            variant="contained"
                            style={{backgroundColor: '#ffa000', borderRadius: 8}}
                            onClick={discart}
                    >
                        Discart
                    </Button>
                </div> : <></>
            }
            <Scroll showBelow={1000}/>
        </Container>
    );
}