import React, {useEffect, useState} from 'react';
import GetSortOrder, {toTitleCase} from "./hooks/inputHooK";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from "@mui/material/List";
import {Link} from "react-router-dom";
import ProductCard from "./products";
import DiseasesList, {DiseasesDropList} from "./diseasesList";
import {makeStyles} from "@material-ui/core/styles";
import useWindowDimensions from "./hooks/utilityHooK";
import MoreAyur from "./moreAyur";
import Box from "@mui/material/Box";
// import DBPath from "./admin/dbPath";
// let dbPath = DBPath();
const useStyles = makeStyles((theme) => ({
    head: {
        fontFamily: 'Old Standard TT, serif',
        fontSize: '20px',
        userSelect: 'none',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: "14px",
        },
    },
}));
export default function ProdCatList(props) {
    const classes = useStyles();
    const [newvalue, setNewValue] = useState('');
    const [getCategory, setCategory] = useState('');
    const {height, width} = useWindowDimensions();
    let distinctSubject = '';

    useEffect(() => {
        async function fetchMyAPI() {
            let url = `https://node.dhanvantary.com/query/dbs_products`;
            // let url = `https://node.dhanvantary.com/query/dbs_products?q=[{"field":"category","opt":"==","value":"6142edc0e695a76a20ec2fc8","id":true}]`;
            let response = await fetch(url);
            response = await response.json();
            distinctSubject = [...new Set(response.map(data => data.category))];
            let menu_sorted = distinctSubject.sort(GetSortOrder("category"));
            // console.log(menu_sorted);
            setNewValue(menu_sorted);
        }

        fetchMyAPI();
    }, []);
    // const callBack = (e) => {
    //     if (!e) {
    //         setCategory('');
    //     }
    // }
    return (
        <Container maxWidth="xl">
            <Grid container spacing={0}>
                <Grid item lg={3} xs={12} style={{marginTop: '20px'}}>
                    <div>
                        <p className={classes.head} style={{
                            margin: "2% 10% 5% 5%",
                            padding: "2% 0% 2% 5%",
                            backgroundColor: "#8cd674",
                            // fontSize: '18px',
                        }}>Product Category List</p>
                        <List className='sideList'
                              style={{marginLeft: '5%', marginTop: '-5%', paddingRight: '5px', maxHeight: "500px"}}>
                            {newvalue ? newvalue.map((data) => {
                                if (data) {
                                    return (
                                        <ul>
                                            {/*<Button className={classes.slideLink} onClick={() => getDetails(data.name)}>{data.name}</Button>*/}
                                            <li style={{
                                                padding: '3px 0px 3px 0px',
                                                margin: '6px 0px 6px 0px',
                                                backgroundColor: "#fff",
                                            }}
                                            >
                                                {/*<Link className='sideLink' to={{pathname: "/more"}}>{toTitleCase(data.name)}</Link>*/}
                                                <Link
                                                    className='sideLink'
                                                    to={{
                                                        pathname: "/products",
                                                        // state: {data:data.name}
                                                        // lang: getLanguage,
                                                    }}
                                                    style={getCategory === data
                                                        ? {color: "black", fontWeight: "bold"}
                                                        : {color: "#446600"}}
                                                    onClick={() => {
                                                        // localStorage.setItem('pCat', data)
                                                        setCategory(data);
                                                    }}
                                                >
                                                    {data}
                                                    {/*{toTitleCase(data.toLowerCase())}*/}
                                                </Link>
                                            </li>
                                        </ul>
                                    );
                                }
                            }) : <></>}
                        </List>
                    </div>
                    {width > 800 ?
                        <>
                        <DiseasesList/><br/>
                        <MoreAyur/>
                        </>
                        :
                        <>
                        <Box sx={{maxWidth: '100%', bgcolor: '#8cd674', marginTop:'20px', borderRadius: '4px',paddingBottom:'10px'}}>
                            <p style={{marginBottom:'-15px',paddingLeft:'15px',}}>Other Usefull Links</p>
                            <DiseasesDropList/>
                        </Box>
                        </>
                    }
                </Grid>
                <Grid item lg={9} xs={12}>
                    {getCategory ?
                        <ProductCard pCat={getCategory}/> // callFunction={callBack}
                        : <><ProductCard/></>}
                </Grid>
            </Grid>
            {/*<Scroll showBelow={1000}/>*/}
        </Container>
    );
}