import React, {useState, Component, useEffect} from 'react';
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {prakritiDataEng, prakritiDataHin, prakritiDataBen} from "./jsonPrakriti";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import dasha2 from "../bannerImage/Elements_Doshas2.png";
import {AnalysisResultEng, AnalysisResultBen, AnalysisResultHin} from './jsonPrakritiResult';
// import kyb from "../bannerImage/kyb.jpg";
import Chart from "react-google-charts";
import Scroll from "./scrollTop";
import {fetchPage} from "./hooks/inputHooK";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import DraftEdit from "./admin/draftEdit";
import draftToHtml from "draftjs-to-html";
import swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
    table: {
        Width: 650,
    },

    tblHead: {
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold',
    },
    paraHead: {
        fontFamily: 'Old Standard TT, serif',
        fontWeight: 'bold',
        fontSize: '24px',
        borderRadius: '8px',
        padding: '6px 0px 3px 30px',
        marginTop: '-5px',
        background: 'linear-gradient(to right, #8cd674, #e5ff63)',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: '10px 0px 5px 5px',
        },
    },
    cont: {
        marginTop: '20px',
        padding: '0px 25px 0px 25px',
        overflowY: "auto",
        height: "1800px",
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '20px',
            padding: '5px',
        },
    },
}));

export function PrakritiQues(props) {
    const classes = useStyles();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getBodyType, setBodyType] = useState('');
    // const [getCount, setCount] = useState('');
    window.sessionStorage.setItem('total', 0);

    let select = [];
    let sl = [];

    const analysis = (event) => {
        let a = event.target.value;
        let b = a.split("-");
        let c = b[0];
        let pos = sl.indexOf(c)
        if (pos > -1) {
            sl.splice(pos, 1);
            select.splice(pos, 1);
        }
        sl.push(c);
        // select.push(event.target.value);
        select.push(b[1]);

        let count = {};
        for (const element of select) {
            if (count[element]) {
                count[element] += 1;
            } else {
                count[element] = 1;
            }
        }
        let tot = ((count.vata ? count.vata : 0) + (count.pitta ? count.pitta : 0) + (count.kapha ? count.kapha : 0));
        window.sessionStorage.clear();
        window.sessionStorage.setItem('vata', count.vata);
        window.sessionStorage.setItem('pitta', count.pitta);
        window.sessionStorage.setItem('kapha', count.kapha);
        window.sessionStorage.setItem('total', tot);

        // document.getElementById(c).style.background = "#b7f07f"; linear-gradient(to right,#fff,#b7f07f)
        document.getElementById(c).style.background = "linear-gradient(to right,#a7e864,#fff)";
        document.getElementById(`c${c}`).style.fontWeight = "bold";
    }

    const calculate = (event) => {
        let vata = window.sessionStorage.getItem('vata');
        let pitta = window.sessionStorage.getItem('pitta');
        let kapha = window.sessionStorage.getItem('kapha');
        let total = window.sessionStorage.getItem('total');
        let vata_percentage = '';
        let pitta_percentage = '';
        let kapha_percentage = '';
        let bodyType = "";

        if (total >= 20) {
            vata_percentage = (vata / total);
            pitta_percentage = (pitta / total);
            kapha_percentage = (kapha / total);
            let bigger = Math.max(vata_percentage, pitta_percentage, kapha_percentage);

            let ratio_vata = vata_percentage / bigger;
            let ratio_pitta = pitta_percentage / bigger;
            let ratio_kapha = kapha_percentage / bigger;

            ratio_vata = ratio_vata.toFixed(2);
            ratio_pitta = ratio_pitta.toFixed(2);
            ratio_kapha = ratio_kapha.toFixed(2);

            vata_percentage = vata_percentage.toFixed(2);
            pitta_percentage = pitta_percentage.toFixed(2);
            kapha_percentage = kapha_percentage.toFixed(2);


            if (parseFloat(ratio_vata) >= 0.5) {
                bodyType = bodyType + "Vata-";
            }
            if (parseFloat(ratio_pitta) >= 0.5) {
                bodyType = bodyType + "pitta-";
            }
            if (parseFloat(ratio_kapha) >= 0.5) {
                bodyType = bodyType + "kapha-";
            }
            if (bodyType.charAt(bodyType.length - 1) == '-') {
                bodyType = bodyType.substr(0, bodyType.length - 1);
                // setBodyType(bodyType);
            }
        } else {
            // alert("Please Answer Atleast 20 Questions");
            swal.fire({
                icon: 'warning',
                title: 'Answer atleast 20 questions',
            });
        }

        let JsonData = {
            tab: 2,
            bt: bodyType,
            vp: vata_percentage,
            ppe: pitta_percentage,
            kp: kapha_percentage,
        }

        if (bodyType) {
            props.prakritiCallBack(JsonData);
        }
    }

    return (
        <>
            <Container maxWidth="lg" style={{marginTop: '20px'}}>
                <form onSubmit={calculate}>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 700}} aria-label="customized table">
                            <TableHead>
                                <TableRow style={{backgroundColor: '#446600'}}>
                                    <TableCell className={classes.tblHead}>Sl</TableCell>
                                    <TableCell className={classes.tblHead}>Characteristics</TableCell>
                                    <TableCell className={classes.tblHead}>Vata</TableCell>
                                    <TableCell className={classes.tblHead}>Pitta</TableCell>
                                    <TableCell className={classes.tblHead}>Kapha</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    getLanguage === 'Eng' ? (prakritiDataEng.map((data) => (
                                            <>
                                                <TableRow id={data.sl}>
                                                    <TableCell
                                                        style={{background: "linear-gradient(to right,#b7f07f, #fff)"}}>{data.sl}</TableCell>
                                                    <TableCell
                                                        style={{background: "linear-gradient(to right,#fff,#b7f07f)"}}>{data.char}</TableCell>
                                                    {data.obj.map((info) => (
                                                        <>
                                                            <TableCell>
                                                                <input type="radio" id={`c${data.sl}`}
                                                                       value={`${data.sl}-${info.name}`}
                                                                       onChange={analysis} name={data.sl}/>{info.value}
                                                            </TableCell>
                                                        </>
                                                    ))}
                                                </TableRow>
                                            </>
                                        ))) :
                                        (getLanguage === 'Hin' ? (prakritiDataHin.map((data) => (
                                                <>
                                                    <TableRow id={data.sl}>
                                                        <TableCell
                                                            style={{background: "linear-gradient(to right,#b7f07f, #fff)"}}>{data.sl}</TableCell>
                                                        <TableCell
                                                            style={{background: "linear-gradient(to right,#fff,#b7f07f)"}}>{data.char}</TableCell>
                                                        {data.obj.map((info) => (
                                                            <>
                                                                <TableCell>
                                                                    <input type="radio" id={`c${data.sl}`}
                                                                           value={`${data.sl}-${info.name}`}
                                                                           onChange={analysis} name={data.sl}/>{info.value}
                                                                </TableCell>
                                                            </>
                                                        ))}
                                                    </TableRow>
                                                </>
                                            ))) :
                                            (prakritiDataBen.map((data) => (
                                                <>
                                                    <TableRow id={data.sl}>
                                                        <TableCell
                                                            style={{background: "linear-gradient(to right,#b7f07f, #fff)"}}>{data.sl}</TableCell>
                                                        <TableCell
                                                            style={{background: "linear-gradient(to right,#fff,#b7f07f)"}}>{data.char}</TableCell>
                                                        {data.obj.map((info) => (
                                                            <>
                                                                <TableCell>
                                                                    <input type="radio" id={`c${data.sl}`}
                                                                           value={`${data.sl}-${info.name}`}
                                                                           onChange={analysis}
                                                                           name={data.sl}/>{info.value}
                                                                </TableCell>
                                                            </>
                                                        ))}
                                                    </TableRow>
                                                </>
                                            ))))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*{(window.sessionStorage.getItem('total'))>=3?*/}
                    <Button size="medium"
                            variant="contained"
                            color="primary"
                            type="submit"
                        // onClick={props.prakritiCallBack}
                            style={{marginTop: '20px', borderRadius: 8}}
                    >
                        Submit
                    </Button>
                    {/*:<></>}*/}

                </form>
            </Container>

        </>
    );
}

export function Result(props) {
    const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    // let jsonPrakritiResult = 'AnalysisResult'.concat(getLanguage);
    // console.log('jjjjjjjjjjjjjjjjjjjj',jsonPrakritiResult)
    const pieData = [
        ['Task', 'Our own task'],
        ['Vata', Number(props.vp)],
        ['Pitta', Number(props.pp)],
        ['Kapha', Number(props.kp)],
    ]

    const pieOptions = {
        title: '',
        pieHole: 0.1,
    }
    let resultData = '';
    if (getLanguage === 'Ben') {
        resultData = props.bt ? (AnalysisResultBen.filter((row) => String(row["bodyType"]).toLowerCase() === String(props.bt).toLowerCase())) : '';
    } else if (getLanguage === 'Hin') {
        resultData = props.bt ? (AnalysisResultHin.filter((row) => String(row["bodyType"]).toLowerCase() === String(props.bt).toLowerCase())) : '';
    } else {
        resultData = props.bt ? (AnalysisResultEng.filter((row) => String(row["bodyType"]).toLowerCase() === String(props.bt).toLowerCase())) : '';
    }

    return (
        <Container style={{marginTop: '20px', paddingLeft: '30px', paddingRight: '50px'}}>
            <div>
                <div className={'heading'}>Your Body-Type is:- <b style={{color: 'green'}}>{props.bt}</b></div>
                <Chart
                    // width={'600px'}
                    height={'320px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={pieData}
                    options={pieOptions}
                    rootProps={{'data-testid': '3'}}
                />
            </div>
            <div>
                {resultData.map((data) => (
                    <>
                        <div dangerouslySetInnerHTML={{__html: `${data.result}`}}/>
                        <div dangerouslySetInnerHTML={{__html: `${data.recommendation}`}}/>
                    </>
                ))}
            </div>
            <Scroll showBelow={800}/>
        </Container>
    );
}

export function KnowYrBody(props) {
    const classes = useStyles();
    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'prakritiQues';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------
    return (
        <Container className={classes.cont}>
            <div className={classes.paraHead}>
                {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                 onClick={() => {
                                     handelEditor(getPageDb);
                                     setBlock(getPageDb[0].block)
                                 }}
                            >
                                <Edit/>
                            </Fab>
                        </Tooltip>
                    </Box>
                    : <></>}
            </div>
            <div className='welcomeBody' align={'justify'}
                 dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></div>
            <div className={classes.paraHead}>
                {getPageDb[1] ? getPageDb[1][`head${getLanguage}`] : ''}
                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                 onClick={() => {
                                     handelEditor(getPageDb);
                                     setBlock(getPageDb[1].block)
                                 }}
                            >
                                <Edit/>
                            </Fab>
                        </Tooltip>
                    </Box>
                    : <></>}
            </div>
            <div className='welcomeBody' align={'justify'}
                 dangerouslySetInnerHTML={{__html: `${getPageDb[1] ? draftToHtml(getPageDb[1][`body${getLanguage}`]) : ''}`}}></div>

            <br/>
            <div className={classes.paraHead}>Factors Affecting The Formation Of Prakriti</div>
            <ul>
                <li>Prakriti of sperm</li>
                <li>Prakriti of ovum</li>
                <li>Prakriti of uterine cavity</li>
                <li>Diet of pregnant mother</li>
                <li>Time/season of conception</li>
                <li>Role of Panchmahabhuta</li>
            </ul>
            <br/>
            <div className={classes.paraHead}>
                {getPageDb[2] ? getPageDb[2][`head${getLanguage}`] : ''}
                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                 onClick={() => {
                                     handelEditor(getPageDb);
                                     setBlock(getPageDb[2].block)
                                 }}
                            >
                                <Edit/>
                            </Fab>
                        </Tooltip>
                    </Box>
                    : <></>}
            </div>
            <div className='welcomeBody' align={'justify'}
                 dangerouslySetInnerHTML={{__html: `${getPageDb[2] ? draftToHtml(getPageDb[2][`body${getLanguage}`]) : ''}`}}></div>
            {/*<div className={classes.paraHead}>The "Tridosha" Principle For Determining Prakriti</div>*/}
            {/*<div className='welcomeBody' align={'justify'} style={{columnCount: 1}}>The fundamental theory of Ayurveda*/}
            {/*    is the "Tridosh" principle or the tri-energies theory. This can also be understood as combination of*/}
            {/*    "panchbhutas" (Five Elements) into three doshas. These are biological or physiological and physical*/}
            {/*    forces that cater to the metabolic functions and structural composition of our body. Kapha is*/}
            {/*    responsible for anabolism. Pitta is responsible for metabolism. Vata is responsible for catabolism. The*/}
            {/*    balance of these tri-energies is known as a state of health and their imbalance is disease. Anything*/}
            {/*    which restores this balance is good for health. So specific diet, exercise, behavior or medicine can be*/}
            {/*    recommended according to prakriti to restore this balance and provide health. The tri-energies are:*/}
            {/*</div>*/}
            <div>
                <Table sx={{minWidth: 300}}>
                    <TableHead>
                        <TableRow style={{backgroundColor: '#446600'}}>
                            <TableCell className={classes.tblHead}>Tridosh</TableCell>
                            <TableCell className={classes.tblHead}>Energy counterpart</TableCell>
                            <TableCell className={classes.tblHead}>Elementary Composition</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow style={{backgroundColor: '#d5f5dd'}}>
                            <TableCell>Vata</TableCell>
                            <TableCell>Kinetic energy</TableCell>
                            <TableCell>Air + Ether</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Pitta</TableCell>
                            <TableCell>Thermal energy</TableCell>
                            <TableCell>Fire+ little quantity of water</TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: '#d5f5dd'}}>
                            <TableCell>Kapha</TableCell>
                            <TableCell>Potential energy</TableCell>
                            <TableCell>Earth + Water</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <br/>
            <div className={classes.paraHead}>
                {getPageDb[3] ? getPageDb[3][`head${getLanguage}`] : ''}
                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                 onClick={() => {
                                     handelEditor(getPageDb);
                                     setBlock(getPageDb[3].block)
                                 }}
                            >
                                <Edit/>
                            </Fab>
                        </Tooltip>
                    </Box>
                    : <></>}
            </div>
            <div><img src={dasha2} alt={'dasha2'} style={{width: '75%'}}/></div>
            <div className='welcomeBody' align={'justify'}
                 dangerouslySetInnerHTML={{__html: `${getPageDb[3] ? draftToHtml(getPageDb[3][`body${getLanguage}`]) : ''}`}}></div>
            {/*<br/>*/}
            {/*<div className={classes.paraHead}>Relationship Between Vata, Pitta, Kapha And 5 Elements</div>*/}
            {/*<div><img src={dasha2} alt={'dasha2'} style={{width: '75%'}}/></div>*/}
            {/*<div className='welcomeBody' align={'justify'} style={{columnCount: 1}}>It states that all the tissues*/}
            {/*    (sapta dhatu) in the body can be developed properly if these are nourished by the nutrients similar in*/}
            {/*    nature to them e.g. shukra dhatu in body is nourished by regular intake of milk and ghee. Similarly*/}
            {/*    lifestyle also affects dosha in the body like by strenuous exercise vata increases in body and by*/}
            {/*    sitting for long hours kapha increases in body. This is a principle of similarity (samanyam).*/}
            {/*    <br/>*/}
            {/*    Same principle is applicable to opposites like Tila oil massage (which has properties opposite to vata)*/}
            {/*    in vataj disorder decreases vata. This is a principle of opposition (Vishesh).*/}
            {/*    <br/>To increase any dosha/dhatu in body we choose similar herbs/dietary regimens, while to decrease any*/}
            {/*    dosha/dhatu in body we choose opposite herbs/dietary regimens.*/}
            {/*</div>*/}
            <div className={classes.paraHead}>
                {getPageDb[4] ? getPageDb[4][`head${getLanguage}`] : ''}
                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                 onClick={() => {
                                     handelEditor(getPageDb);
                                     setBlock(getPageDb[4].block)
                                 }}
                            >
                                <Edit/>
                            </Fab>
                        </Tooltip>
                    </Box>
                    : <></>}
            </div>
            <div className='welcomeBody' align={'justify'}
                 dangerouslySetInnerHTML={{__html: `${getPageDb[4] ? draftToHtml(getPageDb[4][`body${getLanguage}`]) : ''}`}}></div>
            {/*<br/>*/}
            {/*<div className={classes.paraHead}>Importance Of Knowing One's Prakriti</div>*/}
            {/*<div className='welcomeBody' align={'justify'} style={{columnCount: 1}}>According to Ayurvedic texts*/}
            {/*    prakriti tells us about the susceptibility of an individual to develop particular type of diseases*/}
            {/*    throughout his lifetime. There is a beautiful example that if a vata type person develops vataj disorder*/}
            {/*    then its prognosis is difficult. If kapha type or pitta type person develops similar vataj disease then*/}
            {/*    the prognosis is better and disease is likely to be cured easily.*/}
            {/*    <br/>*/}
            {/*    Prakriti Analysis by means of Tridoshas or Trienergies of body plays a very important role in diagnosis*/}
            {/*    and treatment of the disease. Not only this but this Diagnostic tool also helps you to know about*/}
            {/*    particular dietary regimens, herbs to avoid or to prefer. Prakriti Analysis helps us to maintain healthy*/}
            {/*    life style as well.*/}
            {/*</div>*/}
            <div className={classes.paraHead}>
                {getPageDb[5] ? getPageDb[5][`head${getLanguage}`] : ''}
                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                 onClick={() => {
                                     handelEditor(getPageDb);
                                     setBlock(getPageDb[5].block)
                                 }}
                            >
                                <Edit/>
                            </Fab>
                        </Tooltip>
                    </Box>
                    : <></>}
            </div>
            <div className='welcomeBody' align={'justify'}
                 dangerouslySetInnerHTML={{__html: `${getPageDb[5] ? draftToHtml(getPageDb[5][`body${getLanguage}`]) : ''}`}}></div>
            {/*<br/>*/}
            {/*<div className={classes.paraHead}>Therefore Prakriti Is Important To Know</div>*/}
            {/*<ul>*/}
            {/*    <li>Who I am? (On the basis of my vata, pitta, kapha constitution)</li>*/}
            {/*    <li>What should I eat?</li>*/}
            {/*    <li>What should I avoid?</li>*/}
            {/*    <li>What should I eat occasionally?</li>*/}
            {/*    <li>What should I eat in moderation?</li>*/}
            {/*    <li>What should I follow in my life style?</li>*/}
            {/*</ul>*/}
            {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
            <Scroll showBelow={800}/>
        </Container>
    );
}