import React, {useEffect, useState} from 'react';
import "../App.css";
import {Link} from "react-router-dom";
import List from "@mui/material/List";
import FormControl from "@mui/material/FormControl";
import DBPath from "./admin/dbPath";
import GetSortOrder, {toTitleCase} from "./hooks/inputHooK";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Scroll from "./scrollTop";
// import draftToHtml from "draftjs-to-html";
// import useWindowDimensions from "./hooks/utilityHooK";
// import kyb from "../bannerImage/kyb.jpg";
// import DiseasesList, {DiseasesDropList} from "./diseasesList";
// import HerbsList, {HerbsDropList} from "./herbsList";
// import DietChartList, {DietChartDropList} from "./dietChartList";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import RecipeBody from "./recipeBody"
// import MenuItem from "@mui/material/MenuItem";
let dbPath = DBPath();
const useStyles = makeStyles((theme) => ({
    scrol: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "1700px",
        marginTop: "-40px",
        // paddingRight:'100px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "-20px",
            height: "auto",
        },
    },
    title: {
        marginTop: "30px",
        fontFamily: "Copperplate, Fantasy",
        fontSize: "35px",
        color: "#fff",
        // textShadow: "2px 2px 5px #f3fa2f",
        textAlign: "center",
        backgroundColor: '#8cd674',
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        },
    },
}));

export default function RecipeList(props) {
    // const classes = useStyles();
    // const [gotRecipeList, getRecipeList] = useState('');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : localStorage.getItem('language'));
    const [getRecipeObj, setRecipeObj] = useState('');
    // const currentURL = window.location.pathname;
    let collection = 'healthyrecipe'.concat(getLanguage);
    // localStorage.setItem('recipeName', 'Amla Murrabba');
    // function getDetails(param) {
    //     getRecipeList(param);
    // }
// console.log('>>>>>>>>>>>',collection)
    // useEffect(() => {
    // setLanguage(localStorage.getItem('language'));
    // if (localStorage.getItem('language')) {
    //     collection = 'healthyrecipe'.concat(localStorage.getItem('language'));
    // } else if (props.lang) {
    //     collection = 'healthyrecipe'.concat(props.lang);
    // } else {
    //     collection = 'healthyrecipeEng';
    // }

    async function fetchMyAPI(c) {
        try{
            let url = `${dbPath}/${c}`;
            let response = await fetch(url);
            response = await response.json();
            let menu_sorted = response.sort(GetSortOrder("name"));
            setRecipeObj(menu_sorted);
            if (localStorage.getItem('recipeName')) {
                if (menu_sorted.filter(item => item.name == localStorage.getItem('recipeName')).length > 0) {
                    let dtls = menu_sorted.filter(item => item.name == localStorage.getItem('recipeName'))[0].details;
                    props.recipeListObj(localStorage.getItem('recipeName'), JSON.stringify(dtls));
                } else {
                    props.recipeListObj(localStorage.getItem('recipeName'), JSON.stringify({}));
                }
            } else {
                props.recipeListObj(menu_sorted[0].name, JSON.stringify(menu_sorted[0].details));
            }
        }catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchMyAPI(collection);
    }, [collection]);

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    // const handleChange = (event) => {
    //     // console.log('ccccccccccccccccccccccc', event)
    //     // sessionStorage.setItem('language', event.target.value);
    //     props.callRecipe(event.target.value);
    // };
    //
    // const backRecipeName = () => {
    //     // console.log('eeeeeeee',e)
    //     props.callRecipe()
    // }

    return (
        <>
            <div>
                <p className="heading" style={{
                    margin: "0% 10% 5% 5%",
                    padding: "2% 0% 2% 5%",
                    backgroundColor: "#8cd674",
                }}>Healthy Recipes</p>
                <List className='sideList'
                      style={{marginLeft: '5%', marginTop: '-5%', paddingRight: '5px', maxHeight: 300}}>
                    {getRecipeObj ? getRecipeObj.map((recipe) => {
                        return (
                            <ul>
                                {/*<Button className={classes.slideLink} onClick={() => getDetails(data.name)}>{data.name}</Button>*/}
                                <li style={{
                                    padding: '3px 0px 3px 0px',
                                    margin: '6px 0px 6px 0px',
                                    backgroundColor: "#fff",
                                }}
                                    // onClick={backRecipeName(recipe.name)}
                                >
                                    <Link
                                        className='sideLink' to={{pathname: "/recipe"}}
                                        style={localStorage.getItem('recipeName') === recipe.name ? {
                                            color: "black", fontWeight: "bold"
                                        } : {color: "#446600"}}
                                        onClick={() => {
                                            localStorage.setItem('recipeName', recipe.name);
                                            props.recipeListObj(recipe.name, JSON.stringify(recipe.details));
                                        }}
                                    >{toTitleCase(recipe.name)}</Link>
                                </li>
                            </ul>
                        );
                    }) : <></>}
                </List>
            </div>
        </>
    );
}

export function RecipeDropList(props) {
    const classes = useStyles();
    const [getList, setList] = useState('');
    // const [getDoc, setDoc] = useState('');
    // const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    // const {height, width} = useWindowDimensions();
    let collection ='';

    if(getLanguage) {
        collection = 'healthyrecipe'.concat(getLanguage);
    }else{
        collection='healthyrecipeEng';
    }

    useEffect(() => {
        async function fetchList(collection) {
            let url = `${dbPath}/${collection}`;
            let response = await fetch(url);
            const json = await response.json();
            let menu_sorted = json.sort(GetSortOrder("name"));
            setList(menu_sorted);
            if (localStorage.getItem('recipeName')) {
                // let nm = menu_sorted.filter(item => item.name == localStorage.getItem('recipeName'))[0].name;
                let dtls = menu_sorted.filter(item => item.name == localStorage.getItem('recipeName'))[0].details;
                props.recipeDropObj(localStorage.getItem('recipeName'), JSON.stringify(dtls));
            } else {
                props.recipeDropObj(menu_sorted[0].name, JSON.stringify(menu_sorted[0].details));
            }
            // setDoc(sessionStorage.getItem("objId") ? JSON.stringify(menu_sorted.filter(par => par._id === String(sessionStorage.getItem("objId")))[0]) : JSON.stringify(menu_sorted[0]));
            // setDoc(JSON.stringify(menu_sorted[0]))
        }

        fetchList(collection);
    }, []);

    // function RcpiBody(props) {
    //     return (
    //         <RecipeBody obj={props.obj}/>
    //     )
    // }

    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12} style={{marginTop: '30px'}}>
                        {/*{width > 800 ?*/}
                        {/*    <>*/}
                        {/*        /!*<MoreAyur/>*!/*/}
                        {/*        <div style={{marginTop: '20px'}}>*/}
                        {/*            <a href={'/prakriti'}><img src={kyb} alt={'kyb'}*/}
                        {/*                                       style={{marginLeft: '25px', width: '85%'}}/></a>*/}
                        {/*        </div>*/}
                        {/*        <DiseasesList/>*/}
                        {/*        <HerbsList/>*/}
                        {/*        <DietChartList/>*/}
                        {/*    </> :*/}
                        {/*    <>*/}
                                {getList.length > 0 ?
                                    <FormControl sx={{width: 1}}>
                                        <Autocomplete
                                            // value={sessionStorage.getItem("objId") ? getList.filter(par => par._id === String(sessionStorage.getItem("objId")))[0] : {name: ''}}
                                            value={localStorage.getItem('recipeName') ? getList.filter(par => par.name === String(localStorage.getItem('recipeName')))[0] : {name: ''}}
                                            options={getList}
                                            getOptionLabel={(option) => toTitleCase(option.name.toLowerCase())}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    // props.callRecipe(value);
                                                    // sessionStorage.setItem('objId', value._id);
                                                    localStorage.setItem('recipeName', value.name);
                                                    // setDoc(JSON.stringify(getList.filter(par => par._id === value._id)[0]));
                                                    props.recipeDropObj(value.name, JSON.stringify(value.details));
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Healthy Recipe"
                                                />
                                            )}
                                        />
                                    </FormControl> :
                                    <></>
                                }
                        {/*    </>*/}
                        {/*}*/}
                    </Grid>
                    {/*<Grid item lg={7} xs={12}>*/}
                    {/*    {getDoc ? <RcpiBody obj={getDoc}/> : <></>}*/}
                    {/*</Grid>*/}
                </Grid>
                <Scroll showBelow={1000}/>
            </Container>
        </>
    );
}