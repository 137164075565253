
export const prakritiDataEng =[
    {
    sl:'1',
    char:'Body Frame',
    obj:[
            {name:'vata', value:'Thin and Lean'},
            {name:'pitta', value: 'Moderate'},
            {name:'kapha', value:'Strong physique'},
        ]
    },

    {
    sl:'2',
    char:'Color of Hair',
    obj:[
            {name:'vata', value:'Pale Brown'},
            {name:'pitta', value:'Red or Brown' },
            {name:'kapha', value:'Jet Black'}
        ]
    },
    {sl:'3',char:'Type of Hair',obj:[{name:'vata', value:'Dry and With Split Ends'},{name:'pitta', value:'Normal, Thin, More Hair fall'},{name:'kapha', value:'Greasy, Heavy'},]},
    {sl:'4',char:'Skin',obj:[{name:'vata', value:'Dry, Rough'},{name:'pitta', value:'Soft, More Sweating, Acne'},{name:'kapha', value:'Moist, Greasy'},]},
    {sl:'5',char:'Complexion',obj:[{name:'vata', value:'Dark, Blackish'},{name:'pitta', value:'Pink to Red'},{name:'kapha', value:'Glowing, White'},]},
    {sl:'6',char:'Body Weight',obj:[{name:'vata', value:'Low, Difficult to Put on Weight'},{name:'pitta', value:'Medium, Can Easily Lose or Gain Weight'},{name:'kapha', value:'Overweight, Difficult to Lose Weight'},]},
    {sl:'7',char:'Size and Color of the Teeth',obj:[{name:'vata', value:'Very Big or Very Small, Irregular, Blackish'},{name:'pitta', value:'Medium Sized, Yellowish'},{name:'kapha', value:'Large, Shinning White'},]},
    {sl:'8',char:'Nails',obj:[{name:'vata', value:'Blackish, Small, Brittle'},{name:'pitta', value:'Reddish, Small'},{name:'kapha', value:'Pinkish, Big, Smooth'},]},
    {sl:'9',char:'Pace of Performing Work',obj:[{name:'vata', value:'Fast, Always in Hurry'},{name:'pitta', value:'Medium, Energetic'},{name:'kapha', value:'Slow, Steady'},]},
    {sl:'10',char:'Mental Activity',obj:[{name:'vata', value:'Quick, Restless'},{name:'pitta', value:'Smart Intellect, Aggressive'},{name:'kapha', value:'Calm, Stable'},]},
    {sl:'11',char:'Memory',obj:[{name:'vata', value:'Short Term Bad'},{name:'pitta', value:'Good Memory'},{name:'kapha', value:'Long Term is Best'},]},
    {sl:'12',char:'Grasping Power',obj:[{name:'vata', value:'Grasps Quickly but not Completely and Forgets Quickly'},{name:'pitta', value:'Grasps Quickly but Completely and Have Good Memory'},{name:'kapha', value:'Grasps Late and Retains for Longer Time'},]},
    {sl:'13',char:'Sleep Pattern',obj:[{name:'vata', value:'Interrupted, Less'},{name:'pitta', value:'Moderate'},{name:'kapha', value:'Sleepy, Lazy'},]},
    {sl:'14',char:'Intolerance to Weather Conditions',obj:[{name:'vata', value:'Aversion to Cold'},{name:'pitta', value:'Aversion To Heat'},{name:'kapha', value:'Aversion to Moist, Rainy and Cool Weather'},]},
    {sl:'15',char:'Reactions Under Adverse Situations',obj:[{name:'vata', value:'Anxiety, Worry, Irritability'},{name:'pitta', value:'Anger, Aggression'},{name:'kapha', value:'Calm, Reclusive, Sometimes Depressive'},]},
    {sl:'16',char:'Mood',obj:[{name:'vata', value:'Changes Quickly have Frequent Mood Swings'},{name:'pitta', value:'Changes Slowly'},{name:'kapha', value:'Stable Constant'},]},
    {sl:'17',char:'Eating Habit',obj:[{name:'vata', value:'Eats Quickly Without Chewing Properly'},{name:'pitta', value:'Eats at a Moderate Speed'},{name:'kapha', value:'Chews Food Properly'},]},
    {sl:'18',char:'Hunger',obj:[{name:'vata', value:'Irregular, Any Time'},{name:'pitta', value:' Sudden Hunger Pangs, Sharp Hunger'},{name:'kapha', value:'Can Skip any Meal Easily'},]},
    {sl:'19',char:'Body Temperature',obj:[{name:'vata', value:'Less than Normal, Hands and Feet are Cold'},{name:'pitta', value:'More than Normal, Face and Forehead Hot'},{name:'kapha', value:'Normal, Hands and Feet Slightly Cold'},]},
    {sl:'20',char:'Joints',obj:[{name:'vata', value:'Weak, Noise on Movement'},{name:'pitta', value:'Healthy with Optimal Strength'},{name:'kapha', value:'Heavy Weight Bearing'},]},
    {sl:'21',char:'Nature',obj:[{name:'vata', value:'Timid, Jealous'},{name:'pitta', value:'Egoistic, Fearless'},{name:'kapha', value:'Forgiving, Grateful, Not Greedy'},]},
    {sl:'22',char:'Body Energy',obj:[{name:'vata', value:'Becomes Low in Evening, Fatigues After Less Work'},{name:'pitta', value:'Moderate, Gets Tired After Medium Work'},{name:'kapha', value:'Excellent Energy Throughout Day Not Easily Fatigued'},]},
    {sl:'23',char:'Eyeball',obj:[{name:'vata', value:'Unsteady, Fast Moving'},{name:'pitta', value:'Moving Slowly'},{name:'kapha', value:'Steady'},]},
    {sl:'24',char:'Quality of Voice',obj:[{name:'vata', value:'Rough with Broken Words'},{name:'pitta', value:'Fast, Commanding'},{name:'kapha', value:'Soft and Deep'},]},
    {sl:'25',char:'Dreams',obj:[{name:'vata', value:'Sky, Wind, Flying Objects and Confusion'},{name:'pitta', value:'Fire, Light, Bright Colors, Violence'},{name:'kapha', value:'Water Pools, Gardens and Good Relationships'},]},
    {sl:'26',char:'Social Relations',obj:[{name:'vata', value:'Makes Less Friends Prefers Solitude'},{name:'pitta', value:'Good No. of Friends'},{name:'kapha', value:'Loves to Socialize. Relationships are Long Lasting'},]},
    {sl:'27',char:'Wealth',obj:[{name:'vata', value:'Spends without Thinking Much'},{name:'pitta', value:'Saves but Spends on Valuable Things'},{name:'kapha', value:'Prefers More Savings'},]},
    {sl:'28',char:'Bowel Movements',obj:[{name:'vata', value:'Dry, Hard, Blackish, Scanty Stools'},{name:'pitta', value:'Soft, Yellowish, Loose Stools'},{name:'kapha', value:'Heavy, Thick, Sticky Stools'},]},
    {sl:'29',char:'Walking Pace',obj:[{name:'vata', value:'Quick, Fast with Long Steps'},{name:'pitta', value:'Average, Steady'},{name:'kapha', value:'Slow with Short Steps'},]},
    {sl:'30',char:'Communication Skills',obj:[{name:'vata', value:'Fast, Irrelevant Talk, Speech not Clear'},{name:'pitta', value:'Good Speaker with Genuine Argumentative Skills'},{name:'kapha', value:'Authoritative, Firm and Little Speech'},]},

]


export const prakritiDataHin =[
    {
        sl:'1',
        char:'शारीरिक ढांचा',
        obj:[
            {name:'vata', value:'पतला और दुबला'},
            {name:'pitta', value: 'उदारवादी'},
            {name:'kapha', value:'मजबूत काया'},
        ]
    },

    {
        sl:'2',
        char:'बालों का रंग',
        obj:[
            {name:'vata', value:' पीला भूरा'},
            {name:'pitta', value:'लाल या भूरा' },
            {name:'kapha', value:'स्याह काला'}
        ]
    },
    {sl:'3',char:'बालों का प्रकार',obj:[{name:'vata', value:'रूखे और दोमुंहे सिरे'},{name:'pitta', value:'सामान्य, पतले, अधिक बाल झड़ना'},{name:'kapha', value:'चिकना, भारी'},]},
    {sl:'4',char: 'त्वचा',obj:[{name:'vata', value: 'सूखा, खुरदरा'},{name:'pitta', value: 'नरम, अधिक पसीना, मुँहासे'},{name:'kapha', value: 'नम, चिकना'},]},
    {sl:'5',char: 'रंग',obj:[{name:'vata', value:'सावला, काला सा'},{name:'pitta', value: 'गुलाबी से लाल'},{name:'kapha', value: 'चमकदार, सफेद'},]},
    {sl:'6',char:'Body Weight',obj:[{name:'vata', value: 'कम, वजन बढ़ाना मुश्किल'},{name:'pitta', value: 'मध्यम, आसानी से वजन घटा या बढ़ा सकता है'},{name:'kapha', value: 'अधिक वजन, वजन कम करना मुश्किल'},]},
    {sl:'7',char: 'दांतों का आकार और रंग',obj:[{name:'vata', value: 'बहुत बड़ा या बहुत छोटा, अनियमित, काला'},{name:'pitta', value:'मध्यम आकार का, पीलापन लिए हुए'},{name:'kapha', value: 'बड़ा, चमकदार सफेद'},]},
    {sl:'8',char: 'नाखून',obj:[{name:'vata', value:'काले, छोटे, नाज़ुक'},{name:'pitta', value: 'लाल, छोटा'},{name:'kapha', value: 'गुलाबी, बड़ा, चिकना'},]},
    {sl:'9',char: 'काम करने की गति',obj:[{name:'vata', value: 'तेज़, हमेशा जल्दी में'},{name:'pitta', value: 'मध्यम, ऊर्जावान'},{name:'kapha', value:'धीमा, स्थिर'},]},
    {sl:'10',char: 'मानसिक गतिविधि',obj:[{name:'vata', value: 'त्वरित, बेचैन'},{name:'pitta', value:'बुद्दिमान, ज्ञानी, आक्रामक '},{name:'kapha', value: 'शांत, स्थिर'},]},
    {sl:'11',char:'याददश्त',obj:[{name:'vata', value: 'अल्प कलिक, ख़राब'},{name:'pitta', value: 'अच्छी याददाश्त'},{name:'kapha', value: 'दीर्घ कलिक सबसे बेहतरीन'},]},
    {sl:'12',char: 'ग्रहण करने की शक्ति',obj:[{name:'vata', value: 'जल्दी ग्रहण कर लेते है लेकिन पूरी तरह से नहीं और जल्दी भूल जातें है'},{name:'pitta', value: 'जल्दी और  पूरी तरह ग्रहण कर लेते हैं और अच्छी याददाश्त भी होती है'},{name:'kapha', value: 'देर से ग्रहण करते हैं पर लम्बे समय तक याददाश्त में बना रहता है'},]},
    {sl:'13',char: 'सोने का तरीका',obj:[{name:'vata', value: 'बाधित, कम'},{name:'pitta', value:'मध्यम'},{name:'kapha', value: 'निंद्रालु, आलसी'},]},
    {sl:'14',char: 'मौसम की स्थिति के प्रति असहिष्णुता',obj:[{name:'vata', value: 'ठंड से परहेज'},{name:'pitta', value: 'गर्मी से परहेज'},{name:'kapha', value: 'नम, बरसात और ठंडे मौसम से परहेज'},]},
    {sl:'15',char: 'प्रतिकूल परिस्थितियों में प्रतिक्रिया',obj:[{name:'vata', value: 'चिंता, व्यग्रता, चिड़चिड़ापन'},{name:'pitta', value: 'क्रोध, आक्रामकता'},{name:'kapha', value: 'शांत, समावेशी, कभी-कभी अवसादग्रस्त'},]},
    {sl:'16',char: 'मनोदशा',obj:[{name:'vata', value: 'मिज़ाज़ में बार बार एवं जल्दी बदलाव' },{name:'pitta', value: 'मिज़ाज़ में धीरे धीरे बदलाव' },{name:'kapha', value: 'लगातार स्थिर' },]},
    {sl:'17',char: 'खाने की आदत',obj:[{name:'vata', value: 'ठीक से चबाए बिना जल्दी खाता है'},{name:'pitta', value: 'मध्यम गति से खाता है'},{name:'kapha', value: 'खाना ठीक से चबाएं'},]},
    {sl:'18',char: 'भूख',obj:[{name:'vata', value: 'अनियमित, किसी भी समय'},{name:'pitta', value: 'अचानक भूख का दर्द, तेज भूख'},{name:'kapha', value: 'कोई भी खाना आसानी से छोड़ सकते हैं'},]},
    {sl:'19',char: 'शरीर का तापमान',obj:[{name:'vata', value: 'सामान्य से कम, हाथ-पैर ठंडे हैं'},{name:'pitta', value: 'सामान्य से ज्यादा चेहरा और माथा गर्म'},{name:'kapha', value: 'सामान्य, हाथ और पैर थोड़ा ठंडा'},]},
    {sl:'20',char: 'जोड़',obj:[{name:'vata', value: 'कमजोर, हिलने पर आवाज़'},{name:'pitta', value: 'सर्वोत्तम शक्ति के साथ स्वस्थ'},{name:'kapha', value: 'भारी भार वहन'},]},
    {sl:'21',char: 'प्रकृति',obj:[{name:'vata', value: 'डरपोक, ईर्ष्यालु'},{name:'pitta', value: 'अहंकारी, निडर'},{name:'kapha', value: 'क्षमाशील, आभारी, लालची नहीं'},]},
    {sl:'22',char: 'शारीरिक ऊर्जा',obj:[{name:'vata', value: 'शाम में कम हो जाता है, कम काम के बाद थकान'},{name:'pitta', value: 'मध्यम, हल्के काम के बाद थक जाता है'},{name:'kapha', value: 'पूरे दिन उत्कृष्ट ऊर्जा आसानी से नहीं थकती'},]},
    {sl:'23',char: 'नेत्रगोलक',obj:[{name:'vata', value: 'अस्थिर, तेज गतिमान'},{name:'pitta', value: 'धीरे-धीरे चल रहा है'},{name:'kapha', value: 'नियमित'},]},
    {sl:'24',char: 'आवाज की गुणवत्ता',obj:[{name:'vata', value: 'टूटे हुए शब्दों के साथ कठोर'},{name:'pitta', value: 'तेज़, शानदार' },{name:'kapha', value: 'नरम और गहरा'},]},
    {sl:'25',char: 'सपने',obj:[{name:'vata', value: 'आकाश, हवा, उड़ने वाली वस्तुएं और भ्रम'},{name:'pitta', value: 'आग, प्रकाश, चमकीले रंग, हिंसा'},{name:'kapha', value: 'जल ताल, उद्यान और अच्छे संबंध'},]},
    {sl:'26',char: 'सामाजिक संबंध',obj:[{name:'vata', value: 'कम दोस्त बनाता है एकांत पसंद करता है'},{name:'pitta', value: 'दोस्तों की अच्छी संख्या'},{name:'kapha', value: 'मेलमिलाप  करना पसंद करता है। रिश्ते लंबे समय तक चलने वाले होते हैं'},]},
    {sl:'27',char:'संपत्ति',obj:[{name:'vata', value: 'बिना सोचे-समझे खर्च कर देता है'},{name:'pitta', value: 'बचाता है लेकिन कीमती चीजों पर खर्च करता है'},{name:'kapha', value: 'अधिक बचत पसंद करते हैं'},]},
    {sl:'28',char: 'मल त्याग',obj:[{name:'vata', value: 'सूखा, कठोर, काला, कम मल'},{name:'pitta', value: 'नरम, पीले, ढीले मल'},{name:'kapha', value: 'भारी, मोटा, चिपचिपा मल'},]},
    {sl:'29',char: 'चलने की गति',obj:[{name:'vata', value: 'त्वरित, तेज, लंबे कदमों के साथ'},{name:'pitta', value: 'औसत, स्थिर'},{name:'kapha', value: 'छोटे कदमों के साथ धीमा'},]},
    {sl:'30',char: 'संचार कौशल',obj:[{name:'vata', value: 'तेज़, अप्रासंगिक बात, भाषण स्पष्ट नहीं'},{name:'pitta', value: 'वास्तविक तर्क कौशल के साथ अच्छा वक्ता'},{name:'kapha', value: 'आधिकारिक, दृढ़ और छोटा भाषण'},]},

]

export const prakritiDataBen =[
    {
        sl:'1',
        char:'শারীরিক গঠন',
        obj:[
            {name:'vata', value:'পাতলা এবং চর্বিহীন'},
            {name:'pitta', value: 'পরিমিত'},
            {name:'kapha', value:'শক্তিশালী শরীর'},
        ]
    },

    {
        sl:'2',
        char:'চুলের রঙ',
        obj:[
            {name:'vata', value:'বিবর্ণ বাদামী'},
            {name:'pitta', value:'লাল বা বাদামী' },
            {name:'kapha', value:'গাঢ় কৃষ্ণবর্ণ'}
        ]
    },
    {sl:'3',char:'চুলের ধরন',obj:[{name:'vata', value:'শুকনো এবং বিভক্ত শেষ'},{name:'pitta', value:'স্বাভাবিক, পাতলা, বেশি চুল পড়া'},{name:'kapha', value:'তৈলাক্ত, ভারী'},]},
    {sl:'4',char:'ত্বক্',obj:[{name:'vata', value:'শুষ্ক, রুক্ষ'},{name:'pitta', value:'নরম, বেশি ঘাম, ব্রণ'},{name:'kapha', value:'আর্দ্র, তৈলাক্ত'},]},
    {sl:'5',char:'ত্বকের রং',obj:[{name:'vata', value:'গাঢ়, কালো'},{name:'pitta', value:'গোলাপী থেকে লাল'},{name:'kapha', value:'উজ্জ্বল, সাদা'},]},
    {sl:'6',char:'শরীরের ওজন',obj:[{name:'vata', value:'কম, ওজন রাখা কঠিন'},{name:'pitta', value:'মাঝারি, সহজেই ওজন কমাতে বা বাড়াতে পারে'},{name:'kapha', value:'অতিরিক্ত, ওজন কমানো কঠিন'},]},
    {sl:'7',char:'দাঁতের আকার এবং রঙ',obj:[{name:'vata', value:'খুব বড় বা খুব ছোট, অনিয়মিত, কালো'},{name:'pitta', value:'মাঝারি আকারের, হলুদাভ'},{name:'kapha', value:'বড়, ঝকঝকে সাদা'},]},
    {sl:'8',char:'নখ',obj:[{name:'vata', value:'কালো, ছোট, ভঙ্গুর'},{name:'pitta', value:'লালচে, ছোট'},{name:'kapha', value:'গোলাপী, বড়, মসৃণ'},]},
    {sl:'9',char:'কাজ সম্পাদনের গতি',obj:[{name:'vata', value:'দ্রুত, সর্বদা তাড়াহুড়া'},{name:'pitta', value:'মাঝারি, এনার্জেটিক'},{name:'kapha', value:'ধীর, স্থির'},]},
    {sl:'10',char:'মানসিক কার্যকলাপ',obj:[{name:'vata', value:'দ্রুত, অস্থির'},{name:'pitta', value:'স্মার্ট বুদ্ধি, আক্রমণাত্মক'},{name:'kapha', value:'শান্ত, স্থিতিশীল'},]},
    {sl:'11',char:'স্মৃতি শক্তি',obj:[{name:'vata', value:'স্বল্পমেয়াদী খারাপ'},{name:'pitta', value:'ভাল স্মৃতি'},{name:'kapha', value:'দীর্ঘমেয়াদী সেরা'},]},
    {sl:'12',char:'শেখার দক্ষতা',obj:[{name:'vata', value:'দ্রুত শেখে কিন্তু সম্পূর্ণ নয় এবং দ্রুত ভুলে যায়'},{name:'pitta', value:'দ্রুত এবং সম্পূর্ণভাবে শেখে, ভাল স্মৃতিশক্তি'},{name:'kapha', value:'ধীরগতিতে শেখে এবং দীর্ঘ স্মৃতিশক্তি'},]},
    {sl:'13',char:'নিদ্রা অভ্যাস',obj:[{name:'vata', value:'বিঘ্নিত, কম'},{name:'pitta', value:'পরিমিত ঘুম'},{name:'kapha', value:'ঘুমন্ত, অলস'},]},
    {sl:'14',char:'আবহাওয়া অসহিষ্ণুতা',obj:[{name:'vata', value:'ঠান্ডার প্রতি বিদ্বেষ'},{name:'pitta', value:'তাপ বিমুখতা'},{name:'kapha', value:'আর্দ্র, বৃষ্টি এবং শীতল আবহাওয়ার প্রতি বিদ্বেষ'},]},
    {sl:'15',char:'প্রতিকূল পরিস্থিতিতে প্রতিক্রিয়া',obj:[{name:'vata', value:'দুশ্চিন্তা, বিরক্তি, রোষপ্রবণতা'},{name:'pitta', value:'রাগ, আগ্রাসন'},{name:'kapha', value:'শান্ত, নিঃসঙ্গ, কখনও কখনও বিষণ্ণ'},]},
    {sl:'16',char:'মেজাজ',obj:[{name:'vata', value:'দ্রুত ঘন ঘন মেজাজ পরিবর্তন'},{name:'pitta', value:'ধীরে ধীরে পরিবর্তন হয়'},{name:'kapha', value:'স্থিতিশীল,শান্ত'},]},
    {sl:'17',char:'খাদ্যাভ্যাস',obj:[{name:'vata', value:'সঠিকভাবে চিবানো ছাড়াই দ্রুত খায়'},{name:'pitta', value:'মাঝারি গতিতে খায়'},{name:'kapha', value:'খাবার সঠিকভাবে চিবিয়ে খায়'},]},
    {sl:'18',char:'ক্ষুধা',obj:[{name:'vata', value:'অনিয়মিত, যেকোনো সময়'},{name:'pitta', value:'হঠাৎ ক্ষুধার্ত ব্যথা, তীব্র ক্ষুধা'},{name:'kapha', value:'সহজে যেকোনো খাবার এড়িয়ে যেতে পারেন'},]},
    {sl:'19',char:'শরীরের তাপমাত্রা',obj:[{name:'vata', value:'স্বাভাবিকের চেয়ে কম, হাত-পা ঠান্ডা'},{name:'pitta', value:'স্বাভাবিকের চেয়ে বেশি, মুখ এবং কপাল গরম'},{name:'kapha', value:'স্বাভাবিক, হাত এবং পা সামান্য ঠান্ডা'},]},
    {sl:'20',char:'গাঁট',obj:[{name:'vata', value:'দুর্বল, Weak,নড়াচড়া করার সময় শব্দ'},{name:'pitta', value:'সর্বোত্তম শক্তির সাথে স্বাস্থ্যকর'},{name:'kapha', value:'ভারী ওজন বহন করতে সক্ষম'},]},
    {sl:'21',char:'স্বভাব',obj:[{name:'vata', value:'ভীতু, ঈর্ষান্বিত'},{name:'pitta', value:'অহংকারী, নির্ভীক'},{name:'kapha', value:'ক্ষমাশীল, কৃতজ্ঞ, নির্লোভ'},]},
    {sl:'22',char:'শরীরের শক্তি',obj:[{name:'vata', value:'সন্ধ্যায় কম হয়ে যায়, অল্প কাজ করার পর ক্লান্তি আসে'},{name:'pitta', value:'পরিমিত, মাঝারি কাজের পরে ক্লান্ত হয়ে পড়ে'},{name:'kapha', value:'সারাদিনের চমৎকার শক্তি সহজেই ক্লান্ত হয় না'},]},
    {sl:'23',char:'চোখের তারা',obj:[{name:'vata', value:'অস্থির, দ্রুত চলন্ত'},{name:'pitta', value:'আস্তে আস্তে চলে'},{name:'kapha', value:'স্থির'},]},
    {sl:'24',char:'কণ্ঠস্বর',obj:[{name:'vata', value:'রুক্ষ এবং ভাঙা শব্দ'},{name:'pitta', value:'দ্রুত, আদেশসূচক'},{name:'kapha', value:'নরম এবং গভীর'},]},
    {sl:'25',char:'স্বপ্নের ধরন',obj:[{name:'vata', value:'আকাশ, বাতাস, উড়ন্ত বস্তু এবং বিভ্রান্তি'},{name:'pitta', value:'আগুন, আলো, উজ্জ্বল রং, সহিংসতা'},{name:'kapha', value:'জলাশয়, বাগান এবং ভাল সম্পর্ক'},]},
    {sl:'26',char:'সামাজিক সম্পর্ক',obj:[{name:'vata', value:'কম বন্ধু তৈরি করে একাকীত্ব পছন্দ করে'},{name:'pitta', value:'অনেক বন্ধু'},{name:'kapha', value:'সামাজিকীকরণ করতে ভালবাসে। সম্পর্ক দীর্ঘস্থায়ী হয়'},]},
    {sl:'27',char:'সম্পদ',obj:[{name:'vata', value:'কিছু না ভেবেই খরচ করে'},{name:'pitta', value:'সঞ্চয় করে কিন্তু মূল্যবান জিনিসে খরচ করে'},{name:'kapha', value:'বেশি সঞ্চয় পছন্দ করে'},]},
    {sl:'28',char:'মলত্যাগ',obj:[{name:'vata', value:'শুষ্ক, শক্ত, কালো, স্বল্প মল'},{name:'pitta', value:'নরম, হলুদাভ, আলগা মল'},{name:'kapha', value:'ভারী, পুরু, আঠালো মল'},]},
    {sl:'29',char:'হাঁটার গতি',obj:[{name:'vata', value:'দীর্ঘ পদক্ষেপের সাথে দ্রুত'},{name:'pitta', value:'গড়, অবিচলিত হাঁটা'},{name:'kapha', value:'ছোট পদক্ষেপে ধীর হাঁটা'},]},
    {sl:'30',char:'যোগাযোগ দক্ষতা',obj:[{name:'vata', value:'দ্রুত, অপ্রাসঙ্গিক, অপরিষ্কার কথা'},{name:'pitta', value:'্রকৃত তর্কপ্রবণ, ভালো দক্ষ বক্তা'},{name:'kapha', value:'কর্তৃত্বপূর্ণ, দৃঢ় এবং স্বল্প বক্তা'},]},

]