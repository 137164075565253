import React, {useEffect, useState} from "react";
import {useInput, toTitleCase} from '../hooks/inputHooK';
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, convertFromRaw} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/button";
import Modal from "@material-ui/core/Modal";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert2";
import createImagePlugin from '@draft-js-plugins/image';
import DBPath from "./dbPath";
import Scroll from "../scrollTop";

let dbPath = DBPath();
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import NewEditor from "./newEditor";
// import diseases from "../../bannerImage/disease-virology.jpg";
// import herbs from "../../bannerImage/herbs.jpg";
// import diet from "../../bannerImage/Diet1.jpg";
// import remedies from "../../bannerImage/homeRemedies1.jpg";
// import nutrients from "../../bannerImage/nutrients.jpg";
// import ColorLensIcon from '@mui/icons-material/ColorLens';
// import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
// import BookIcon from '@mui/icons-material/Book';
// import LinkIcon from '@mui/icons-material/Link';
// import LinkOffIcon from '@mui/icons-material/LinkOff';
// import ClearIcon from '@mui/icons-material/Clear';
// import RedoIcon from '@mui/icons-material/Redo';
// import UndoIcon from '@mui/icons-material/Undo';
// import ImageIcon from '@mui/icons-material/Image';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1200,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        overflowY: 'scroll',
        height: '700px',
    };
}

export default function Editinfo(props) {
    let coll = (props.col.replace(/[ -]/g, '').toLowerCase());
    let collection = coll;
    const imagePlugin = createImagePlugin();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : localStorage.getItem('language'));
    if (localStorage.getItem('language') != null) {
        collection = collection.concat(localStorage.getItem('language'));
    } else {
        collection = collection.concat(props.lang);
    }
    let [editorState, setEditorState] = useState(EditorState.createEmpty());
    let [getChangeState, setChangeState] = useState(EditorState.createEmpty());
    let [gotNewEditor, setNewEditor] = useState(EditorState.createEmpty());
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const {value: Name, bind: bindName, reset: resetName} = useInput('');
    const {value: ScName, bind: bindScName, reset: resetScName} = useInput('');

    const handleOpen = () => {
        setNewEditor('');
        resetName('');
        resetScName('');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        async function fetchDB(d) {
            try {
                let url = `${dbPath}/${collection}?q=[{"field":"name","opt":"==","value":"${d}"}]`;
                let response = await fetch(url)
                response = await response.json()
                if (response.length > 0) {
                    if (typeof (response[0].details) == 'object') {
                        const contentState = convertFromRaw(response[0].details);
                        const editorState = EditorState.createWithContent(contentState);
                        setEditorState(editorState);
                        setChangeState(editorState);
                    } else {
                        setEditorState(EditorState.createWithText("No data to display.", ""));
                    }
                } else {
                    setEditorState("")
                }
            } catch (e) {
                console.error(e);
            }
        }

        fetchDB(props.txtData)
    }, [props.txtData]);

    const saveEdit = async () => {
        let parameter = [];
        let json = {
            field: "name",
            opt: "==",
            value: props.txtData
        }
        parameter.push(json);
        let bJSON = {
            details: convertToRaw(editorState.getCurrentContent())
        }
        try {
            const response = await fetch(`${dbPath}/${collection}?q=${JSON.stringify(parameter)}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bJSON)
            });
            setChangeState(editorState);
            // const data = response;
            swal.fire({icon: 'success', title: 'Successfully Updated', timer: 1000});
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
    }

    const saveNew = (evt) => {
        let body = {};
        let json = {};

        if (coll != 'herbs') {
            json = {
                name: Name,
                details: convertToRaw(gotNewEditor.getCurrentContent())
            }
        } else {
            json = {
                name: Name,
                scientific_name: ScName,
                details: convertToRaw(gotNewEditor.getCurrentContent())
            }
        }
        body = Object.assign(json);

        try {
            const response = fetch(`${dbPath}/${collection}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            // const data = response;
            // console.log(response);
            setOpen(false);
            swal.fire({icon: 'success', title: 'Successfully Saved', timer: 2000});
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
    }

    let onStateChange = editorState => {
        setNewEditor(editorState);
    };

    const modalBody = (
        <div style={modalStyle} className={classes.paper}>
            <form onSubmit={saveNew}>
                <Container>
                    <h2 className='heading' style={{textAlign: 'center', color: '#446600'}}>Add New {props.col}</h2>
                    <p style={{textAlign: 'center', marginTop: '-10px'}}>Input Language: {getLanguage}</p>

                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <TextField name="string" {...bindName} label={`${props.col} Name`} required={true}/>
                        </Grid>
                        {coll == 'herbs' ?
                            (<Grid item xs={6}>
                                <TextField type='string' {...bindScName} label="Scientific Name" required={true}/>
                                {/*<TextField type='number' {...bindScName} label="Scientific Name" required={true}/>*/}
                            </Grid>) : (<></>)
                        }
                    </Grid>
                    <h3></h3>
                    <div style={{border: "1px solid black", padding: '2px', minHeight: '400px'}}>
                        <Editor
                            editorState={gotNewEditor}
                            onEditorStateChange={onStateChange}
                            plugins={[imagePlugin]}
                            // toolbar={{
                            //     inline: {inDropdown: true},
                            //     list: {inDropdown: true},
                            //     textAlign: {inDropdown: true},
                            //     link: {inDropdown: true},
                            //     history: {inDropdown: true},
                            //     image: {uploadCallback: uploadImageCallBack, alt: {present: true, mandatory: true}},
                            // }}
                        />
                    </div>
                    <div align="center" style={{marginTop: '15px'}}>
                        <Button size="medium"
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight: '15px', borderRadius: 8}}
                        >
                            Save
                        </Button>
                    </div>
                </Container>
            </form>
        </div>
    )

    let onEditorStateChange = editorState => {
        setEditorState(editorState);
    };
    let discart = () => {
        setEditorState(getChangeState);
    };

    return (

        <Container maxWidth="lg" style={{marginTop: '30px'}}>
            <p><b>{toTitleCase(props.col)}</b> - {toTitleCase(props.txtData)}
                <span style={{float: 'right', size: '10px'}}>
                    <Tooltip title={`Add New ${props.col}`} placement="left-start">
                    <Fab size="small" color="primary" aria-label="add">
                      <AddIcon onClick={handleOpen}/>
                      <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                      >
                            {modalBody}
                      </Modal>
                    </Fab>
                    </Tooltip>
                </span>
            </p>
            {/*{(props.language === '') ? (*/}
            <div style={{border: "1px solid black", padding: '2px', minHeight: '400px'}}>
                <Editor
                    editorStyle={{maxHeight: '800px'}}
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    // toolbar={{
                    //     inline: {inDropdown: true},
                    //     list: {inDropdown: true},
                    //     textAlign: {inDropdown: true},
                    //     link: {inDropdown: true},
                    //     history: {inDropdown: true},
                    //     image: {uploadCallback: uploadImageCallBack, alt: {present: true, mandatory: true}},
                    // }}
                />
            </div>
            {editorState != getChangeState ?
                <div align="center" style={{marginTop: '15px'}}>
                    <Button size="medium"
                            variant="contained"
                            color="primary"
                            style={{marginRight: '15px', borderRadius: 8}}
                            onClick={saveEdit}
                    >
                        Save Changes
                    </Button>

                    <Button size="medium"
                            variant="contained"
                            style={{backgroundColor: '#ffa000', borderRadius: 8}}
                            onClick={discart}
                    >
                        Discart
                    </Button>
                </div> : <></>
            }
            <Scroll showBelow={1000}/>
        </Container>

    );
}