import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import spino from "../spino.png";
import spinbak from '../bannerImage/spinbak.jpg';
import DBPath from "./admin/dbPath";
import {Add, Sort} from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import OpenImageGallery from "./hooks/openImgGallery";
import SortImageDb from "./hooks/sortImage";
import img1 from '../bannerImage/mainSlider/web_banner_01.jpg'
// import img2 from '../bannerImage/mainSlider/web_banner_02.jpg'
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import img3 from '../bannerImage/mainSlider/web_banner_03.jpg'
// import img4 from '../bannerImage/mainSlider/web_banner_04.jpg'
// import draftToHtml from "draftjs-to-html";
// import swal from "sweetalert2";
// import img5 from '../bannerImage/mainSlider/agriculture-banner.jpg'
// import Button from "@material-ui/core/Button";

let dbPath = DBPath();
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        // marginLeft: 25,
        // marginBottom: 25,
        height: '100%',
        maxWidth: '100%',
        // flexGrow: 3,
    },
    caption: {
        fontSize: '20px',
        color: 'white',
        marginTop:'20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            marginTop:'10px',
        },
    },

    img: {
        width: '100%',
        height: '380px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '130px',
        },
    },
}));


function MainSlider() {
    // const classpin = spin();
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [GalleryImg, setGalleryImg] = useState(false);
    const [getDbImg, setDbImg] = useState(false);

    const [getPathName, setPathName] = useState(null);
    const [getDialogDocumentId, setDialogDocumentId] = useState('');
    const [getSortImg, setSortImg] = useState(false);
    const [getCollectionId, setCollectionId] = useState('');
    const [getSelectedImgArr, setSelectedImgArr] = useState('');

    const [UniqueKey, setUniqueKey] = useState(false);
    // ------------

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    function manageImage(imgDb, pathName) {
        // console.log(pathName[1]);
        let x = Math.floor((Math.random() * 1000000) + 1)
        setPathName(pathName);
        setDialogDocumentId(imgDb._id);
        setSelectedImgArr(imgDb.main_slider);
        // console.log(imgDb.main_slider)
        setUniqueKey(x);
    }

    function funcSortImg(e, p) {
        let itemsArr = e.main_slider.map((val, index) => ({
            id: `item-${index}`,
            content: `${val}`
        }));

        let x = Math.floor((Math.random() * 1000000) + 1)
        setSortImg(itemsArr);
        setPathName(p);
        setCollectionId(e._id);
        setUniqueKey(x);
    }

    function addDialogClose(img) {
        // console.log(img);
        setDialogDocumentId('');
    }

    function sortDialogClose(i) {
        setDbImg(i);
        setCollectionId('');
    }

    useEffect(() => {
        let currentDate = new Date().toISOString().slice(5, 10);
        currentDate = currentDate.concat('.jpg')
        // find occasional banner available in banner folder
        async function fetchDB() {
           try {
                const responsesImg = await fetch(`https://node.dhanvantary.com/3/upload?database=dbsweb&folder=main_slider`).then(res => res.json())
                    .then((result) => {
                        if (result.length > 0) {
                            result.map((img) => {
                                // list.push(img.split('_')[1])
                                if ((img.split('_')[1]) == currentDate) {
                                    setGalleryImg(img)
                                }
                            });
                        }
                    });
            }catch (e) {
               console.error(e);
           }
        }

        async function fetchImg() {
            try{
                let url = `${dbPath}/slider?q=[{"field":"pageName","opt":"==","value":"webMain"}]`;
                // let url = `http://localhost:3001/2/query/dbsweb/slider?q=[{"field":"pageName","opt":"==","value":"webMain"}]`;
                let response = await fetch(url);
                response = await response.json();
                console.log('rrrrrrrrrrrrr',response)
                if (response) {
                    setDbImg(response[0]);
                }
            }catch (e) {
                console.error(e);
            }
        }
        fetchDB();
        fetchImg();
    }, []);

    return (
        <Container maxWidth="xl" style={{marginTop: '65px'}}>
            <Grid container spacing={1}>
                <Grid item lg={8} xs={12}>
                    <div className={classes.root}>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'ltr' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {GalleryImg
                                ? <img className={classes.img}
                                       src={`https://node.dhanvantary.com/images/dbsweb/main_slider/${GalleryImg}`}/>
                                : <img className={classes.img} src={img1}/>}

                            {getDbImg ? getDbImg.main_slider.map((data) => (
                                <img className={classes.img} src={data}/>
                            )) : <></>}
                        </AutoPlaySwipeableViews>
                        {/*//---------------------------------------------------------------//*/}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse"
                                 style={{position: "absolute", marginTop: "-80px",}}>
                                <Tooltip title="Add" aria-label="Add">
                                    <Fab size="small" color="primary" className={classes.fab}
                                        // onClick={() => this.handleImageUploadTop(["banners","papaapp"])}
                                         onClick={() => manageImage(getDbImg, ['main_slider', 'dbsweb'])}
                                    >
                                        <Add/>
                                    </Fab>
                                </Tooltip>
                                <Tooltip title="Sort" aria-label="Sort">
                                    <Fab size="small" color="default" className={classes.fab} style={{backgroundColor: "#e8cf2c"}}
                                         onClick={() => funcSortImg(getDbImg, ['main_slider', 'slider'])}
                                        // ['main_slider','slider'] e.g-[field name, collection name]
                                    >
                                        <Sort/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                        {/*--------------image Dialogue import ----------------*/}
                        {getDialogDocumentId ? <OpenImageGallery key={UniqueKey} imagePath={getPathName}
                                                       docField={'main_slider'} docId={getDialogDocumentId}
                                                       collectionName={'slider'} imgArr={getSelectedImgArr}
                                                       successCallBack={addDialogClose}/> : <></ >}
                        {getCollectionId ? <SortImageDb key={UniqueKey} images={getSortImg} imagePath={getPathName}
                                                        collectionName={'slider'}
                                                        docField={'main_slider'} docId={getCollectionId}
                                                        successCallBack={sortDialogClose}/> : <></>}

                        {/*{getSortImgStat ? <SortImageDb key={UniqueKey} images={getSortImg} dialogOpen={true}*/}
                        {/*collectionName={'slider'}/> : <></>}*/}
                        {/*//---------------------------------------------------------------//*/}

                    </div>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <div className="App" style={{backgroundImage: `url(${spinbak})`, width:'100%', height:'auto'}}>
                        {/*style={{backgroundColor:"silver"}}*/}
                        {/*<header className="App-header">*/}
                        <img src={spino} className="App-logo" alt="logo"/>
                        <p className={classes.caption}>
                            Our mission is to reach every family <br/> to keep them healthy & strong.
                        </p>
                        <br/>
                        {/*<a*/}
                        {/*    className="App-link"*/}
                        {/*    href="https://reactjs.org"*/}
                        {/*    target="_blank"*/}
                        {/*    rel="noopener noreferrer"*/}
                        {/*>*/}
                        {/*    Learn React*/}
                        {/*</a>*/}
                        {/*</header>*/}
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default MainSlider;