import React, {useEffect, useState} from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Container from "@material-ui/core/Container";
import certificateBanner from "../bannerImage/Page_top_banner/certificate.jpg";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import DBPath from "./admin/dbPath";
// import ImageList from '@mui/material/ImageList';
// import Modal from "@material-ui/core/Modal";
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
let dbPath = DBPath();
{/*
const itemData = [
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-1.png',
        title: 'GMP',
        caption: 'GMP Ensuring that products are consistently produced and controlled according to quality standards.',
        certificate: 'https://dhanvantary.com/Images/certificates/gmp.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-2.png',
        title: 'RoHS',
        caption: 'RoHS stands for Restriction of Hazardous Substances also known as Directive 2002/95/EC.',
        certificate: 'https://dhanvantary.com/Images/certificates/rohs.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-3.png',
        title: 'ISO',
        caption: 'ISO 9001-international standard that specifies requirements for a quality management system (QMS).',
        certificate: 'https://dhanvantary.com/Images/certificates/wooden-frameiso.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-4.png',
        title: 'SA',
        caption: 'International certification standard that encourages organizations to develop, maintain and apply socially acceptable practices in the workplace.',
        certificate: 'https://dhanvantary.com/Images/certificates/sa.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-5.png',
        title: 'Halal',
        caption: 'Halal is an Arabic word meaning lawful or permitted. In reference to food, it is the dietary standard, as prescribed in the Koran.',
        certificate: 'https://dhanvantary.com/Images/certificates/halal.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-6.png',
        title: 'HACCP',
        caption: 'Hazard Analysis Critical Control Point (HACCP) HACCP is a management system in which food safety is addressed',
        certificate: 'https://dhanvantary.com/Images/certificates/haccp.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-7.png',
        title: 'FDA',
        caption: 'Food and Drug Administration (FDA) prepare information about a product\'s regulatory or marketing status',
        certificate: 'https://dhanvantary.com/Images/certificates/fda.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-8.png',
        title: 'GDP',
        caption: 'Is a quality warranty system, which includes requirements for purchase, receiving, storage and export of drugs intended for human consumption.',
        certificate: 'https://dhanvantary.com/Images/certificates/gdp.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-9.png',
        title: 'Green Guard',
        caption: 'The Greenguard Environmental Institute.',
        certificate: 'https://dhanvantary.com/Images/certificates/greenguard.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-11.png',
        title: 'CE',
        caption: 'Indicates conformity with health, safety, and environmental protection standards for products sold within the European Economic Area (EEA).',
        certificate: 'https://dhanvantary.com/Images/certificates/ce.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-12.png',
        title: 'CVC',
        caption: 'CVC is asking individuals to take a pledge to root out corruption.',
        certificate: 'https://dhanvantary.com/Images/certificates/cvc.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-13.png',
        title: '5S',
        caption: 'The 5S method is a structured program it means - 1.Sort 2.Set in Order 3.Shine 4.Standardize 5.Sustain',
        certificate: 'https://dhanvantary.com/Images/certificates/5s.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/ISO-2005-logo.png',
        title: 'ISO',
        caption: 'ISO 9001-international standard that specifies requirements for a quality management system (QMS).',
        certificate: 'https://dhanvantary.com/Images/certificates/iso-2005-frm.png',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/Kosher-logo.png',
        title: 'Kosher',
        caption: 'Kosher Certification',
        certificate: 'https://dhanvantary.com/Images/certificates/kosher-frm.png',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/iso-2013.png',
        title: 'ISO',
        caption: 'ISO 9001-international standard that specifies requirements for a quality management system (QMS).',
        certificate: 'https://dhanvantary.com/Images/certificates/iso-2103-frm.png',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/ICL-logo.png',
        title: 'ICL',
        caption: 'ICL ISO Certification',
        certificate: 'https://dhanvantary.com/Images/certificates/icl-frm.jpg',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/ohsas-logo.png',
        title: 'OHSAS',
        caption: 'OHSAS Certification',
        certificate: 'https://dhanvantary.com/Images/certificates/ohsas-frm.png',
    },
    {
        img: 'https://dhanvantary.com/Images/certificates/certificate-12.png',
        title: 'CVC',
        caption: 'CVC is asking individuals to take a pledge to root out corruption.',
        certificate: 'https://dhanvantary.com/Images/certificates/vigilance-frm.jpg',
    },
];
*/
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        '&.element.style': {
            gridTemplateColumns: 'repeat(4, 1fr)',
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
        },
    },
    image: {
        minHeight: 350,
        maxHeight: 350,
        minWidth: '92%',
        maxWidth: '92%',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            minHeight: 250,
            maxHeight: 250,
            minWidth: '90%',
            maxWidth: '90%',
        },
    },
}));

export default function Certificate() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [cert, setCert] = useState('');
    const [getCrtDb, setCrtDb] = useState('');

    useEffect(() => {
        async function fetchCertificate() {
            try{
                let url = `${dbPath}/dbsCertificate`;
                // let url = `http://localhost:3001/2/query/dbsweb/dbsCertificate`;
                let response = await fetch(url)
                response = await response.json()
                if (response.length > 0) {
                    setCrtDb(response);
                }
            }catch (e) {
                console.error(e);
            }
        }
        fetchCertificate();
    }, []);


    return (
        <>
            <div>
                <img src={certificateBanner} alt={'certificate'} className={'topBanner'}/>
            </div>
            <Container maxWidth="lg" style={{marginTop: '50px'}}>
                {/*<ImageList style={{maxHeight: '50%'}}>*/}
                <Grid container spacing={1}>
                    {getCrtDb ? getCrtDb.map((item) => (
                        <Grid item lg={3} xs={6}>
                            <ImageListItem className={classes.image} key={item.img}
                                           style={{margin: '1px', padding: '10px', border: '1px solid'}}>
                                <img
                                    src={item.img}
                                    // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    onClick={() => {
                                        setOpen(true);
                                        setTitle(item.title);
                                        setCert(item.certificate);
                                    }}
                                />

                                <ImageListItemBar
                                    title={<p style={{fontWeight: 'bold'}}>{item.title}</p>}
                                    subtitle={<p style={{
                                        fontFamily: 'serif',
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal'
                                    }}>{item.caption}</p>}
                                    position="below"
                                />
                            </ImageListItem>
                        </Grid>
                    )) : <></>}
                </Grid>
                {/*</ImageList>*/}

            </Container>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <div style={{margin: '20px'}}>
                    <img src={cert} alt={'Certificate'} style={{width: '100%'}}/>
                </div>
            </Dialog>

        </>

    );
}
