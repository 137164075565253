import React from 'react';
import Button from '@material-ui/core/Button';
import SpaIcon from '@mui/icons-material/Spa';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import {NavLink} from "react-router-dom";

const Txt = withStyles((theme)=>({
    root: {
        fontSize: 16,
        fontFamily: "Arial Black",
        color: "#fff",
        [theme.breakpoints.down('sm')]: {
            fontFamily: "Arial",
            fontSize: 12,
        },
    },
}))(Typography);

const useStyles = makeStyles((theme) => ({
    container:{
        textAlign: 'center',
        backgroundColor:'#446600',
        minWidth:'97%',
        maxWidth:'97%',
    },
    Button:{
        paddingLeft:'10%',
        color:'#caa136',
        [theme.breakpoints.down('sm')]: {
            paddingLeft:'5%',
        },
    },
}));
export default function MenuBar2() {
    const classes = useStyles();
    return (
        // <Grid container item lg={12} style={{textAlign: 'center', backgroundColor:'#99ff33', marginLeft:'20px',marginRight:'20px'}}>
        //     <Container maxWidth="xl" style={{textAlign: 'center', backgroundColor:'#446600', width:'99%'}}>
            <Container maxWidth="xl" className={classes.container}>
                <Button startIcon={<HealthAndSafetyIcon/>} className={classes.Button}><Txt variant="body2"><NavLink
                    className='nav-links' to="/">Health Care</NavLink></Txt></Button>
                <Button startIcon={<SelfImprovementIcon/>} className={classes.Button}><Txt variant="body2"><NavLink
                    className='nav-links' to="/">Personal Care</NavLink></Txt></Button>
                <Button startIcon={<SpaIcon/>} className={classes.Button}><Txt variant="body2"><NavLink
                    className='nav-links' to="/">Beauty Care</NavLink></Txt></Button>
                <Button startIcon={<AgricultureIcon/>} className={classes.Button}><Txt variant="body2"><NavLink
                    className='nav-links' to="/">Agriculture</NavLink></Txt></Button>
            </Container>
        // </Grid>
    );
}