import React, {useEffect, useState} from 'react';
import "../App.css";
import {Link} from "react-router-dom";
import List from "@mui/material/List";
import GetSortOrder, {toTitleCase} from "./hooks/inputHooK";
import DBPath from "./admin/dbPath";
import Grid from "@material-ui/core/Grid";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Scroll from "./scrollTop";
// import MoreAyurBody from "./moreAyurBody";
// import WhyAyurveda from "./whyAyurveda";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Button from "@material-ui/core/Button";
// import kyb from "../bannerImage/kyb.jpg";
// import DiseasesList, {DiseasesDropList} from "./diseasesList";
// import HerbsList, {HerbsDropList} from "./herbsList";
// import DietChartList, {DietChartDropList} from "./dietChartList";
// import draftToHtml from "draftjs-to-html";
// import useWindowDimensions from "./hooks/utilityHooK";
// import {makeStyles} from "@material-ui/core/styles";
// import Information from "./detailBody";
let dbPath = DBPath();

// const useStyles = makeStyles((theme) => ({
//     scrol: {
//         overflowY: "auto",
//         margin: 0,
//         padding: 0,
//         listStyle: "none",
//         height: "1700px",
//         marginTop: "-40px",
//         // paddingRight:'100px',
//         '&::-webkit-scrollbar': {
//             width: '0.4em'
//         },
//         '&::-webkit-scrollbar-track': {
//             boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
//             webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginTop: "-20px",
//             height: "auto",
//         },
//     },
//     title: {
//         marginTop: "30px",
//         fontFamily: "Copperplate, Fantasy",
//         fontSize: "35px",
//         color: "#fff",
//         // textShadow: "2px 2px 5px #f3fa2f",
//         textAlign: "center",
//         backgroundColor: '#8cd674',
//         [theme.breakpoints.down('sm')]: {
//             fontSize: "20px",
//         },
//     },
// }));

export default function MoreAyur(props) {

    // const classes = useStyles();
    // const [gotDocName, getDocName] = useState('');
    // const [getDoc, setDoc] = useState('');
    const [newvalue, setNewValue] = useState('');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const currentURL = window.location.pathname;
    let collection = 'more';

    useEffect(() => {
        async function fetchMyAPI(collection) {
            try{
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                response = await response.json();
                let menu_sorted = response.sort(GetSortOrder("name"));
                setNewValue(menu_sorted);
                if (sessionStorage.getItem("objId")) {
                    props.callListMore(JSON.stringify(menu_sorted.filter(item => item._id == sessionStorage.getItem("objId"))[0]));
                } else {
                    props.callListMore(JSON.stringify(menu_sorted[0]));
                }
            }catch (e) {
                console.error(e);
            }
        }
        fetchMyAPI(collection);
    }, []);

    return (
        <>
            <div>
                <p className="heading" style={{
                    margin: "0% 10% 5% 5%",
                    padding: "2% 0% 2% 5%",
                    backgroundColor: "#8cd674",
                }}>More About Ayurveda</p>
                <List className='sideList'
                      style={{
                          marginLeft: '5%',
                          marginTop: '-5%',
                          paddingRight: '5px',
                          maxHeight: "500px",
                      }}>
                    {newvalue ? newvalue.map((data) => {
                        return (
                            <ul>
                                <li style={{
                                    padding: '3px 0px 3px 0px',
                                    margin: '6px 0px 6px 0px',
                                    backgroundColor: "#fff",
                                }}
                                >
                                    {/*<Link className='sideLink' to={{pathname: "/more"}}>{toTitleCase(data.name)}</Link>*/}
                                    <Link
                                        className='sideLink'
                                        to={{
                                            pathname: "/ayurveda",
                                        }}
                                        style={sessionStorage.getItem('objId') === data._id ? {
                                            color: "black", fontWeight: "bold"
                                        } : {color: "#446600"}}
                                        onClick={() => {
                                            props.callListMore(JSON.stringify(newvalue.filter(par => par._id === data._id)[0]));
                                            sessionStorage.setItem('objId', data._id)
                                            // localStorage.setItem('yogaDetails', JSON.stringify(newvalue.filter(par => par._id === data._id)[0]));
                                        }}
                                    >{toTitleCase(data.name.toLowerCase())}</Link>
                                </li>
                            </ul>
                        );
                    }) : <></>}
                </List>
            </div>
        </>
    );
}


export function MoreDropAyur(props) {
    // const classes = useStyles();
    const [getList, setList] = useState('');
    // const [getDoc, setDoc] = useState('');
    // const [getObjId, setObjId] = useState('');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    // const {height, width} = useWindowDimensions();

    let collection = 'more';
    // collection = collection.concat(getLanguage);
    useEffect(() => {
        sessionStorage.setItem('objId', '');

        async function fetchList(collection) {
            try{
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                const json = await response.json();
                let menu_sorted = json.sort(GetSortOrder("name"));
                setList(menu_sorted);
            }catch (e) {
                console.error(e);
            }
            // setDoc(sessionStorage.getItem("objId") ? JSON.stringify(menu_sorted.filter(par => par._id === String(sessionStorage.getItem("objId")))[0]) : JSON.stringify(menu_sorted[0]));
        }

        fetchList(collection);
    }, [collection]);

    // function MoreBody(props) {
    //     return (
    //         <MoreAyurBody obj={props.obj} lang={getLanguage}/>
    //     )
    // }

    return (
        <>
            {/*<div>*/}
            {/*    <img src={banner} alt={'image'} className={'topBanner'}/>*/}
            {/*</div>*/}
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {getList.length > 0 ?
                            <FormControl sx={{width: 1}}>
                                <Autocomplete
                                    value={sessionStorage.getItem("objId") ? getList.filter(par => par._id === String(sessionStorage.getItem("objId")))[0] : {name: ''}}
                                    // value={getObjId ? getList.filter(par => par._id === String(getObjId))[0] : {name: ''}}
                                    options={getList}
                                    getOptionLabel={(option) => toTitleCase(option.name.toLowerCase())}
                                    // defaultValue={localStorage.getItem('knowMore')}
                                    //toTitleCase(option.name.toLowerCase())
                                    onChange={(event, value) => {
                                        // localStorage.setItem('colName', 'more');
                                        // localStorage.setItem('knowMore', value.name)
                                        // window.location.assign("/more");
                                        if (value) {
                                            // props.callback(value.banner);
                                            sessionStorage.setItem('objId', value._id);
                                            // sessionStorage.setItem('screenSize', 'xs');
                                            // setDoc(JSON.stringify(getList.filter(par => par._id === value._id)[0]));
                                            props.callDropMore(JSON.stringify(getList.filter(par => par._id === value._id)[0]));
                                        }

                                        // localStorage.setItem('knowMore', JSON.stringify(getList.filter(par => par._id === value._id)[0]))

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="More About Ayurveda"
                                        />
                                    )}
                                />
                            </FormControl> :
                            <></>
                        }
                    </Grid>
                </Grid>
                <Scroll showBelow={1000}/>
            </Container>
        </>
    );
}

/*
const classes = useStyles();
const moreAbout = ["What Is Ayurveda",
"Eight Branches Of Ayurveda",
"Manas Prakriti",
"Ayurveda - A Holistic Approach Toward Health Of A Mankind",
"Ayurveda Fundamentals",
"Ashtanga Ayurveda (8 Limbs Of Ayurveda)",
"Lakshana And Classification Of Siddhanta",
"Darshana",
"Padartha",
"Dravya - Lakshana Classification Enumeration",
"Kala In Ayurveda",
"Atma With Its Importance In Ayurveda",
"Purusha",
"Manas And Its Importance In Ayurveda",
"Manas Chikitsa In Ayurveda",
"Tamas As The Tenth Dravya",
"Gunas In Ayurveda",
"Karma Vigyaniyam",
"Samanya Vigyaniyam",
"Vishesha Vigyaniyam",
"Samavaya Vigyaniyam",
"Abhava Vigyaniyam",
"Pramana Pariksha Vigyaniyan",
"Karya Karana Siddhanta",
"History Of Ayurveda",
"Origin Of Ayurveda",
"Concept Of Panchmahabhuta (Five Elements)",
"Concept Of Trienergies",
"Vata-Pitta-Kapha Body Types & Personalities",
"Vata",
"Pitta",
"Kapha",
"Vata Dosha",
"Pitta Dosha",
"Kapha Dosha",
"Dosha Vriddhi And Dosha Kshaya",
"Panchmahabhoot And Tridosha",
"Triguna Theory Of Ayurveda",
"Srotas - Channels Of Circulation In Human Body",
"Prakriti In Ayurveda",
"Trayostambh In Ayurveda",
"Unbalanced Diet - Virudh Ahara",
"Vipaka In Ayurveda",
"Prabhava In Ayurveda",
"Concept Of Agni In Ayurveda",
"13 Types Of Agni In Ayurveda",
"Concept Of Ama In Ayurveda",
"Agni Versus Ama",
"Panchkarma In Ayurveda",
"Panchakarma Procedures",
"Methods Of Diagnosis In Ayurveda",
"Rasashastra In Ayurveda",
"Ama-Vata (Rheumatoid Arthritis) And Ayurveda",
"Preventive Aspects Of Ayurveda",
"The Basics Of Staying Healthy With Ayurveda",
"Snehana In Ayurveda",
"Yoga And Meditation In Ayurveda",
"Virechana Therapy In Ayurveda",
"Raktamokshana Therapy In Ayurveda",
"Stress Leads To Many Diseases",
"Relation Between Mental And Physical Disorders",
"How Ayurveda Helps In Healing The Problem?",
"Nasya Chikitsa In Ayurveda",
"Virya And Its Significance",
"Rasayana And Vajikarana",
"Ashtanga Yoga (8 Limbs Of Yoga)",
"Shadrasa - 6 Tastes In Ayurveda",
"Pancha Kashaya Kalpana",
"Kriyakala",
"Aharapaka",
"Sapta Dhatus (Seven Tissues) In Ayurveda",
"Ojas (Vyadhishamta)",
"Ashraya-Ashrayi Bhava",
"Updhatu",
"Mala - Waste Products",
"Concept Of Sharir (Human Body)",
"Naadi Pariksha (Pulse Diagnosis)",
"Shira (Blood Vessels)",
"Brahmcharya",
"Garbha Sharira (Ayurvedic Embryology)",
"Koshtha",
"Dhamani (Artery)",
"Asthi (Bone)",
"Snayu (Ligaments)",
"Authors",
];
return (
<div>
    <p className="heading" style={{
        margin: "0% 10% 5% 5%",
        padding: "2% 0% 2% 5%",
        backgroundColor: "#8cd674",
    }}>More About Ayurveda</p>
    <List
        sx={{
            width: '100%',
            marginLeft: '5%',
            marginTop: '-5%',
            paddingLeft: 3,
            maxWidth: '80%',
            // bgcolor: 'background.paper',
            bgcolor:'#d8f0c7',
            borderLeft: '1px solid #446600',
            borderBottom: '2px solid #446600',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 500,
            '& ul': {padding: 0},
        }}
    >
        {
            moreAbout.map(
                (moreName) => <ul>
                    {/*<button  to="/itm">{itemList}</button>/}
                    <li style={{margin:'5px 0px 5px 0px', backgroundColor: "#fff",}}><Button className={classes.sideLink}>{moreName}</Button></li>
                </ul>
            )
        }
    </List>
</div>
);
*/

