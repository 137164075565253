import React, {useEffect, useState} from 'react';
import "../App.css";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import HO_day from '../bannerImage/dbsHO_day.png';
import HO_night from '../bannerImage/dbsHO_night.png';
import img2 from '../bannerImage/cmd_pg1.jpg';
import {makeStyles} from "@material-ui/core/styles";
import fpwa from '../bannerImage/fpwa.jpg';
import DBPath from "./admin/dbPath";
import draftToHtml from "draftjs-to-html";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import DraftEdit from "./admin/draftEdit"
import {fetchPage} from "./hooks/inputHooK";

let dbPath = DBPath();
// import Modal from "@material-ui/core/Modal";
// import KnoWhat from "./knowhat";
// import Divider from '@material-ui/core/Divider';
// import Typography from '@material-ui/core/Typography';
// import {withStyles} from "@material-ui/core/styles";
// import {makeStyles} from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    img1: {
        width: '400px',
        height: 'auto',
        borderRadius: '8px',
        marginTop: '30px',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            marginTop: '0px',
        },
    },
    imgBak1: {
        borderRadius: '8px',
        backgroundColor: '#4e9525',
        opacity: '.302',
        position: 'absolute',
        // top: '-35px',
        left: '110px',
        zIndex: 0,
        width: '25%',
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    img2: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        marginTop: '210px',
        // marginLeft: '25%',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            margin: '0px',
        },
    },
    imgBak2: {
        borderRadius: '8px',
        backgroundColor: '#4e9525',
        opacity: '.302',
        position: 'absolute',
        marginTop: '170px',
        left: '66%',
        zIndex: 0,
        width: '26%',
        height: '52%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    // floatButton:{
    //     // position: "absolute",
    //     marginTop: "-42px",
    // },

}));

// const Txt = withStyles({
//     root: {
//         fontSize: 16,
//         fontFamily: "Prata",
//         color: "gray",
//     },
// })(Typography);

export default function WelcomeNote(props) {
    const classes = useStyles();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getBlock, setBlock] = useState('');
    // const [getCallbackState, setCallbackState] = useState('');
    // const [open, setOpen] = useState(false);
    const [getDialog, setDialog] = useState('');
    let pageName = 'welcome';

    var time = new Date();
    let ct = time.toLocaleString('en-US', { hour: 'numeric', hour12: true });
    // console.log(ct);
    ct=ct.toString();
    let hr = parseInt(ct.substr(0,2).trim());
    let meridiem = ct.substr(2,3).trim();
    // console.log(hr);

    // function formatAMPM(date) {
    //     var hours = date.getHours();
    //     var minutes = date.getMinutes();
    //     var ampm = hours >= 12 ? 'pm' : 'am';
    //     hours = hours % 12;
    //     hours = hours ? hours : 12; // the hour '0' should be '12'
    //     minutes = minutes < 10 ? '0'+minutes : minutes;
    //     var strTime = hours + ':' + minutes + ' ' + ampm;
    //     return strTime;
    // }
    //
    // console.log('-----> ',formatAMPM(new Date));

    // const handleClose = () => {
    //     setOpen(false);
    // };

// console.log('session language',getLanguage)
//     async function fetchDB() {
//         let url = `${dbPath}/pages?q=[{"field":"page","opt":"==","value":"welcome"}]`;
//         let response = await fetch(url);
//         response = await response.json();
//         setPageDb(response);
//     }
//
//     useEffect(() => {
//         fetchDB();
//     }, []);

    //------------ 'EDIT' dialog-box -----------
    function callbackFunc(state, block, head, body) {
        if (!state) {
            console.log(block)
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {

        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------

    return (
        // <div className={classes.root}>
        <Container maxWidth="lg" style={{marginTop: '50px'}}>

            <Grid container spacing={0}>
                <Grid item lg={5} xs={12} style={{float: 'right'}}>
                    {/*<Paper className={classes.paper}>*/}
                    <div>
                        <div className={classes.imgBak1}></div>
                        <div>
                            <imageTtB>
                                {/*<img src={img1} alt={'image'} width='400px' height='auto' style={{borderRadius: '8px',marginTop:'30px'}}/>*/}
                                {meridiem==='AM' && (hr>=5 && hr!=12) ? <img src={HO_day} alt={'img1'} className={classes.img1}/>:
                                (meridiem==='PM' && (hr<=4 || hr==12) ? <img src={HO_day} alt={'img1'} className={classes.img1}/>:<img src={HO_night} alt={'img_N'} className={classes.img1}/>)}
                            </imageTtB>
                        </div>
                    </div>
                    {/*</Paper>*/}
                </Grid>
                <Grid item lg={7} xs={12}>
                    {/*<Paper className={classes.paper}>*/}
                    <h4 className='heading headingBak'>{getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                        // className={classes.fab}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[0].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                    </h4>
                    <textRtL className='welcomeBody'
                             dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></textRtL>
                </Grid>
                <Grid item lg={8} xs={12}>
                    {/*<Paper className={classes.paper} style={{}}>*/}
                    <h4 className='heading headingBak' id="div3">{getPageDb[1] ? getPageDb[1][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                        // className={classes.fab}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[1].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                        {/*{getDialog ? <DraftEdit info={info} block={'chairman'} successCallBack={callbackFunc}/> : <></>}*/}
                    </h4>
                    <textLtR className='welcomeBody'
                             dangerouslySetInnerHTML={{__html: `${getPageDb[1] ? draftToHtml(getPageDb[1][`body${getLanguage}`]) : ''}`}}></textLtR>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <div>
                        <div className={classes.imgBak2}></div>
                        <div>
                            <imageBtT>
                                <img src={img2} alt={'img2'} className={classes.img2}/>
                            </imageBtT>
                        </div>
                    </div>
                </Grid>

                <Grid item lg={12} xs={12}>
                    {/*<Paper className={classes.paper} style={{}}>*/}
                    <h4 className='heading headingBak'>{getPageDb[4] ? getPageDb[4][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[4].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                    </h4>
                    <textRtL className='welcomeBody'
                             dangerouslySetInnerHTML={{__html: `${getPageDb[4] ? draftToHtml(getPageDb[4][`body${getLanguage}`]) : ''}`}}></textRtL>
                </Grid>
                <Grid item lg={12} xs={12}>
                    {/*<Paper className={classes.paper} style={{}}>*/}
                    <h4 className='heading headingBak' id="div3">{getPageDb[3] ? getPageDb[3][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[3].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                    </h4>
                    <textRtL className='welcomeBody'
                             dangerouslySetInnerHTML={{__html: `${getPageDb[3] ? draftToHtml(getPageDb[3][`body${getLanguage}`]) : ''}`}}></textRtL>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <div>
                        {/*<div className={classes.imgBak1}></div>*/}
                        {/*<div>*/}
                        <imageTtB>
                            {/*<img src={fpwa} alt={'image'} width='400px' height='auto' style={{borderRadius: '8px',marginTop:'30px'}}/>*/}
                            <img src={fpwa} alt={'fpwa'} className={classes.img1} style={{marginTop: '80px'}}/>
                        </imageTtB>
                        {/*</div>*/}
                    </div>
                </Grid>

                <Grid item lg={8} xs={12}>
                    <h4 className='heading headingBak' id="div3">{getPageDb[2] ? getPageDb[2][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[2].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                    </h4>
                    <textLtR className='welcomeBody'
                             dangerouslySetInnerHTML={{__html: `${getPageDb[2] ? draftToHtml(getPageDb[2][`body${getLanguage}`]) : ''}`}}></textLtR>
                </Grid>

            </Grid>
            {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
            {/*{getDialog ? <callDraftEdit info={info} block={getBlock} />:''}*/}
        </Container>
        // </div>
    );

}