import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import img_sir from '../bannerImage/chairman.jpg';
import Container from "@material-ui/core/Container";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import {fetchPage} from "./hooks/inputHooK";
import DraftEdit from "./admin/draftEdit";
import draftToHtml from "draftjs-to-html";
import swal from "sweetalert2";
// import img1 from "../bannerImage/web_logo.jpg";
// import Grid from "@material-ui/core/Grid";

const cmd_style = makeStyles((theme) => ({
    // backgroundImage: img_sir,
    sir_xyz: {
        backgroundImage: `url(${img_sir})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "auto",
        width: '100%',
        paddingTop: 1000,
        marginLeft: 250,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 220,
            marginLeft: -10,
        },
    },
    cmdDesk: {
        fontFamily: 'Cormorant Upright',
        fontSize: '20px',
        columnCount: 2,
        textAlign: 'justify',
        width: '45%',
        paddingLeft: '50px',
        paddingTop: '50px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            padding: '2%',
            width: '95%',
            columnCount: 1,
        },
    },
    cmdSpecial: {
        // fontFamily: 'Cormorant Upright',
        fontSize: '20px',
        columnCount: 2,
        textAlign: 'justify',
        // width: '85%',
        paddingLeft: '50px',
        paddingRight: '50px',
        // position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            padding: '2%',
            width: '95%',
            columnCount: 1,
        },
    },
}));

function Chairman(props) {
    const classes = cmd_style();
    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'chairman';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------
    return (
        <Container maxWidth="xl" style={{marginTop: '80px'}}>
            {/*<Grid container spacing={0}>*/}
            {/*<Grid item lg={6} xs={12} style={{marginTop: '80px'}}>*/}
            <div>
                <div className={classes.cmdDesk}>
                    <h1 className='heading' style={{textAlign: 'center'}}>
                        {/*Mr. Pranab Meur*/}
                        {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[0].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                    </h1>
                    <text className='welcomeBody'
                        dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></text>
                </div>
                {/*</Grid>*/}
                {/*<Grid item xl={12} xs={12} style={{paddingLeft: '300px'}}>*/}
                <div className={classes.sir_xyz}/>

                {/*---------------------------------------------*/}
            </div>

            {/*<div>*/}
            {/*    <div className={classes.cmdSpecial}>*/}
            {/*        <h1 className='heading' style={{textAlign: 'center'}}>*/}
            {/*            {getPageDb[1] ? getPageDb[1][`head${getLanguage}`] : ''}*/}
            {/*            {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?*/}
            {/*                <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>*/}
            {/*                    <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">*/}
            {/*                        <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}*/}
            {/*                             onClick={() => {*/}
            {/*                                 handelEditor(getPageDb);*/}
            {/*                                 setBlock(getPageDb[1].block)*/}
            {/*                             }}*/}
            {/*                        >*/}
            {/*                            <Edit/>*/}
            {/*                        </Fab>*/}
            {/*                    </Tooltip>*/}
            {/*                </Box>*/}
            {/*                : <></>}*/}
            {/*        </h1>*/}
            {/*        <text*/}
            {/*            dangerouslySetInnerHTML={{__html: `${getPageDb[1] ? draftToHtml(getPageDb[1][`body${getLanguage}`]) : ''}`}}></text>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
        </Container>
    )
}

export default Chairman;

