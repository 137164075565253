import React, {useState} from "react";
import "../App.css";
// import {render} from "react-dom";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import {Tabs, Tab} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import KnoWhat from "./knowhat";
import diseases from "../bannerImage/Page_top_banner/diseases_banner.jpg";
import herbs from "../bannerImage/Page_top_banner/herbs.jpg";
import diet from "../bannerImage/Page_top_banner/diet.jpg";
import remedies from "../bannerImage/Page_top_banner/home_remidies.jpg";
import nutrients from "../bannerImage/Page_top_banner/nutrients.jpg";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";

// const useTabStyles = makeStyles({
const useTabStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
    },
    scroller: {
        flexGrow: "0",
    },
}));

export default function TopTab(props) {
    const classes = useTabStyles();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');

    let colN = '';
    // let docN = '';

    // if (prpos.location.state) {
    //     colN = prpos.location.state.colName;
    //     docN = prpos.location.state.docName;
    // } else if (sessionStorage.getItem('colName')) {
    //     colN = sessionStorage.getItem('colName');
    // } else {
    //     colN = 'diseases';
    // }

    if (localStorage.getItem('colName')) {
        colN = localStorage.getItem('colName');
    } else {
        colN = 'diseases';
    }

    const [getColName, setColName] = useState(colN);

    // const [getLanguage, setLanguage] = useState((sessionStorage.getItem('language') != null) ? (sessionStorage.getItem('language')) : 'Eng');
    // console.log('lllllllllllllllllllllllll',sessionStorage.getItem('language'));
    const tabList = ["Diseases", "Herbs", "Diet Chart", "Home Remedies", "Nutrients A-Z"];

    // sessionStorage.setItem('lng','Eng')

    function fetchData(par) {
        if (par) {
            setColName(par.replace(/ /g, '').toLowerCase());
            localStorage.setItem('colName', (par.replace(/ /g, '').toLowerCase()));
        }else{
            console.log(props.location.state)
            setColName(props.location.state.replace(/ /g, '').toLowerCase());
            localStorage.setItem('colName', (props.location.state.replace(/ /g, '').toLowerCase()));
        }
    }

    function CallCollection(props) {
        return (
            <KnoWhat collection={props.tabName} lang={getLanguage}/>
        );
    }

    // const handleChange = (event) => {
    //     setLanguage(event.target.value);
    //     localStorage.setItem('language', event.target.value);
    // };

    return (
        <MuiThemeProvider>
            <div>
                {getColName === 'diseases' ?
                    (<img src={diseases} alt={getColName} className='topBanner'/>) :
                    (getColName === 'herbs' ?
                        (<img src={herbs} alt={getColName} className='topBanner'/>) :
                        (getColName === 'dietchart' ?
                            (<img src={diet} alt={getColName} className='topBanner'/>) :
                            (getColName === 'homeremedies' ?
                                (<img src={remedies} alt={getColName} className='topBanner'/>) :
                                <img src={nutrients} alt={getColName} className='topBanner'/>)))
                }
            </div>
            <Tabs
                classes={{root: classes.root, scroller: classes.scroller}}
                value={getColName} centered
                indicatorColor="primary"
                textColor="primary"
                variant={"scrollable"}
                scrollButtons={"on"}
            >
                {tabList.map((tabName, index) => (
                    <Tab
                        style={localStorage.getItem('colName') === (tabName.replace(/ /g, '').toLowerCase())? {
                            color: "Black",
                            fontWeight: "bold",
                            fontSize: "14px",
                            // textDecorationStyle: "double",
                            textDecorationLine: "underline",
                            textDecorationColor: "orange",
                            textDecorationThickness: "3px",
                            textShadow: "3px 3px 6px #f3fa2f",
                        } : {fontWeight: "bold",color: "#75b000"}}
                         label={tabName} onClick={() => [fetchData(tabName)]}/>
                ))}
            </Tabs>
            {/*{console.log('gggggggg',getColName)}*/}
            <CallCollection tabName={getColName} lang={getLanguage}/>
        </MuiThemeProvider>
    );
}