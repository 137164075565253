import React from 'react';
import "../App.css";
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";
import draftToHtml from 'draftjs-to-html';
import Scroll from './scrollTop'
// import DBPath from "./admin/dbPath";
import {toTitleCase} from "./hooks/inputHooK";

// let dbPath = DBPath();
// import {makeStyles} from "@material-ui/core/styles";
// import { convertToRaw, convertFromRaw, Draft} from "draft-js";
// import TextareaAutosize from '@mui/material/TextareaAutosize';
// import {Editor, EditorState} from 'draft-js'
// import { stateToHTML } from "draft-js-export-html";
// import Tabs from "@mui/material/Tabs";
// import Box from "@mui/material/Box";
// import Button from "@material-ui/core/Button";
// import welcome from "./welcome";
// import { stateToHTML } from "draft-js-export-html"
const useStyles = makeStyles((theme) => ({
    pTag: {
        fontSize: '18px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            textTransform: 'capitalize',
        },
    },
}));


export default function Information(props) {
    const classes = useStyles();
    // const [getDetail, setDetail] = useState(props.obj);
    let category = (props.col.replace(/[ -]/g, '').toLowerCase());
    let docName='';
    let detail = {};
    // console.log('ooooooo',props.obj)
    // console.log('ccccccc',props.obj.name)
    if(props.col && props.obj.name) {
        docName = props.obj.name;
        detail = draftToHtml(props.obj.details);
    }

    return (
        <Container maxWidth="md" style={{marginTop: '30px'}}>
            <p className={classes.pTag}> {props.col.replace(/ /g, '').toUpperCase()} - {docName}</p>
            <hr/>
            {(category === 'dietchart') || (category === 'nutrientsaz')|| (category === 'diseases')
                ? <div className='welcomeBody DietImg' dangerouslySetInnerHTML={{__html: `${detail}`}}/>
                : <div className='welcomeBody BodyImg' dangerouslySetInnerHTML={{__html: `${detail}`}}/>
            }
            <Scroll showBelow={1000}/>
        </Container>
    );
}