import React, {useEffect, useState} from 'react';
import "../App.css";
import List from '@mui/material/List';
import {makeStyles} from "@material-ui/core/styles";
import {Link, NavLink} from "react-router-dom";
import DBPath from "./admin/dbPath";
import GetSortOrder,{toTitleCase } from "./hooks/inputHooK";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
let dbPath = DBPath();
// import Button from '@material-ui/core/Button';
// import ListItem from '@material-ui/core/ListItem';
// import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles({
    root: {
        // border:"none",
        // backgroundColor:"none",
        // fontFamily: 'Cormorant Upright',
        // fontSize: '18px',
        // color: '#000',
    },

    sideLink: {
        fontFamily: 'Old Standard TT',
        // paddingLeft:"15px",
        fontSize:"12px",
        minWidth:"330px",
        maxWidth:"330px",
        justifyContent:"flex-start",
        '&:hover': {
            // opacity: 0.5,
            color:'#446600',
            fontWeight:'bold',
            backgroundColor:"white",
        },
    },
});


export default function DietChartList() {
    const classes = useStyles();

    //-------------- Fetch List -------------
    const [getList, setList] = React.useState('');
    const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    let collection = 'dietchart';
    collection = collection.concat(getLanguage);
    useEffect(() => {
        async function fetchList(collection) {
            try{
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                const json = await response.json();
                let menu_sorted = json.sort(GetSortOrder("name"));
                setList(menu_sorted);
            }catch (e) {
                console.error(e);
            }
        }
        fetchList(collection);
    }, [collection]);
    // ------------------x------------------
    return (
        <div>
            <p className="heading" style={{
                margin: "7% 10% 5% 5%",
                padding: "2% 0% 2% 5%",
                backgroundColor: "#8cd674",
            }}>Diet Chart</p>
            <List className='sideList' style={{marginLeft:'5%',marginTop: '-5%',paddingRight:'5px'}}>
                {
                    getList?getList.map(
                        (dietName) =><ul>
                            <li  style={{padding: '3px 0px 3px 0px', margin: '6px 0px 6px 0px', backgroundColor: "#fff",}}><Link
                                className='sideLink' to={{pathname: "/knowhat"}}
                                onClick={() => {
                                    localStorage.setItem('colName', 'dietchart'); localStorage.setItem('dietDoc', dietName.name)
                                }}
                            >{toTitleCase(dietName.name)}</Link></li>
                        </ul>
                    ):<></>
                }
            </List>
        </div>
    );
}

export function DietChartDropList() {
    // const classes = useStyles();
    const [getList, setList] = useState('');
    const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    let collection = 'dietchart';
    collection = collection.concat(getLanguage);
    useEffect(() => {
        async function fetchList(collection) {
            try{
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                const json = await response.json();
                let menu_sorted = json.sort(GetSortOrder("name"));
                setList(menu_sorted);
            }catch (e) {
                console.error(e);
            }
        }

        fetchList(collection);
    }, [collection]);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <FormControl sx={{width: 1}}>
                        <Autocomplete
                            options={getList}
                            getOptionLabel={(option) => toTitleCase(option.name)}
                            onChange={(event, value) => {
                                localStorage.setItem('colName', 'dietchart');
                                localStorage.setItem('dietDoc', value.name)
                                window.location.assign("/knowhat");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Diet Chart"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    );
}