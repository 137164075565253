import React, {useEffect, useState} from 'react';
import "../App.css";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import aboutBanner from "../bannerImage/Page_top_banner/about_us_web.jpg";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Add, Edit} from "@mui/icons-material";
import {fetchPage} from './hooks/inputHooK'
import DraftEdit from "./admin/draftEdit";
import draftToHtml from "draftjs-to-html";
import DBPath from "./admin/dbPath";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Editor} from "react-draft-wysiwyg";
import {convertToRaw} from "draft-js";
import {makeStyles} from "@material-ui/core/styles";


let dbPath = DBPath();
// import DiseasesList from "./diseasesList";
// import HerbsList from "./herbsList";
// import DietChartList from "./dietChartList";
// import {makeStyles} from "@material-ui/core/styles";
// const ytLinks = [
//     "PcfjYtAMHKY",
//     "zdWHD4VDyIU",
//     "5Z9Eap2Bm7k",
//     "wvKz4Z6mV8o",
//     "VMZ8AMOFvU4",
//     "6ytkm9xICO0",
// ]
const useStyles = makeStyles((theme) => ({
    title: {
        // marginTop: "30px",
        fontFamily: "Copperplate, Fantasy",
        fontSize: "35px",
        color: "#fff",
        // textShadow: "2px 2px 5px #f3fa2f",
        textAlign: "center",
        backgroundColor: '#8cd674',
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        },
    },
}));
export default function WhyDhanvantary(props) {
    const classes = useStyles();
    const [getUtLink, setUtLink] = useState('');
    const [getNewLink, setNewLink] = useState('');

    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'whyDhanvantary';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (
            async function () {
                try {
                    const response = await fetchPage(pageName);
                    const json = await response.json();
                    // setPage(json.data.children.map(it => it.data));
                    setPageDb(json);
                } catch (e) {
                    console.error(e);
                }
            })();
        fetchLink();
    }, []);

    //-----------------x----------------------
    async function fetchLink() {
        try{
            let url = `${dbPath}/slider?q=[{"field":"pageName","opt":"==","value":"whyDhanvantary"}]`;
            let response = await fetch(url);
            response = await response.json();
            if (response.length > 0) {
                setUtLink(response[0].utLink);
            }
        }catch (e) {
            console.error(e);
        }
        // const contentState = draftToHtml(response[0].details);
        // // setNewValue(response[0].banner);
        // setDetail(contentState);
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const addNew = async () => {
        let x=getNewLink.substr(getNewLink.length-11);
        getUtLink.splice(0,0,x);
        getUtLink.pop();
        let bJSON = {
            utLink: getUtLink,
        }
        // console.log(getUtLink)
        try {
        let url = `${dbPath}/slider?q=[{"field":"pageName","opt":"==","value":"whyDhanvantary"}]`;
        const response = await fetch(`${url}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bJSON)
        });
        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }
        handleClose();
    }

    const dialogBody = (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" >
            <DialogTitle>Add New</DialogTitle>
            <DialogContent>
                <DialogContentText style={{width: '100%', marginTop: '-5px'}}>
                    YouTube link
                </DialogContentText>
                {/*<TextField*/}
                {/*    autoFocus*/}
                {/*    margin="dense"*/}
                {/*    id="name"*/}
                {/*    label="Email Address"*/}
                {/*    type="email"*/}
                {/*    fullWidth*/}
                {/*    variant="standard"*/}
                {/*/>*/}
                <TextField autoFocus margin="dense" variant="standard" label="Paste Link" onChange={(e) => setNewLink(e.target.value)}
                           style={{width: '100%', marginTop: '0px'}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={addNew}>Save</Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <>
            <div>
                {/*<Grid item xs={12}>*/}
                <img src={aboutBanner} alt={'image'} className={'topBanner'}/>
                {/*</Grid>*/}
            </div>

            <div>
                <Container maxWidth="xl" style={{marginTop: '30px'}}>
                    <Grid container spacing={0}>

                        <Grid item xs={12} md={6} lg={4} style={{padding: '35px'}}>

                            {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                <Tooltip title="Add New" aria-label="Add">
                                    <Fab size="small" color="primary"
                                         style={{float: 'right', backgroundColor: '#caa136', color: '#fff'}}
                                         onClick={handleClickOpen}
                                        // onClick={() => this.handleImageUploadTop(["banners","papaapp"])}
                                        //  onClick={() => manageImage(getDbImg, ['main_slider', 'dbsweb'])}
                                    >
                                        <Add/>
                                    </Fab>
                                </Tooltip>
                                : <></>}
                            {dialogBody}
                            {/*{ytLinks.map((data) => (*/}
                            {getUtLink ? getUtLink.map((data) => (
                                    <iframe
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: '10px',
                                            borderColor: '#8cd674',
                                            marginBottom: '20px',
                                            width: '90%'
                                        }}
                                        src={`https://www.youtube.com/embed/${data}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        // title="Embedded youtube"
                                    />
                                )
                            ) : <></>}
                        </Grid>
                        <Grid item xs={12} md={12} lg={7} style={{marginTop: '27px'}}>
                            {/*<h2 align={'center'}>Who We Are</h2>*/}
                            <div className={classes.title}>
                                {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                 onClick={() => {
                                                     handelEditor(getPageDb);
                                                     setBlock(getPageDb[0].block)
                                                 }}
                                            >
                                                <Edit/>
                                            </Fab>
                                        </Tooltip>
                                    </Box>
                                    : <></>}
                            </div>
                            <div style={{columnCount: 1}} className='welcomeBody'>
                                {/*<img src={img2} alt={'image'} width='400' height='250'/>*/}
                                <div
                                    dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></div>
                            </div>
                            <br/>
                            <div className={classes.title}>
                                {getPageDb[1] ? getPageDb[1][`head${getLanguage}`] : ''}
                                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                 onClick={() => {
                                                     handelEditor(getPageDb);
                                                     setBlock(getPageDb[1].block)
                                                 }}
                                            >
                                                <Edit/>
                                            </Fab>
                                        </Tooltip>
                                    </Box>
                                    : <></>}
                            </div>
                            <div style={{columnCount: 1}} className='welcomeBody'>
                                {/*<img src={img2} alt={'image'} width='400' height='250'/>*/}
                                <div className='welcomeBody'
                                     dangerouslySetInnerHTML={{__html: `${getPageDb[1] ? draftToHtml(getPageDb[1][`body${getLanguage}`]) : ''}`}}></div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
        </>
    );

}