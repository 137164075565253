import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import './Navbar.css';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import Product from '@material-ui/icons/ViewQuilt';
import Gallery from '@material-ui/icons/Apps';
import Library from '@material-ui/icons/LocalLibrary';
import Media from '@material-ui/icons/Theaters';
import Typography from "@material-ui/core/Typography";
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import UploadIcon from '@mui/icons-material/Upload';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
// import Download from '@material-ui/icons/GetApp';
// import Doctor from '@material-ui/icons/LocalHospital';
// import Container from "@material-ui/core/Container";
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';
// import Grid from '@material-ui/core/Grid';

///////////////////////////
const StyledMenu = withStyles({
    subMenu: {
        fontSize: 12,
        fontFamily: "Montserrat",
        color: "blue",
    }
})((props) => (
    <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            // backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


///////////////////////////


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(0),
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
}));

const Txt = withStyles({
    root: {
        fontSize: 12,
        fontFamily: "Montserrat",
        color: "white",
    }
})(Typography);


export default function TextButtons() {
    const classes = useStyles();
    ////////////////////
    const [companyMenu, setAnchorEl] = useState(null);
    const [adminMenu, setAdminMenu] = useState(null);
    const [libraryMenu, setLibraryMenu] = useState(null);
    const [aboutMenu, setAboutMenu] = useState(null);
    const [productMenu, setProductMenu] = useState(null);
    const [mediaMenu, setMediaMenu] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAdmin = (event) => {
        setAdminMenu(event.currentTarget);
    };
    const closeAdmin = () => {
        setAdminMenu(null);
    };

    const openLibrary = (event) => {
        setLibraryMenu(event.currentTarget);
    };
    const closeLibrary = () => {
        setLibraryMenu(null);
    };

    const openAbout = (event) => {
        setAboutMenu(event.currentTarget);
    };
    const closeAbout = () => {
        setAboutMenu(null);
    };

    const openProduct = (event) => {
        setProductMenu(event.currentTarget);
    };
    const closeProduct = () => {
        setProductMenu(null);
    };

    const openMedia = (event) => {
        setMediaMenu(event.currentTarget);
    };
    const closeMedia = () => {
        setMediaMenu(null);
    };
    ////////////////////

    return (
        <div className={classes.root}>
            {/*<Container maxWidth="xl" style={{textAlign: 'center'}}>*/}
            <Button startIcon={<HomeIcon/>} style={{color: '#caa136'}}><Txt variant="body2"><NavLink
                className='nav-links' to="/">Home</NavLink></Txt></Button>

            <Button startIcon={<BusinessIcon/>} style={{color: '#caa136'}} aria-controls="customized-menu"
                    aria-haspopup="true" onClick={handleClick}> <Txt variant="body2">Company</Txt></Button>
            <StyledMenu id="customized-menu" anchorEl={companyMenu} keepMounted open={Boolean(companyMenu)}
                        onClose={handleClose}>
                <StyledMenuItem>
                    <InfoIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={handleClose}
                                                               to="/about">About Us</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <PersonIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={handleClose}
                                                               to="/Chairman">CMD Desk</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <AccountCircleIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={handleClose}
                                                               to="/md">Director</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <ManageAccountsIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={handleClose}
                                                               to="/mgmt">Management</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <CardMembershipIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={handleClose}
                                                               to="/crtf">Certificate</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <BusinessIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={handleClose}
                                                               to="/goc">Companies In Group</NavLink></txt>
                </StyledMenuItem>
            </StyledMenu>
            {/**/}
            <Button startIcon={<Product/>} style={{color: '#caa136'}} aria-controls="customized-menu"
                    aria-haspopup="true" onClick={openProduct}><Txt variant="body2">Products</Txt></Button>
            <StyledMenu anchorEl={productMenu} keepMounted open={Boolean(productMenu)} onClose={closeProduct}>
                <StyledMenuItem>
                    <ArtTrackIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeProduct}
                                                               to="/products">DBS Products</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <MenuBookIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeProduct}
                                                               to="/ayushKwath">Ayush Kwath</NavLink></txt>
                </StyledMenuItem>
            </StyledMenu>

            {/*<Button startIcon={<Gallery/>} style={{color: '#caa136'}}><Txt variant="body2"><NavLink*/}
            {/*    className='nav-links' to="/nopage">Gallery</NavLink></Txt></Button>*/}

            <Button startIcon={<Library/>} style={{color: '#caa136'}} onClick={openLibrary}> <Txt
                variant="body2">Library</Txt></Button>
            <StyledMenu anchorEl={libraryMenu} keepMounted open={Boolean(libraryMenu)} onClose={closeLibrary}>
                <StyledMenuItem>
                    <MenuBookIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeLibrary}
                                                               to="/knowhat">Know About</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <FilterVintageIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeLibrary}
                                                               to="/ayurveda">Why Ayurveda</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <FoodBankIcon fontSize="medium" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeLibrary}
                                                               to="/recipe">Healthy Recipes</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <SelfImprovementIcon fontSize="medium" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeLibrary}
                                                               to="/yoga">Benefits of Yoga</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <LiveHelpIcon fontSize="medium" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeLibrary}
                                                               to="/faq">FAQ's</NavLink></txt>
                </StyledMenuItem>
            </StyledMenu>

            <Button startIcon={<Media/>} style={{color: '#caa136'}} onClick={openMedia}> <Txt
                variant="body2">Media</Txt></Button>
            <StyledMenu anchorEl={mediaMenu} keepMounted open={Boolean(mediaMenu)} onClose={closeMedia}>
                <StyledMenuItem>
                    <AppShortcutIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeMedia}
                                                               to="/apnaApp">Apna App</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <TouchAppIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeMedia}
                                                               to="/whydbs">Why Dhanvantary</NavLink></txt>
                </StyledMenuItem>
            </StyledMenu>

            {/*<Button startIcon={<Download/>}><Txt variant="body2">Download</Txt></Button>*/}
            {/*<Button startIcon={<Doctor/>} style={{color:'#caa136'}}><Txt variant="body2"><NavLink className='nav-links' to="/nopage">Doctor</NavLink></Txt></Button>*/}

            {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ? (
                <Button startIcon={<SettingsIcon/>} style={{color: '#caa136'}} aria-controls="admin-menu"
                        aria-haspopup="true"
                        onClick={openAdmin}> <Txt variant="body2">Admin</Txt></Button>) : <></>}
            <StyledMenu anchorEl={adminMenu} keepMounted open={Boolean(adminMenu)} onClose={closeAdmin}>
                <StyledMenuItem>
                    <EditIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeAdmin}
                                                               to="/editkw">Info. Edit</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <EditIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeAdmin}
                                                               to="/sddtc">More Edit</NavLink></txt>
                </StyledMenuItem>
                <StyledMenuItem>
                    <LiveHelpIcon fontSize="small" style={{color: '#caa136'}}/>
                    <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeAdmin}
                                                               to="/addfaq">Add FAQ</NavLink></txt>
                </StyledMenuItem><StyledMenuItem>
                <UploadIcon fontSize="small" style={{color: '#caa136'}}/>
                <txt style={{marginLeft: '10px'}}><NavLink className='sub-links' onClick={closeAdmin}
                                                           to="/upImg">Upload Image</NavLink></txt>
            </StyledMenuItem>
            </StyledMenu>


            {/*<Button href="#text-buttons" color="primary"> Link </Button>*/}
            {/*//     </Container>*/}
        </div>
    );
}

export {StyledMenuItem};