import React, {useEffect, useState} from 'react';
import "../App.css";
import Information from "./detailBody";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import NativeSelect from '@mui/material/NativeSelect';
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';

import DBPath from "./admin/dbPath";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Stack from "@mui/material/Stack";
import GetSortOrder, {useInput} from "./hooks/inputHooK";

let dbPath = DBPath();
const theme = createTheme({
    typography: {
        body1: {
            fontSize: 12,
        },
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& label': {
                        fontSize: 14,
                    },
                },
                input: {
                    fontSize: 12,
                },
                listbox: {
                    fontSize: 12,
                },
            },
        },
    },
});
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    // slideLink: {
    //     fontFamily: 'Old Standard TT',
    //     fontSize: "12px",
    //     '&:hover': {
    //         // opacity: 0.5,
    //         color: '#446600',
    //         fontWeight: 'bold',
    //         backgroundColor: "white",
    //     },
    // },
}));

export default function KnoWhat(props) {
    let category = (props.collection.replace(/[ -]/g, '').toLowerCase());
    const classes = useStyles();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : localStorage.getItem('language'));
    let collection = category.concat(getLanguage);

    // collection = sessionStorage.getItem('language')?:;
    // const [getSubject, setSubject] = useState('');
    const [getKnwObj, setKnwObj] = useState('');
    const [getDocument, setDocument] = useState('');
    const [value, setValue] = useState('');
    const [newvalue, setNewValue] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // function findDoc(newDoc) {
    //     console.log('C')
    //     if (category === 'diseases' && (localStorage.getItem('diseaseDoc'))) {
    //         setDocument(localStorage.getItem('diseaseDoc'));
    //     } else if (category === 'herbs' && (localStorage.getItem('herbDoc'))) {
    //         setDocument(localStorage.getItem('herbDoc'));
    //     } else if (category === 'dietchart' && (localStorage.getItem('dietDoc'))) {
    //         setDocument(localStorage.getItem('dietDoc'));
    //     } else if (category === 'homeremedies' && (localStorage.getItem('remidiDoc'))) {
    //         setDocument(localStorage.getItem('remidiDoc'));
    //     } else if (category === 'nutrientsaz' && (localStorage.getItem('nutriDoc'))) {
    //         setDocument(localStorage.getItem('nutriDoc'));
    //     } else {
    //         setDocument(newDoc);
    //     }
    // }

    function setFirstDoc(response) {
        setKnwObj(response.filter(item => item.name == response[0].name)[0]);
        setDocument(response[0].name);
    }

    useEffect(() => {
        async function fetchMyAPI(collection) {
            try {
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                response = await response.json();
                response = response.sort(GetSortOrder("name"));
                // console.log('LLLLLLLLLLL',category)
                // if (response.length > 0) {
                //     findDoc(response[0].name);
                //
                //     console.log(response.filter(item=>item.name==getDocument)[0]);
                // }


                if (category === 'diseases') {
                    if (localStorage.getItem('diseaseDoc') && (response.filter(item => item.name == localStorage.getItem('diseaseDoc'))[0])) {
                        setKnwObj(response.filter(item => item.name == localStorage.getItem('diseaseDoc'))[0]);
                        setDocument(localStorage.getItem('diseaseDoc'));
                    } else {
                        setFirstDoc(response);
                    }
                } else if (category === 'herbs') {
                    if (localStorage.getItem('herbDoc') && (response.filter(item => item.name == localStorage.getItem('herbDoc'))[0])) {
                        setKnwObj(response.filter(item => item.name == localStorage.getItem('herbDoc'))[0]);
                        setDocument(localStorage.getItem('herbDoc'));
                    } else {
                        setFirstDoc(response);
                    }
                } else if (category === 'dietchart') {
                    if (localStorage.getItem('dietDoc') && (response.filter(item => item.name == localStorage.getItem('dietDoc'))[0])) {
                        setKnwObj(response.filter(item => item.name == localStorage.getItem('dietDoc'))[0]);
                        setDocument(localStorage.getItem('dietDoc'));
                    } else {
                        setFirstDoc(response);
                    }
                } else if (category === 'homeremedies') {
                    if (localStorage.getItem('remidiDoc') && (response.filter(item => item.name == localStorage.getItem('remidiDoc'))[0])) {
                        setKnwObj(response.filter(item => item.name == localStorage.getItem('remidiDoc'))[0]);
                        setDocument(localStorage.getItem('remidiDoc'));
                    } else {
                        setFirstDoc(response);
                    }

                } else if (category === 'nutrientsaz') {
                    if (localStorage.getItem('nutriDoc') && (response.filter(item => item.name == localStorage.getItem('nutriDoc'))[0])) {
                        setKnwObj(response.filter(item => item.name == localStorage.getItem('nutriDoc'))[0]);
                        setDocument(localStorage.getItem('nutriDoc'));
                    } else {
                        setFirstDoc(response);
                    }
                } else {
                    setFirstDoc(response);
                }
                setNewValue(response);
            } catch (e) {
                console.error(e);
            }
        }

        fetchMyAPI(collection);
    }, [collection]);

    function getDetails(param) {
        // console.log(param)
        setDocument(param);
        if (category === 'diseases') {
            localStorage.setItem('diseaseDoc', param);
        } else if (category === 'herbs') {
            localStorage.setItem('herbDoc', param);
        } else if (category === 'dietchart') {
            localStorage.setItem('dietDoc', param);
        } else if (category === 'homeremedies') {
            localStorage.setItem('remidiDoc', param);
        } else if (category === 'nutrientsaz') {
            localStorage.setItem('nutriDoc', param);
        }
        setKnwObj(newvalue.filter(item => item.name == param)[0]);
    }

    function DetailInfo(props) {
        if (props) {
            return (
                <Information obj={props.obj} col={props.coll}/>
            )
        }
    }

    return (
        <div className={classes.root}>
            <Box sx={{maxWidth: '100%', bgcolor: 'background.paper'}}>
                <FormControl style={{width: '100px', marginLeft: '20px', float: 'left'}}>
                    {/*<InputLabel variant="standard" htmlFor="uncontrolled-native">*/}
                    {/*    Goto*/}
                    {/*</InputLabel>*/}
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            // id="combo-box-demo"
                            disableCloseOnSelect
                            options={newvalue}
                            getOptionLabel={(option) => option.name}
                            style={{width: 120}}
                            renderInput={(params) => <TextField {...params} label="Search"
                                                                onSelect={(e) => getDetails(e.target.value)}/>}
                        />
                    </ThemeProvider>
                </FormControl>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {newvalue ? newvalue.map((data) => {
                        return (
                            <ul>
                                <Button className='slideLink'
                                        onClick={() => getDetails(data.name)}
                                        style={getDocument === data.name ? {
                                            color: "Black",
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                            textDecorationLine: "underline",
                                            textDecorationColor: "orange",
                                            textDecorationThickness: "3px",
                                            textShadow: "3px 3px 6px #f3fa2f",
                                        } : {color: "#446600"}}

                                >{data.name}</Button>
                            </ul>
                        );
                    }) : <></>}
                </Tabs>
            </Box>

            {getDocument ? <DetailInfo obj={getKnwObj} coll={props.collection}/> : <></>}
            {/*{getDocument ? <DetailInfo docName={getDocument} coll={props.data} lang={props.language}/> : <></>}*/}
        </div>
    );
}