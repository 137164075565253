import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import map from "../bannerImage/HO-Map.jpg";
import footerImg from "../bannerImage/footer.jpg";
import Container from "@material-ui/core/Container";
// import {makeStyles} from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import Divider from "@material-ui/core/Divider";
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    foot: {
        fontSize: '18px',
        marginTop: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            marginTop: '0px',
        },
    },
}));

function FooterEx() {
    const classes = useStyles();
    return (
        <Container maxWidth="xl" style={{
            marginTop: '50px', maxWidth: '100%',
            backgroundImage: `url(${footerImg})`,
            backgroundRepeat: 'no-repeat',
            color: 'white',
            backgroundSize:'auto',
        }}>

            <Grid container spacing={3}>
                <Grid item lg={4} xs={12}>
                    <img src={map} alt={'map'} width='80%' height='auto' style={{paddingTop: "10px"}}/>
                </Grid>
                <Grid item lg={4} xs={4} className={classes.foot}>
                    <p><NavLink to="/about" style={{color: 'white'}}>About Us</NavLink></p>
                    <p>Privacy Policy</p>
                    <p>Return Policy</p>
                </Grid>
                <Grid item lg={4} xs={8} className={classes.foot}>
                    Our Corporate & Head Office :
                    <p>
                        Dhanvantary Tower,<br/>Rajarhat Newtown Kolkata - 700 157, <br/>West Bengal India
                        info@dhanvantary.com <br/> +033 2572 7171
                    </p>
                </Grid>
            </Grid>
            <Grid item lg={12} xs={12}>
                <div className='input-areas' style={{textAlign: 'center', paddingBottom: '20px'}}>
                    <Typography variant="caption">
                        www.dhanvantary.com © Copyright 2022
                    </Typography>
                </div>
            </Grid>
        </Container>
    );
}

// FooterEx.propTypes = {};
// FooterEx.defaultProps = {};

export default FooterEx;
