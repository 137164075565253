import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import {ListItemIcon} from "@material-ui/core";
import {Link} from "react-router-dom";
import DrawerData from './jsonMenu';
import Logo from "../bannerImage/dbs-logo.png";
// import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Toolbar from '@material-ui/core/Toolbar';
// import Box from "@material-ui/core/Box";
// import InfoIcon from "@mui/icons-material/Info";
// import sidebarBack from '../bannerImage/sidebarBack.jpg'

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        // '& > *': {
        //     margin: theme.spacing(0),
        // },
        [theme.breakpoints.up('sm')]: {
            display: 'none !important',
        },
    },

    drawer: {
        width: drawerWidth,
        position: "absolute",
        backgroundColor: '#213300',
    },

    toolbar: theme.mixins.toolbar,
    logo: {
        maxWidth: 40,
        padding: 5,
    },
    paper: {
        background: '#e0f79e',
        // backgroundImage: `url(${sidebarBack})`,
        // backgroundRepeat: 'no-repeat',
    },

    // icon: {
    //     color: "blue"
    // },
    // backGround:{
    //     backgroundColor:'#e0f79e',
    // },
}));

function Sidenav(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState("")
    const [subIndex, setSubIndex] = useState("")
    // const [noSub, findSub] = useState("zzz")
    if (props.menuOpen === false) {
        if (mobileOpen === false) {
            setMobileOpen(!props.menuOpen);
        }
    }
    if (props.menuOpen === true) {
        if (mobileOpen === true) {
            setMobileOpen(!props.menuOpen);
        }
    }

    const handleDrawerToggle = () => {
        props.appBarCallback(true);
    };

    const handleClose = (e) => {
        setSubIndex(e);
        props.appBarCallback(true);
    }

    const handleClick = index => {
        if (selectedIndex === index) {
            setSelectedIndex("")
        } else {
            setSelectedIndex(index);
        }
        if (index === 0) {
            props.appBarCallback(true);
        }
    }

    //state hold when page refresh
    React.useEffect(() => {
        const path = window.location.pathname;
        DrawerData.map((menu) => {
            menu.items.map((data) => {
                if (data.subitems != null) {
                    data.subitems.map((sub) => {
                        if (sub.path === path) {
                            setSelectedIndex(Number(data.id));
                        }
                        return <></>
                    });
                } else {
                    if (data.path === path) {
                        setSelectedIndex(Number(data.id));
                    }
                }
                return <></>
            })
            return <></>
        });
    }, []);


    const drawer = (
        <div>
            <div style={{backgroundColor: '#213300', height: '45px', padding: '5px'}}>
                <img src={Logo} alt="logo" className={classes.logo}/>
            </div>
            <div>
                {DrawerData.map((item, index) => {
                    return (
                        <div key={index}>
                            {
                                item.items.map((list) => {
                                    return (
                                        <div key={list.id}>

                                            {list.subitems != null ?
                                                (<>
                                                    <List
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                        key={list.id}
                                                    >
                                                        <ListItem
                                                            key={list.id}
                                                            onClick={() => {
                                                                handleClick(list.id)
                                                            }}
                                                        >
                                                            {list.name != 'Admin' ?
                                                                (<>
                                                                    <ListItemIcon className={classes.icon}>
                                                                        {list.icon}
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={
                                                                        <Typography
                                                                            style={list.id === selectedIndex ? {color: '#0366fc'} : {color: '#000000'}}>
                                                                            {list.name} </Typography>
                                                                    }/>
                                                                    {list.id === selectedIndex ? <ExpandLess/> :
                                                                        <ExpandMore/>}</>) :
                                                                (((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                                                    (<>
                                                                        <ListItemIcon className={classes.icon}>
                                                                            {list.icon}
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={
                                                                            <Typography
                                                                                style={list.id === selectedIndex ? {color: '#0366fc'} : {color: '#000000'}}>
                                                                                {list.name} </Typography>
                                                                        }/>
                                                                        {list.id === selectedIndex ? <ExpandLess/> :
                                                                            <ExpandMore/>}</>) :
                                                                    <></>)
                                                            }
                                                        </ListItem>
                                                        <Collapse in={list.id === selectedIndex} timeout="auto"
                                                                  unmountOnExit>
                                                            <List component="div" disablePadding>
                                                                {list.subitems.map((sub, index) => {
                                                                    return (
                                                                        <ListItem button component={Link}
                                                                                  to={sub.path} key={sub.id}
                                                                                  onClick={() => {
                                                                                      handleClose(sub.name)
                                                                                  }}
                                                                        >
                                                                            <ListItemIcon className={classes.icon}
                                                                                          style={{marginLeft: '25px'}}>
                                                                                {sub.icon}
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={
                                                                                <Typography
                                                                                    style={sub.name === subIndex ? {color: '#0366fc'} : {color: '#000000'}}>
                                                                                    {sub.name}</Typography>
                                                                            }/>
                                                                        </ListItem>
                                                                    )
                                                                })}
                                                            </List>
                                                        </Collapse>
                                                    </List>
                                                </>)
                                                :
                                                (
                                                    <>
                                                        <List
                                                            component="nav"
                                                            aria-labelledby="nested-list-subheader"
                                                            key={list.id}
                                                        >
                                                            <ListItem
                                                                // findSub={list.id}
                                                                key={list.id}
                                                                button
                                                                component={Link}
                                                                to={list.path}
                                                                onClick={() => {
                                                                    handleClick(0)
                                                                }}
                                                            >
                                                                <ListItemIcon className={classes.icon}>
                                                                    {list.icon}
                                                                </ListItemIcon>
                                                                <ListItemText primary={
                                                                    <Typography
                                                                        style={list.id === selectedIndex ? {color: '#0366fc'} : {color: '#000000'}}>{list.name}</Typography>
                                                                }/>
                                                            </ListItem>
                                                        </List>
                                                    </>
                                                )}
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div className={classes.drawer}>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        classes={{paper: classes.paper}}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                {/*<Hidden xsDown implementation="css">*/}
                {/*    <Drawer*/}
                {/*        variant="permanent"*/}
                {/*    >*/}
                {/*        {drawer}*/}
                {/*    </Drawer>*/}
                {/*</Hidden>*/}
            </nav>
        </div>
    );
}

export default Sidenav;