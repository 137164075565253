import React, {useState, useEffect} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Scroll from "./scrollTop";
import {generalEng, generalBen, generalHin} from "./jsonGeneralFaq";
import {makeStyles} from "@material-ui/core/styles";
import DBPath from "./admin/dbPath";
let dbPath = DBPath();

const useStyles = makeStyles((theme) => ({
    ques: {
        fontSize:'16px',
        fontWeight:'bold',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize:'12px',
        },
    },
    ans: {
        fontSize:'16px',
        color:'#474747',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize:'12px',
        },
    },
}));
export default function FaqQusAns(props) {
    const classes = useStyles();
    const [getFaqs, setFaqs] = useState(props.obj);

    return (
        <div style={{marginTop: '30px'}}>
            {getFaqs ? getFaqs.map((QAs) => (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {/*<Typography>Accordion 1</Typography>*/}
                            <Typography><b>{QAs.question}</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className={classes.ans}>{QAs.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                )) :
                <div>
                    {props.lang==='Eng'? generalEng.map((data)=>(
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {/*<Typography>Accordion 1</Typography>*/}
                                <span className={classes.ques}>{data.Q}</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={classes.ans}>{data.Ans}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    )):(props.lang==='Ben'? generalBen.map((data)=>(
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {/*<Typography>Accordion 1</Typography>*/}
                                <span className={classes.ques}>{data.Q}</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={classes.ans}>{data.Ans}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    )):props.lang==='Hin'? generalHin.map((data)=>(
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {/*<Typography>Accordion 1</Typography>*/}
                                <span className={classes.ques}>{data.Q}</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <span className={classes.ans}>{data.Ans}</span>
                            </AccordionDetails>
                        </Accordion>
                    )):<></> )}
                </div>
            }
            <Scroll showBelow={600}/>
        </div>
    );
}
