import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {useInput} from "../hooks/inputHooK";
import setFAQ from "../../bannerImage/setFAQ.jpg";
import Button from "@material-ui/core/Button";
import DBPath from "./dbPath";
import swal from "sweetalert2";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
// import {makeStyles} from "@material-ui/core/styles";
// import {convertToRaw} from "draft-js";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Paper from '@material-ui/core/Paper';
// import {convertFromRaw, EditorState} from "draft-js";


let dbPath = DBPath();

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         // textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
// }));

export default function AddFaq() {
    // const classes = useStyles();
    const [getLanguage, setLanguage] = useState('');
    const [getSubject, setSubject] = useState('');
    const [getEngName, setEngName] = useState('');
    const [getBenName, setBenName] = useState('');
    const [getHinName, setHinName] = useState('');
    const [getSubList, setSubList] = useState('');
    const [getNameEng, setNameEng] = useState('');
    const [getNameBen, setNameBen] = useState('');
    const [getNameHin, setNameHin] = useState('');
    let distinctSubject = '';
    const {value: Subject, bind: bindSubject, reset: resetSubject} = useInput('');
    const {value: EngName, bind: bindEngName, reset: resetEngName} = useInput('');
    const {value: EngQues, bind: bindEngQues, reset: resetEngQues} = useInput('');
    const {value: EngAns, bind: bindEngAns, reset: resetEngAns} = useInput('');
    const {value: BenName, bind: bindBenName, reset: resetBenName} = useInput('');
    const {value: BenQues, bind: bindBenQues, reset: resetBenQues} = useInput('');
    const {value: BenAns, bind: bindBenAns, reset: resetBenAns} = useInput('');
    const {value: HinName, bind: bindHinName, reset: resetHinName} = useInput('');
    const {value: HinQues, bind: bindHinQues, reset: resetHinQues} = useInput('');
    const {value: HinAns, bind: bindHinAns, reset: resetHinAns} = useInput('');

    let coll = 'faqEng';
    let collection = 'faq'.concat(getLanguage);
    useEffect(() => {
        const fetchFaq = async (c) => {
            try {
                let url = `${dbPath}/${c}`;
                let response = await fetch(url);
                response = await response.json();
                distinctSubject = [...new Set(response.map(data => data.subject))];
                setSubList(distinctSubject);
            } catch (e) {
                console.error(e);
            }
        }
        fetchFaq(coll);
    }, [dbPath, coll]);


// Query ref:
// db.faqEng.update({"subject":"Diseases","name":"Acidity"},{ $addToSet: {"details" : {"question":"question1","answer":"answer1"}}},{upsert: true,})

    const addNew = (evt) => {
        //------------    For Eng QA      -----------
        if (getEngName && EngQues && EngAns) {
            let jsonEng = {
                question: EngQues,
                answer: EngAns,
            }
            let bodyEng = Object.assign(jsonEng);
            try {
                let url = `${dbPath}/faqEng/faq?q=[{"field":"subject","opt":"==","value":"${getSubject}"},{"field":"name","opt":"==","value":"${getEngName}"}]&arr=[{"array":"details"}]`;
                console.log(url);
                // alert('x')
                const response = fetch(`${url}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyEng)
                });
                console.log(response);
                // alert('x')
                // setOpen(false);
                // swal.fire({ icon: 'success', title: 'Successfully Saved',timer: 2000});
            } catch (error) {
                // enter your logic for when there is an error (ex. error toast)
                console.log(error)
            }
        }
        //------------    For Ben QA      -----------
               if (getBenName && BenQues && BenAns) {
            let jsonBen = {
                question: BenQues,
                answer: BenAns,
            }
            let bodyBen = Object.assign(jsonBen);
            try {
                const response = fetch(`${dbPath}/faqBen/faq?q=[{"field":"subject","opt":"==","value":"${getSubject}"},{"field":"name","opt":"==","value":"${getBenName}"}]&arr=[{"array":"details"}]`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyBen)
                });
                // setOpen(false);
                // swal.fire({ icon: 'success', title: 'Successfully Saved',timer: 2000});
            } catch (error) {
                // enter your logic for when there is an error (ex. error toast)
                console.log(error)
            }
        }
        //------------    For Hin QA      -----------
        if (getHinName && HinQues && BenAns) {
            let jsonHin = {
                question: HinQues,
                answer: HinAns,
            }
            let bodyHin = Object.assign(jsonHin);
            try {
                const response = fetch(`${dbPath}/faqHin/faq?q=[{"field":"subject","opt":"==","value":"${getSubject}"},{"field":"name","opt":"==","value":"${getHinName}"}]&arr=[{"array":"details"}]`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyHin)
                });
                // setOpen(false);
                // swal.fire({ icon: 'success', title: 'Successfully Saved',timer: 2000});
            } catch (error) {
                // enter your logic for when there is an error (ex. error toast)
                console.log(error)
            }
        }

        swal.fire({icon: 'success', title: 'Successfully Saved', timer: 1000});
        evt.preventDefault();
    }

    // const handleLanguage = (event) => {
    //     setLanguage(event.target.value);
    //     sessionStorage.setItem('language', event.target.value);
    // }

    // const handelEngName=(event, value)=>{
    //     setEngName(value);
    // }
    // const handelBenName=(event, value)=>{
    //     setBenName(value);
    // }
    // const handelHinName=(event, value)=>{
    //     setHinName(value);
    // }

    const handelList = (event, value) => {
        setSubject(value);
        // let key = event.target.id.split("-")[0];
        const fetchName = async (collection, subject) => {
            try {
                let url = `${dbPath}/${collection}?q=[{"field":"subject","opt":"==","value":"${subject}"}]`;
                let response = await fetch(url);
                response = await response.json();
                distinctSubject = [...new Set(response.map(data => data.name))];
                if (collection == 'faqEng') {
                    setNameEng(distinctSubject);
                }
                if (collection == 'faqBen') {
                    setNameBen(distinctSubject);
                }
                if (collection == 'faqHin') {
                    setNameHin(distinctSubject);
                }
            } catch (e) {
                console.error(e);
            }
        }
        // setNameEng(fetchName('faqEng',value));
        // setNameBen(fetchName('faqBen',value));
        // setNameHin(fetchName('faqHin',value));
        fetchName('faqEng', value);
        fetchName('faqBen', value);
        fetchName('faqHin', value);
    }
// console.log('ssssssssssssss',getSubList)
    return (
        <>
            <div>
                <img src={setFAQ} alt={setFAQ} style={{width: '100%', marginTop: '30px'}}/>
            </div>
            <Container maxWidth="lg" style={{marginTop: '50px'}}>
                {/*<div className={classes.root}>*/}
                <form onSubmit={addNew}>
                    <Grid container spacing={0}>
                        <Grid item lg={4} xs={12}>
                            {/*<TextField name="Subject" {...bindSubject} label={`Subject`} fullWidth={true} style={{padding:'5px'}}/>*/}
                            <Stack>
                                <Autocomplete
                                    id="subject"
                                    freeSolo
                                    disableClearable
                                    onChange={handelList}
                                    options={getSubList.length > 0 ? getSubList.map((option) => option) : []}
                                    // renderInput={(bindSubject) => <TextField name="Subject" {...bindSubject} label="Subject"/>}
                                    renderInput={(bindSubject) => (
                                        <TextField {...bindSubject} label="Subject" onChange={(event) => {
                                            setSubject(event.target.value)
                                        }} InputProps={{...bindSubject.InputProps, type: 'search',}}/>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid item lg={8} xs={12}></Grid>
                        {/*-----------------------       ENGLISH          --------------------------*/}
                        <Grid item lg={12} xs={12} className={'heading'} style={{marginTop: '20px'}}>English</Grid>
                        <Grid item lg={4} xs={12}>
                            {/*<TextField name="Name" {...bindEngName} label={`Name`} fullWidth={true} style={{padding: '5px'}}/>*/}
                            <Stack>
                                <Autocomplete
                                    id="nameEng"
                                    freeSolo
                                    disableClearable
                                    // onChange={handelEngName}
                                    onChange={(event, value) => {
                                        setEngName(value)
                                    }}
                                    options={getNameEng.length > 0 ? getNameEng.map((option) => option) : []}
                                    // renderInput={(bindEngName) => <TextField {...bindEngName} label="Name" style={{padding: '10px'}}/>}
                                    renderInput={(bindEngName) => (
                                        <TextField {...bindEngName} label="Name" onChange={(event) => {
                                            setEngName(event.target.value)
                                        }} InputProps={{...bindEngName.InputProps, type: 'search',}}
                                                   style={{padding: '10px'}}/>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid lg={8} xs={12}>
                            <TextField name="Question" {...bindEngQues} label={`Question`} fullWidth={true}
                                       style={{padding: '5px'}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="Answer" {...bindEngAns} label={`Answer`} fullWidth={true}
                                       style={{padding: '5px'}}/>
                        </Grid>
                        {/*-----------------------       BENGALI          --------------------------*/}
                        <Grid item lg={12} xs={12} className={'heading'} style={{marginTop: '20px'}}>বাংলা</Grid>
                        <Grid item lg={4} xs={12}>
                            {/*<TextField name="Name" {...bindBenName} label={`নাম`} fullWidth={true} style={{padding: '5px'}}/>*/}
                            <Stack>
                                <Autocomplete
                                    id="nameBen"
                                    freeSolo
                                    disableClearable
                                    // onChange={handelBenName}
                                    onChange={(event, value) => {
                                        setBenName(value)
                                    }}
                                    options={getNameBen.length > 0 ? getNameBen.map((option) => option) : []}
                                    // renderInput={(bindBenName) => <TextField {...bindBenName} label="নাম" style={{padding: '10px'}}/>}
                                    renderInput={(bindBenName) => (
                                        <TextField {...bindBenName} label="নাম" onChange={(event) => {
                                            setBenName(event.target.value)
                                        }} InputProps={{...bindBenName.InputProps, type: 'search',}}
                                                   style={{padding: '10px'}}/>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid lg={8} xs={12}>
                            <TextField name="Question" {...bindBenQues} label={`প্রশ্ন`} fullWidth={true}
                                       style={{padding: '5px'}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="Answer" {...bindBenAns} label={`উত্তর`} fullWidth={true}
                                       style={{padding: '5px'}}/>
                        </Grid>
                        {/*-----------------------       HINDI          --------------------------*/}
                        <Grid item lg={12} xs={12} className={'heading'} style={{marginTop: '20px'}}>हिन्दी</Grid>
                        <Grid item lg={4} xs={12}>
                            {/*<TextField name="Name" {...bindHinName} label={`नाम`} fullWidth={true} style={{padding: '5px'}}/>*/}
                            <Stack>
                                <Autocomplete
                                    id="nameHin"
                                    freeSolo
                                    disableClearable
                                    // onChange={handelHinName}
                                    onChange={(event, value) => {
                                        setHinName(value)
                                    }}
                                    options={getNameHin.length > 0 ? getNameHin.map((option) => option) : []}
                                    // renderInput={(bindHinName) => <TextField {...bindHinName} label="नाम" style={{padding: '10px'}}/>}
                                    renderInput={(bindHinName) => (
                                        <TextField {...bindHinName} label="নাম" onChange={(event) => {
                                            setHinName(event.target.value)
                                        }} InputProps={{...bindHinName.InputProps, type: 'search',}}
                                                   style={{padding: '10px'}}/>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid lg={8} xs={12}>
                            <TextField name="Question" {...bindHinQues} label={`प्रश्न`} fullWidth={true}
                                       style={{padding: '5px'}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="Answer" {...bindHinAns} label={`उत्तर`} fullWidth={true}
                                       style={{padding: '5px'}}/>
                        </Grid>


                        <Grid item lg={12} xs={12}>
                            <Button size="medium"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={{marginLeft: '35px', borderRadius: 8}}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );

}