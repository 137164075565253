import React, {useState} from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import MenuAll from "./components/menuAll";
import MainSlider from "./components/MainSlider";
import MenuBar2 from "./components/menuBar2";
import WelcomeNote from "./components/welcome";
// import Products from "./components/Products";

import imageMenu from "./components/imageMenu";
import AboutUs from "./components/aboutus";
import TopTab from "./components/TopTab";
import EditKW from "./components/admin/EditKW";
import Chairman from "./components/chairman";
import MD from "./components/md";
import Mgmt from "./components/mangementTeam";
import Certificate from "./components/certificate";
import FooterEx from "./components/footer";
import WhyAyurveda from "./components/whyAyurveda";
import HealthRecipe from "./components/healthyRecipe";
// import More from "./components/more";
import Yoga from "./components/yoga";
import NoPage from "./components/noPage";

import SDDTC from "./components/admin/SaveDraftDataToCollection";
import GroupOfComp from "./components/groupOfComp";
import AyushKwath from "./components/ayushKwath";
import ApnaApp from "./components/apnaApp";
import FAQ from "./components/faq";
import AddFaq from "./components/admin/addFaq";
import Prakriti from "./components/prakriti";
import UploadImage from "./components/admin/uploadImage";
import Sidebar from "./components/Sidebar";
import WhyDhanvantary from "./components/whyDhanvantary";
import Smile from "./components/smile";
import Pathshala from "./components/pathshala";
import ShowCase from "./components/showCase";
// import ProductCard from "./components/Products";
// import LoadApp from "./components/flipbook/flipbook";
// import Slid3 from "./components/carousel3";
// import Demo from "./components/carousel2";
// import ImgGrid from "./components/imgGrid";
// import EditMore from "./components/admin/EditMore";
// import Test from "./components/goc";

function App() {
    // const [getLanguage, setLanguage] = useState('');
    const [getLanguage, setLanguage] = useState(localStorage.getItem('language')?localStorage.getItem('language'):'Eng');
    const [getAdmin, setAdmin] = useState('');
    // const [getUrl, setUrl] = useState('');
    const currentURL = window.location.pathname;

    function callbackStates(lang) {
        if (typeof lang != 'boolean') {
            if (lang === 'xx') {
                setAdmin(lang);
            } else {
                setLanguage(lang);
            }
        } else {
            setLanguage('Eng');
        }
    }

    if (currentURL != '/more') {
        localStorage.setItem('moreName', '');
        // setKnMoID('');
    }

    window.addEventListener("beforeunload", (ev) => {
        localStorage.setItem('language', '');
        // ev.preventDefault();
        // return ev.returnValue = 'Are you sure you want to close?';
    });
    return (
        <Router>
            <>
                <Route path="/" component={() => <MenuAll statesCallBack={callbackStates}/>}/>
                <Route path="/" component={Sidebar}/>
                <Route exact path="/" component={MainSlider}/>
                <Route exact path="/" component={MenuBar2}/>
                <switch>
                    <Route exact path="/" component={() => <WelcomeNote lang={getLanguage} okAdmin={getAdmin}/>}/>
                    <Route exact path="/" component={imageMenu}/>
                    {/*<Route exact path="/products" component={Products}/>*/}

                    <Route exact path="/about" component={() => <AboutUs lang={getLanguage}/>}/>
                    <Route path="/chairman" component={() => <Chairman lang={getLanguage}/>}/>
                    <Route path="/md" component={() => <MD lang={getLanguage}/>}/>
                    <Route path="/mgmt" component={() => <Mgmt lang={getLanguage}/>}/>
                    <Route exact path="/knowhat" component={() => <TopTab lang={getLanguage}/>}/>
                    <Route path="/apnaApp" component={ApnaApp}/>
                    <Route exact path="/crtf" component={() => <Certificate lang={getLanguage}/>}/>
                    <Route exact path="/ayurveda" component={() => <WhyAyurveda lang={getLanguage}
                                                                                url={localStorage.setItem('moreName', '')}/>}/>
                    <Route exact path="/recipe" component={() => <HealthRecipe lang={getLanguage}/>}/>
                    {/*<Route exact path="/more" component={() => <More lang={getLanguage}/>}/>*/}
                    <Route path="/prakriti" component={() => <Prakriti lang={getLanguage}/>}/>
                    <Route path="/goc" component={GroupOfComp}/>
                    <Route path="/faq" component={() => <FAQ lang={getLanguage}/>}/>
                    <Route path="/ayushKwath" component={() => <AyushKwath lang={getLanguage}/>}/>
                    <Route exact path="/whydbs" component={() => <WhyDhanvantary lang={getLanguage}/>}/>
                    <Route exact path="/yoga" component={() => <Yoga lang={getLanguage}/>}/>
                    <Route path="/nopage" component={NoPage}/>
                    <Route path="/smile" component={() => <Smile lang={getLanguage}/>}/>
                    <Route path="/pathshala" component={() => <Pathshala lang={getLanguage}/>}/>
                    <Route path="/products" component={ShowCase}/>
                    {/*<Route path="/product" component={ProductCard}/>*/}
                    {/*<Route path="/editmore" component={()=><EditMore lang={getLanguage}/>}/>*/}
                    {/*<Route path="/pcl" component={()=><ProdCatList lang={getLanguage}/>}/>*/}
                    {/*---------------   Admin Pages -------------------*/}
                    {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                        <>
                            <Route path="/sddtc" component={() => <SDDTC lang={getLanguage}/>}/>
                            <Route exact path="/editkw" component={() => <EditKW lang={getLanguage}/>}/>
                            <Route path="/addfaq" component={() => <AddFaq lang={getLanguage}/>}/>
                            <Route path="/upImg" component={UploadImage}/>
                        </>
                        : <></>}
                    {/*------ extra page------------*/}

                    {/*<Route path="/flip" component={LoadApp}/>*/}
                    {/*<Route exact path="/test" component={Test}/>*/}
                    {/*<Route exact path="/" component={Demo}/>*/}
                    {/*<Route exact path="/list" component={List}/>*/}
                    {/*<Route exact path="/slid" component={Slid3}/>*/}
                    {/*/!*<Route exact path="/" component={ImgGrid}/>*!/*/}
                    {/*/!*<Route path="/edit" component={Editinfo}/>*!/*/}
                </switch>
                <Route path="/" component={FooterEx}/>
            </>
        </Router>
    );
}

export default App;