import React,{useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import LoginIcon from '@mui/icons-material/Login';
import {StyledMenuItem} from '../menuBar';
import swal from "sweetalert2";
export default function AdminLogin() {
    const [open, setOpen] = useState(false);
    // const [clicked, setClicked] = useState(false);
    const [getUser, setUser] = useState('');
    const [getPass, setPass] = useState('');

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    // function handleClick() {
    //     setClicked(true);
    // }

    function login() {
        if(getUser==='admin' && getPass==='admin@dbs'){
            sessionStorage.setItem('okAdmin', 'okAdmin');
        }else{
            setOpen(false);
            swal.fire({ icon: 'error', title: 'Incorrect User/Password!!',timer: 2000});
        }
    }
    const handelGetUser=event=>{
        let field = event.target.name;
        if (field === 'user') {
            setUser(event.target.value);
        }
    }
    const handelGetPass=event=>{
        let field = event.target.name;
        if (field === 'pass') {
            setPass(event.target.value);
        }
    }

    return (
        <div>
            {/*<Button variant="outlined" color="primary" onClick={handleClickOpen}>*/}
            {/*    Login*/}
            {/*</Button>*/}
            <StyledMenuItem>
            <LoginIcon fontSize="small" style={{color:'#caa136'}}/>
            <MenuItem onClick={handleClickOpen}>Login</MenuItem></StyledMenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <form onSubmit={login}>
                    <h3 className='heading' style={{textAlign: 'center', color: '#446600'}}>Admin Login</h3>
                    <DialogContent>
                        <Grid>
                            <TextField type="text" id="user" name="user" fullWidth label="User ID" onChange={handelGetUser} value={getUser}/>
                        </Grid>
                        <Grid>
                            <TextField type="password" id="pass" name="pass" fullWidth label="Password" onChange={handelGetPass} value={getPass}/>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {/*{!clicked ? (*/}
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            {/*<Grid>*/}
                            <Button color="primary" type="submit" style={{paddingLeft:'85px'}}>
                                LOGIN
                            </Button>
                            {/*</Grid>*/}
                        </Grid>
                        {/*) : null}*/}
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
