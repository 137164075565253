import React, {useEffect, useState} from 'react';
import "../App.css";
import Container from "@material-ui/core/Container";
import HealthyRecipe from "../bannerImage/Page_top_banner/healthy_recipes.jpg";
import Grid from "@material-ui/core/Grid";
import RecipeList, {RecipeDropList} from "./recipeList";
import {makeStyles} from "@material-ui/core/styles";
import draftToHtml from "draftjs-to-html";
import DiseasesList, {DiseasesDropList} from "./diseasesList";
import HerbsList, {HerbsDropList} from "./herbsList";
import DietChartList, {DietChartDropList} from "./dietChartList";
// import List from "@mui/material/List";
// import Information from "./detailBody";
// import EditMore from "./admin/EditMore";
// import Button from "@material-ui/core/Button";
// import Tabs from "@mui/material/Tabs";
import DBPath from "./admin/dbPath";
import Scroll from "./scrollTop";
import kyb from "../bannerImage/kyb.jpg";
import MoreAyur, {MoreDropAyur} from "./moreAyur";
import useWindowDimensions from "./hooks/utilityHooK";

let dbPath = DBPath();
const useStyles = makeStyles((theme) => ({
    scrol: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "1500px",
        marginTop: "-40px",
        // paddingRight:'100px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        // '&::-webkit-scrollbar-thumb': {
        //     backgroundColor: 'rgba(0,0,0,.1)',
        //     outline: '1px solid slategrey'
        // }
        [theme.breakpoints.down('sm')]: {
            marginTop: "-20px",
            height: "auto",
        },
    },
    title: {
        marginTop: "30px",
        fontFamily: "Copperplate, Fantasy",
        fontSize: "35px",
        color: "#fff",
        // textShadow: "2px 2px 5px #f3fa2f",
        textAlign: "center",
        backgroundColor: '#8cd674',
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        },
    },
}));

export default function HealthRecipe(props) {
    const classes = useStyles();
    // let recipeName = '';
    // let collection = '';
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getRecipeName, setRecipeName] = useState('');
    const [getDetail, setDetail] = useState('');
    const {height, width} = useWindowDimensions();

    function callRecipe(name, detail) {
        // console.log('HHHHHHHHHHH',detail)
        if(name) {
            setRecipeName(name);
        }
        // setDetail()
        if(detail) {
            setDetail(draftToHtml(JSON.parse(detail)));
        }
    }

    return (
        <>
            <div>
                <img src={HealthyRecipe} alt={'image'} className={'topBanner'}/>
            </div>
            <Container maxWidth="xl" >
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12} style={{marginTop: '30px'}}>
                        {width > 800 ?
                            <>
                                <RecipeList recipeListObj={callRecipe} lang={getLanguage}/>
                                {/*callRecipe={callBack}*/}
                                {/*<div style={{marginTop: '20px'}}>*/}
                                {/*    <a href={'/prakriti'}><img src={kyb} alt={'kyb'}*/}
                                {/*                               style={{marginLeft: '25px', width: '85%'}}/></a>*/}
                                {/*</div>*/}
                                <DiseasesList/>
                                <HerbsList/>
                                <DietChartList/>
                            </>:
                            <>
                                <RecipeDropList recipeDropObj={callRecipe} lang={getLanguage}/>
                                <fieldset style={{backgroundColor: '#e2fcb8', marginTop:'20px',borderRadius: '4px', width: '98%'}}>
                                    <legend>Other Usefull Links:</legend>
                                    <DiseasesDropList lang={getLanguage}/>
                                    <HerbsDropList/>
                                    <DietChartDropList/>
                                </fieldset>
                            </>
                        }
                    </Grid>
                    {getRecipeName ?
                        <Grid item lg={7} xs={12}>
                            <p className={classes.title}>{getRecipeName}</p>
                            <div className={`welcomeBody BodyImg ${classes.scrol}`} dangerouslySetInnerHTML={{__html: `${getDetail}`}}/>
                        </Grid>
                        :<></>
                    }
                </Grid>
                <Scroll showBelow={1000}/>
            </Container>
        </>
    );
}