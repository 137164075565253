import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import InfoIcon from '@mui/icons-material/Info';
import Product from '@material-ui/icons/ViewQuilt';
import Gallery from '@material-ui/icons/Apps';
import Library from '@material-ui/icons/LocalLibrary';
import Media from '@material-ui/icons/Theaters';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
// import Doctor from '@material-ui/icons/LocalHospital';
// import Download from '@material-ui/icons/GetApp';
// import Container from "@material-ui/core/Container";
// import Typography from "@material-ui/core/Typography";
const DrawerData = [
    {
        items: [
            {
                id: 1,
                name: "Home",
                icon: <HomeIcon  fontSize="small"/>,
                path:"/"
            },
            {
                id: 2,
                name: "Company",
                icon: <BusinessIcon fontSize="small" />,
                subitems: [
                    {
                        id: 21,
                        name: "About Us",
                        icon: <InfoIcon  fontSize="small"/>,
                        path:"/about"
                    },
                    {
                        id: 22,
                        name: "CMD Desk",
                        icon: <PersonIcon fontSize="small" />,
                        path:"/Chairman"
                    },
                    {
                        id: 23,
                        name: "Director",
                        icon: <AccountCircleIcon  fontSize="small"/>,
                        path:"/md"
                    },
                    {
                        id: 24,
                        name: "Management",
                        icon: <ManageAccountsIcon  fontSize="small"/>,
                        path:"/mgmt"
                    },
                    {
                        id: 25,
                        name: "Certificate",
                        icon: <CardMembershipIcon  fontSize="small"/>,
                        path:"/crtf"
                    },

                    {
                        id: 26,
                        name: "Companies In Group",
                        icon: <BusinessIcon  fontSize="small"/>,
                        path:"/goc"
                    },

                ]
            },
            {
                id: 3,
                name: "Products",
                icon: <Product  fontSize="small"/>,
                subitems: [
                    {
                        id: 31,
                        name: "DBS Products",
                        icon: <ArtTrackIcon fontSize="small"/>,
                        path:"/products"
                    },
                    {
                        id: 32,
                        name: "Ayush Kwath",
                        icon: <MenuBookIcon fontSize="small"/>,
                        path:"/ayushKwath"
                    },
                ]
            },
            {
                id: 4,
                name: "Gallery",
                icon: <Gallery fontSize="small"/>,
                path:"/nopage"
            },
        ]
    },

    {
        items: [
            {
                id: 5,
                name: "Library",
                icon: <Library fontSize="small"/>,
                subitems: [
                    {
                        id: 51,
                        name: "Know About",
                        icon: <MenuBookIcon fontSize="small"/>,
                        path:"/knowhat"
                    },
                    {
                        id: 52,
                        name: "Why Ayurveda",
                        icon: <FilterVintageIcon fontSize="small"/>,
                        path:"/ayurveda"
                    },
                    {
                        id: 53,
                        name: "Healthy Recipes",
                        icon: <FoodBankIcon fontSize="small"/>,
                        path:"/recipe"
                    },
                    {
                        id: 54,
                        name: "Benefits of Yoga",
                        icon: <SelfImprovementIcon fontSize="small"/>,
                        path:"/yoga"
                    },
                    {
                        id: 55,
                        name: "FAQ's",
                        icon: <LiveHelpIcon fontSize="small"/>,
                        path:"/faq"
                    },
                ]
            },
            {
                id: 6,
                name: "Media",
                icon: <Media fontSize="small"/>,
                // path:"/nopage"
                subitems: [
                    {
                        id: 61,
                        name: "Apna App",
                        icon: <AppShortcutIcon fontSize="small"/>,
                        path:"/apnaApp"
                    },
                    {
                        id: 62,
                        name: "Why Dhanvantary",
                        icon: <TouchAppIcon fontSize="small"/>,
                        path:"/whydbs"
                    },
                ]
            },
            // {
            //     id: 7,
            //     name: "Download",
            //     icon: <Download/>,
            //     path:"/"
            // },
            // {
            //     id: 8,
            //     name: "Doctor",
            //     icon: <Doctor fontSize="small"/>,
            //     path:"/nopage"
            // },
            {
                id: 9,
                name: "Admin",
                icon: <SettingsIcon fontSize="small"/>,
                subitems: [
                    {
                        id: 91,
                        name: "Info. Edit",
                        icon: <EditIcon fontSize="small"/>,
                        path:"/editkw"
                    },
                    {
                        id: 92,
                        name: "More Edit",
                        icon: <EditIcon fontSize="small"/>,
                        path:"/sddtc"
                    },
                    {
                        id: 93,
                        name: "Add FAQ",
                        icon: <EditIcon fontSize="small"/>,
                        path:"/addfaq"
                    },
                ]
            },
        ]
    },
];
export default DrawerData;