import React,{useState} from "react";
import MenuAppBar from "./appBar";
import Sidenav from "./Sidebar";
// import MobSidebar from "./mobSidebar";

export default function MenuAll(props){
    // console.log(props)
    const [getState, setState] = useState();
    // const [getLanguage, setLanguage] = useState();

    const callback=(appBarLang)=>{
        if(appBarLang==='xx'){
            props.statesCallBack('xx');          // On adminSessionClose() return 'xx'
        } else{
            if(appBarLang) {
                props.statesCallBack(appBarLang);
            }else{
                props.statesCallBack(localStorage.getItem('language'));
            }
            setState(appBarLang);
            setLanGlobal(appBarLang);
        }
    }

    const setLanGlobal=(lang)=>{
        props.statesCallBack(lang);                // Send 'language' to parent 'app.js'.
    }

    return(
        <>
            <MenuAppBar appBarCallback={callback}/>
            <Sidenav menuOpen={getState} appBarCallback={callback}/>
        </>
    );
    // return(
    //     <>
    //         <MenuAppBar appBarCallback={callback}/>
    //         <Sidenav menuOpen={gotState} appBarCallback={callback}/>
    //
    //     </>
    // );
}