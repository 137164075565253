import React, {useEffect, useState} from 'react';
import "../App.css";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ayushKwath from "../bannerImage/ayushKwath.jpg";
import MoreAyur, {MoreDropAyur} from "./moreAyur"
import {fetchPage} from "./hooks/inputHooK";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import DraftEdit from "./admin/draftEdit";
import kyb from "../bannerImage/kyb.jpg";
import useWindowDimensions from "./hooks/utilityHooK";

// import HerbsList from "./herbsList";
// import ayush2 from '../bannerImage/ayush-katha-2.png';
// import ImageList from "@mui/material/ImageList";
// import DiseasesList from "./diseasesList";
// import HerbsList from "./herbsList";
// import DietChartList from "./dietChartList";


export default function AyushKwath(props) {
    const {height, width} = useWindowDimensions();
    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'ayushKwath';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------
    return (
        // <div className={classes.root}>
        <>
            <div>
                <img src={ayushKwath} alt={'ayushKwath'} className={'topBanner'}/>
            </div>
            <Container maxWidth="xl" style={{marginTop: '50px'}}>
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12}>
                        {width > 800 ?
                            <>
                            <MoreAyur/>
                            <div style={{marginTop: '20px'}}>
                                <a href={'/prakriti'}><img src={kyb} alt={'kyb'}
                                                           style={{marginLeft: '25px', width: '85%'}}/></a>
                            </div>
                            </>
                            :
                            // <div style={{marginBottom:'20px'}}>
                            //     <MoreDropAyur/>
                            // </div>
                            <></>
                        }
                    </Grid>
                    <Grid item lg={7} xs={12} align={'center'}>
                        <div
                            style={{backgroundColor: '#446600', padding: '8px', color: 'white', fontWeight: 'bold'}}>WAR
                            AGAINST COVID-19
                        </div>

                        <span
                            className='heading'>
                            {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                            {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                    <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                        <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                             onClick={() => {
                                                 handelEditor(getPageDb);
                                                 setBlock(getPageDb[0].block)
                                             }}
                                        >
                                            <Edit/>
                                        </Fab>
                                    </Tooltip>
                                </Box>
                                : <></>}
                        </span>
                        <text className='welcomeBody BodyImg' align={'justify'} style={{columnCount: 1}}
                              dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></text>
                    </Grid>
                </Grid>
                {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
            </Container>
        </>
// </div>
    );
}