import React, {useState} from 'react';
import "../App.css";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import whysubhead2 from '../bannerImage/why-subhead-2.jpg';
import whyAyurveda from "../bannerImage/Page_top_banner/why_ayurveda.jpg";
import kyb from "../bannerImage/kyb.jpg";
import MoreAyur, {MoreDropAyur} from "./moreAyur"
import DiseasesList, {DiseasesDropList} from "./diseasesList";
import HerbsList, {HerbsDropList} from "./herbsList";
import DietChartList, {DietChartDropList} from "./dietChartList";
import Scroll from './scrollTop';
import useWindowDimensions from "./hooks/utilityHooK";
import draftToHtml from "draftjs-to-html";
import {makeStyles} from "@material-ui/core/styles";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import ImageList from "@mui/material/ImageList";
// import FormControl from "@mui/material/FormControl";
// import Typography from '@material-ui/core/Typography';
// import {makeStyles, withStyles} from "@material-ui/core/styles";
// import {makeStyles} from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import List from "@mui/material/List";
// import Button from "@material-ui/core/Button";
// import md from '../bannerImage/chairman.jpg';
// import Divider from '@material-ui/core/Divider';

// const Txt = withStyles({
//     root: {
//         fontSize: 16,
//         fontFamily: "Prata",
//         color: "gray",
//     },
// })(Typography);
const useStyles = makeStyles((theme) => ({
    scrol: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "1900px",
        marginTop: "-40px",
        // paddingRight:'100px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "-20px",
            height: "auto",
        },
    },
    title: {
        // marginTop: "30px",
        fontFamily: "Copperplate, Fantasy",
        fontSize: "35px",
        color: "#fff",
        // textShadow: "2px 2px 5px #f3fa2f",
        textAlign: "center",
        backgroundColor: '#8cd674',
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        },
    },
}));

export default function WhyAyurveda(props) {
    const classes = useStyles();
    const {height, width} = useWindowDimensions('');
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getBannerUrl, setBannerUrl] = useState('');
    const [getTitle, setTitle] = useState('');
    const [getDetail, setDetail] = useState('');
    const [getObj, setObj] = useState('');
    const [getTag, setTag] = useState('0');
let obj='';

    function getMoreObj(objBack) {
        // setBannerUrl(objBanner);
        // setTitle(objTitle);
        //     setDetail(draftToHtml(objDoc));
        if(objBack) {
            obj = JSON.parse(objBack);
        }

        // console.log('MMMMMMMMMMMMM',obj)
        if (obj) {
            if (getLanguage === 'Ben') {
                setDetail(draftToHtml(obj.detailBen));
                setTitle(obj.title.Ben)
            } else if (getLanguage === 'Hin') {
                setDetail(draftToHtml(obj.detailHin));
                setTitle(obj.title.Hin)
            } else {
                setDetail(draftToHtml(obj.detailEng));
                setTitle(obj.title.Eng)
            }
            setBannerUrl(obj.banner);
            // setDetail(contentState);
        } else {
            setDetail("No data found.");
        }


    }

    // function getBannerLink(bl, tag) {
    function getBannerLink(bl) {
        if(bl) {
            setBannerUrl(bl);
        }else{
            setBannerUrl('https://node.dhanvantary.com/images/dbsweb/banner_more/1644839590790_why_ayurveda.jpg');
        }
    }

    return (
        // <div className={classes.root}>
        <>
            <div>
                {getBannerUrl ?
                    <img src={getBannerUrl} alt={'whyAyurveda'} className={'topBanner'}/> :
                    <img src={whyAyurveda} alt={'whyAyurveda'} className={'topBanner'}/>
                }
            </div>
            <Container maxWidth="xl" style={{marginTop: '30px'}}>
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12}>
                        {width > 800 ?
                            <>
                                <MoreAyur callListMore={getMoreObj} lang={getLanguage}/>
                                <div style={{marginTop: '20px'}}>
                                    <a href={'/prakriti'}><img src={kyb} alt={'kyb'}
                                                               style={{marginLeft: '25px', width: '85%'}}/></a>
                                </div>
                                <DiseasesList/>
                                <HerbsList/>
                                <DietChartList/>
                            </> :
                            <>
                                <MoreDropAyur callDropMore={getMoreObj} lang={getLanguage}/>
                                <fieldset style={{
                                    backgroundColor: '#e2fcb8',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    marginLeft: '4%',
                                    borderRadius: '4px',
                                    width: '90%'
                                }}>
                                    <legend>Other Usefull Links:</legend>
                                    <DiseasesDropList/>
                                    <HerbsDropList/>
                                    <DietChartDropList/>
                                </fieldset>
                            </>
                        }
                    </Grid>

                    { getBannerUrl?
                        // (getTitle?
                        <>
                        <Grid item lg={7} xs={12} >
                            <p className={classes.title}>{getTitle}</p>
                            <div className={`welcomeBody BodyImg ${classes.scrol}`} dangerouslySetInnerHTML={{__html: `${getDetail}`}} />
                        </Grid>
                        </>
                        // :<></>)
                        :
                        <Grid item lg={7} xs={12} align={'center'}>
                        <span
                        className='heading'>Ayurveda is a natural and holistic ancient Indian medical science.</span>
                        <div className='welcomeBody' align={'justify'}>
                        The aim of Ayurveda is to maintain a balanced body, mind and spirit. To stay healthy, a
                        person should follow the correct daily routine or dincharya. This includes adapting to
                        seasonal changes and eating a diet with adequate nutrition. For a long time, conventional
                        medicine has been trying to find a solution for long-term chronic problems such as
                        paralysis, AIDS, high cholesterol, psoriasis and so on. The field of Ayurveda has been
                        garnering a lot of interest lately for treating such ailments. <br/><br/>
                        {/*</div>*/}
                        {/*<br/>*/}
                        {/*<div className='welcomeBody' align={'justify'}>*/}
                        The principles of Ayurveda can be understood by drawing a parallel to a better understood
                        system of present day...Allopathy. The definition of health by WHO is 'A state of not merely
                        the absence of disease but a sense of well being at a physical, mental, psychological and
                        spiritual level'. The definition of health in Ayurveda is a balance and harmony of all
                        forces acting on the body at the physical, mental, psychological and soul level. While
                        western medicine is of disease and treatment, Ayurveda also includes rules of healthy living
                        and ways of maintaining health. Thus the scope of Ayurveda is wide and encompasses diet,
                        behavior, principles of right living and also of disease and treatment of disease.
                        {/*</div>*/}
                        <br/><br/>
                        <p className='heading'>AYURVEDA - A TIME TESTED MEDICAL SCIENCE. </p>
                        {/*<div className='welcomeBody' align={'justify'}>*/}
                        What has to exist, exists, no matter what way the world changes. Any science, that is
                        applicable survives through different generations and flows from one generation to another.
                        Nature has its unique way to make or break a system, and we remain just an onlooker, erased
                        by the passage of time! So Ayurveda is a science which has faced the turmoil of all times
                        and is still emerging as a leading alternative health care system. Ayurveda is the world's
                        oldest science of health care. The written tradition dates back around 5,000 years, but the
                        oral tradition of this science is timeless.

                        <img src={whysubhead2} alt={'whysubhead2'} align={'center'} width='95%'
                        style={{marginTop: '20px'}}/>

                        {/*</div>*/}
                        <br/><br/>
                        <p className='heading'>The Basic Principles Of Ayurveda</p>
                        {/*<div className='welcomeBody' align={'justify'}>*/}
                        There are three basic fundamental biological humours described by Ayurveda- The Vata, Pitta
                        and Kapha also called the "tri-energies". These are the ones who are totally controlling the
                        universe and are responsible for planetary movements (Vata), their formation and destruction
                        (Pitta) and generation and maintenance of new life (Kapha). Same tri-energies are found
                        inside every living being and are responsible for health and disease. Their balance is
                        "health" or "harmony" whereas the imbalance is "disease" or "chaos". <br/><br/>
                        These govern all the processes in all levels of our life. Vata governs all movement, Pitta
                        all heat and transformation and Kapha all growth, structure and lubrication. <br/><br/>
                        Our diet, our behavior, the seasons, emotions and our life style all have impact on balance
                        of tri energies. The main principal of healing in Ayurveda is to maintain the balance of
                        Vata, Pitta and Kapha by alteration in our lifestyle, our thoughts, our diet and
                        understanding nature and through herbal medicines.
                        {/*</div>*/}
                        <br/><br/>
                        <p style={{fontFamily: 'monospace', color: 'red', fontSize: '16.5px'}}>" Samadosha
                        samagnisch samadhatu malakriyaPrasanna atma mana swastha iti abhidhiyate."<span
                        style={{color: 'red', fontSize: '12px'}}> (SANSKRIT VERSE)</span></p>
                        <p className='heading'>It States That:-</p>
                        {/*<div className='welcomeBody' align={'justify'}>*/}
                        Having a balanced state of Doshas, Agni (digestive fire), Dhatus (tissues) and normal
                        functioning of
                        Mala (waste products), cheerful state of Atman (soul), sensory organs and mind are the
                        symptoms of healthy life.
                        <br/><br/>
                        <li>If "Vata" gets out of balance, for instance, it leads to overactive mind, poor
                        circulation, poor nerve conduction, loss of memory, irregular elimination &
                        uncomfortable menses etc - all
                        things related to movement.
                        </li>
                        <li>If "Pitta" is out of balance, we can get excessive digestive fire, resulting in
                        heartburn, excess
                        stomach acid, hot temper & inflammations etc -all things related to heat and digestion.
                        </li>
                        <li>If "Kapha" gets out of balance, it can lead to chronic congestion, weight gain,
                        cellulite, cholesterol
                        buildup, acne & oily skin etc - all things related to structure and lubrication.
                        </li>
                        <br/><br/>
                        Herbs in synergistic combination, diet, routine & meditation etc are used to restore
                        balance, to restore proper operation of the various systems & to restore health. Ayurveda
                        does not focus on decreasing symptoms, it focuses on increasing health. Where there is
                        health, there is no room for disease.
                        <br/><br/>
                        Due to foreign invasions in India for hundreds of years, Ayurveda became fragmented and it
                        on its comeback trail from around last 35 years. As a result of growing scientific
                        verification at major research institutions, it is the worlds' fastest growing health care
                        system. <br/><br/>

                        According to Ayurveda, the five elements (fire, earth, water, air & space) in their
                        biological form combine to form these 3 energies in the body. These 3 basic energies are the
                        primary life forces or biological humors, called doshas in Ayurveda - The Vata, Pitta and
                        Kapha. They regulate physical functioning within our body, besides providing us with
                        individual physical characteristics. The constitution of a person is primarily determined by
                        the dominant dosha.
                        <br/><br/>
                        It is such a unique combinations of doshas that no two persons are alike. The determination
                        of constitution gives insight into deeper working, behavior, body type and with this it is
                        possible to know which herbs, diet, medicines and activities will be suitable for a
                        particular individual to maintain his health whole life. It also becomes easier to outline
                        the disease tendencies of the different body types so that a preventive lifestyle may be
                        observed. Accordingly, a person can plan diet and other routine activities affirming to his
                        / her constitution. This individual constitution analysis is an important step to ascertain
                        about types of diseases and to help a lot in maintenance of good health and prevention of
                        diseases.
                        </div>
                        <br/> <br/>
                        </Grid>
                    }

                </Grid>
                <Scroll showBelow={1000}/>
            </Container>
        </>
        // </div>
    );
}