import React, {useEffect, useState} from 'react';
import "../App.css";
import Grid from "@material-ui/core/Grid";
import DiseasesList, {DiseasesDropList} from "./diseasesList";
import HerbsList, {HerbsDropList} from "./herbsList";
import DietChartList, {DietChartDropList} from "./dietChartList";
import Container from "@material-ui/core/Container";
import aboutBanner from "../bannerImage/Page_top_banner/about_us_top.png";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import {fetchPage} from './hooks/inputHooK'
import DraftEdit from "./admin/draftEdit";
import draftToHtml from "draftjs-to-html";
import kyb from "../bannerImage/kyb.jpg";
import useWindowDimensions from "./hooks/utilityHooK";
// import img2 from '../bannerImage/about_us-left.jpg';
// import MoreAyur, {MoreDropAyur} from "./moreAyur";
// import Typography from "@material-ui/core/Typography";
// import img1 from "../bannerImage/web_logo.jpg";
// import { FixedSizeList } from 'react-window';
// import WelcomeNote from "./welcome";
// import {Route} from "react-router-dom";
// import welcome from "./welcome";

const useStyles = makeStyles((theme) => ({
    img1: {
        width: '50%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '98%',
        },
    },
    scrol: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "1200px",
        marginTop: "-20px",
        // paddingRight:'100px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "-20px",
            height: "auto",
        },
    },
    // root: {
    //     flexGrow: 1,
    //     '& .MuiAppBar-colorPrimary': {
    //         color: '#fff',
    //         backgroundColor: '#446600',
    //     },
    // },
    // menuButton: {
    //     marginRight: theme.spacing(1),
    // },
    // title: {
    //     flexGrow: 1,
    // },
    // listHead: {
    //     marginLeft: '5%', paddingTop: '5px',
    //     marginTop: '20px', height: '40px',
    //     width: '86%', backgroundColor: '#446600',
    // },
}));


export default function Aboutus(props) {
    const classes = useStyles();
    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    const {height, width} = useWindowDimensions();
    let pageName = 'aboutus';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x----------------------

    return (
        <>
            <div>
                {/*<Grid item xs={12}>*/}
                <img src={aboutBanner} alt={'image'} className={'topBanner'}/>
                {/*</Grid>*/}
            </div>

            <div>
                <Container maxWidth="xl" style={{marginTop: '30px'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={6} lg={4}>
                            {/*<DiseasesList/>*/}
                            {/*<HerbsList/>*/}
                            {/*<DietChartList/>*/}
                            {width > 800 ?
                                <>
                                    <DiseasesList/>
                                    <HerbsList/>
                                    <DietChartList/>
                                </>:
                                <>
                                    <fieldset style={{backgroundColor: '#e2fcb8',borderRadius: '4px', width: '98%'}}>
                                        <legend>Other Usefull Links:</legend>
                                        <DiseasesDropList/>
                                        <HerbsDropList/>
                                        <DietChartDropList/>
                                    </fieldset>
                                    <div style={{marginTop: '20px'}}>
                                        <a href={'/prakriti'}><img src={kyb} alt={'kyb'}
                                                                   style={{marginLeft: '25px', width: '85%'}}/></a>
                                    </div>
                                </>
                            }
                        </Grid>
                        <Grid item xs={12} md={12} lg={7} style={{marginTop: '27px'}}>
                            {/*<h2 align={'center'}>Who We Are</h2>*/}
                            <p className={'heading'}>
                                {/*DHANVANTARY BIOSCIENCE PRIVATE LIMITED AN ISO 14001-2015 CERTIFIED COMPANY.*/}

                                {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                                {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                                    <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                        <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                            <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                                 onClick={() => {
                                                     handelEditor(getPageDb);
                                                     setBlock(getPageDb[0].block)
                                                 }}
                                            >
                                                <Edit/>
                                            </Fab>
                                        </Tooltip>
                                    </Box>
                                    : <></>}
                            </p>

                            {/*<img src={img2} alt={'image'} className={classes.img1}/>*/}
                            <p className={`welcomeBody ${classes.scrol}`}
                               dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></p>
                            {/*</div>*/}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
        </>
    );
}