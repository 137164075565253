import DBPath from "./dbPath";
import uploadImg from "../../bannerImage/uploadImage.jpg";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useInput} from "../hooks/inputHooK";
import swal from "sweetalert2";
import uploadImageToserver from "../imageUpload";
import FormControl from '@mui/material/FormControl';
import {makeStyles} from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Autocomplete from "@mui/material/Autocomplete";

let dbPath = DBPath();
const useStyles = makeStyles((theme) => ({
    uploadButton: {
        borderRadius: 4,
        marginTop: '-50px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
        },
    },
}));
export default function UploadImage() {
    const classes = useStyles();
    const [getUrl, setUrl] = useState();
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState();
    const [getFolder, setFolder] = useState();
    const [getPage, setPage] = useState();
    const [getBlock, setBlock] = useState();
    const [getPageList, setPageList] = useState('');
    const [getBlockList, setBlockList] = useState('');
    // const [GalleryImg, setGalleryImg] = useState(false);
    const {value: Url, bind: bindUrl, reset: resetUrl} = useInput('');
    const {value: page, bind: bindPage, reset: resetPage} = useInput('');
    let distinctSubject = '';
    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }
    useEffect(() => {
        const findPage = async () => {
            try {
                let url = `${dbPath}/pages`;
                let response = await fetch(url);
                response = await response.json();
                distinctSubject = [...new Set(response.map(data => data.page))];
                setPageList(distinctSubject);
                // console.log('0000000000000',distinctSubject);
            }catch(e){
                console.error(e);
            }
        }
        findPage();
    }, []);

    const handelList = (event, value) => {
        setPage(value);
        // setSubject(value);
        // let key = event.target.id.split("-")[0];
        const fetchBlock = async (subject) => {
            try {
                let url = `${dbPath}/pages?q=[{"field":"page","opt":"==","value":"${subject}"}]`;
                let response = await fetch(url);
                response = await response.json();
                setBlockList(response.map(data => data.block))
                // distinctSubject = [...new Set(response.map(data => data.block))];
                // if(collection=='faqEng') { setNameEng(distinctSubject);}
            }catch (e) {
                console.error(e);
            }
        }
        // setNameEng(fetchName('faqEng',value));
        // setNameBen(fetchName('faqBen',value));
        // setNameHin(fetchName('faqHin',value));
        fetchBlock(value);
    }


    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const uploadImageToserverCallTop = async (path) => {
        const retyernr = await uploadImageToserver(selectedFile, path);
        if (retyernr) {
            setUrl(retyernr);
            setPreview(null);
            setSelectedFile(undefined);
        } else {
            swal.fire("Upload failed !!");
        }
    }
    const handlePath = (event) => {
        setFolder(event.target.value);
    }

    const addNew = async (evt) => {
        evt.preventDefault();
        let insId='';
        let json = {
            page: getPage,
            block: getBlock,
            headEng: 'head',
            bodyEng: {
                blocks: [],
                entityMap: {},
            },
        }
        let body = Object.assign(json);
        try {
            const response = await fetch(`${dbPath}/pages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            insId=await response.json();
            // console.log('responceeee',insId)
            // setOpen(false);
            // swal.fire({ icon: 'success', title: 'Successfully Saved',timer: 2000});

        } catch (error) {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        }

    }
    return (
        <>
            <div>
                <img src={uploadImg} alt={uploadImg} className={'topBanner'}/>
            </div>
            <Container maxWidth="lg" style={{marginTop: '50px'}}>
                <Grid container spacing={0}>
                    <Grid item lg={4} xs={12}>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
                            <InputLabel>Select Categary</InputLabel>
                            <Select
                                onChange={handlePath}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'diet_img'}>Diet Image</MenuItem>
                                <MenuItem value={'disease_img'}>Diseases Image</MenuItem>
                                <MenuItem value={'general_pages'}>General Image</MenuItem>
                                <MenuItem value={'herbs_img'}>Herbs Image</MenuItem>
                                <MenuItem value={'main_slider'}>Main Slider</MenuItem>
                                <MenuItem value={'banner_more'}>More Ayur Banner</MenuItem>
                                <MenuItem value={'more_img'}>More Image</MenuItem>
                                <MenuItem value={'nutrients_img'}>Nutrients Image</MenuItem>
                                <MenuItem value={'page_top_banner'}>Page Top Banner</MenuItem>
                                <MenuItem value={'recipe_img'}>Recipe Image</MenuItem>
                                <MenuItem value={'remedies_img'}>Remedies Image</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <div style={{minHeight: '250px'}}>
                            <TextField onChange={(e) => alert(e.target.value)} {...bindUrl}
                                       style={{width: '100%', paddingTop: '15px'}} value={getUrl}/>
                            <div>
                                <input type='file' onChange={onSelectFile}/>
                                {getFolder && preview && <><img src={preview} width="320px" style={{margin: '10px'}}/>
                                    <Button size="medium"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => uploadImageToserverCallTop([getFolder, 'dbsweb'])}
                                            className={classes.uploadButton}
                                    >
                                        Upload
                                    </Button>
                                </>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Divider/>
                <div>
                    <p>Add Page/Block to DB</p>
                    <form onSubmit={addNew}>
                        <Grid container spacing={0}>
                            <Grid lg={4} xs={12}>
                                <div style={{width: 200}}>
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        onChange={handelList}
                                        options={getPageList ? getPageList.map((option) => option) : []}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Page Name" margin="normal"
                                                       onChange={(event) => {
                                                           setPage(event.target.value)
                                                       }} InputProps={{...params.InputProps, type: 'search'}}/>
                                            // <TextField {...bindPage} label="Page Name" onChange={(event)=>{setPage(event.target.value)}} InputProps={{ ...bindPage.InputProps, type: 'search', }} />
                                        )}
                                    />

                                </div>
                                {/*<TextField onChange={(e)=>setPage(e.target.value)} label={'Page Name'} required={true} style={{fontSize: '10px'}}/>*/}
                            </Grid>
                            <Grid lg={4} xs={12}>
                                <div style={{width: 200}}>
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        onChange={handelList}
                                        options={getBlockList ? getBlockList.map((option) => option) : []}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Block Name" margin="normal"
                                                       onChange={(event) => {
                                                           setBlock(event.target.value)
                                                       }} InputProps={{...params.InputProps, type: 'search'}}/>
                                            // <TextField {...bindPage} label="Page Name" onChange={(event)=>{setPage(event.target.value)}} InputProps={{ ...bindPage.InputProps, type: 'search', }} />
                                        )}
                                    />

                                </div>
                                {/*<TextField onChange={(e)=>setBlock(e.target.value)} label={'Block Name'} required={true} style={{fontSize: '10px'}}/>*/}
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <Button size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{marginLeft: '35px', borderRadius: 8}}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </>
    );
}