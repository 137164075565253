import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ImageList from "@mui/material/ImageList";
import DialogActions from "@mui/material/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@mui/material/Dialog";
import React, {useState} from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {AddPhotoAlternate, PhotoLibrary, Publish, Save} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import {useInput} from "./inputHooK";
import uploadImageToserver from "../imageUpload";
import {makeStyles} from "@material-ui/core/styles";
import LinearProgress from '@mui/material/LinearProgress';
import DBPath from "../admin/dbPath";

let dbPath = DBPath();
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
// import BurstModeIcon from '@mui/icons-material/BurstMode';


const useStyles = makeStyles((theme) => ({
    imginput: {
        display: "none"
    },
    galaryImgPop: {
        padding: '3px',
        backgroundColor: '#956161',
    },
    galaryImgPop_deleteBtn: {
        position: 'absolute',
        top: '10px',
        color: 'red',
        fontsize: '15px',
        backgroundColor: '#fff'
    },
    selected_galaryImgPop: {
        padding: '12px',
        // border: '5px solid #0388fc',
        // border: '#0388fc',
    },

}));
export default function OpenImageGallery(props) {
    const classes = useStyles();
    const [GalleryImg, setGalleryImg] = useState([]);
    const [GalleryImgOpen, setGalleryImgOpen] = useState(false);
    const [top_imageUpfrom, setTop_imageUpfrom] = useState(false);
    // const [top_selectedImgPath, setTop_selectedImgPath] = useState('');
    // const [top_uploadedImage, setTop_uploadedImage] = useState([]);
    // const [imageToserverCall, uploadImageToserverCall] = useState(false);
    // const [top_uploadCount, setTop_uploadCount] = useState(false);

    const [galleryImgPath, setGalleryImgPath] = useState(props.imgArr);

    const [getUrl, setUrl] = useState();
    // const [getState, setState] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [getProgressBar, setProgressBar] = useState(false);
    const [getDialogOpen, setDialogOpen] = useState(true);
    const {value: Url, bind: bindUrl, reset: resetUrl} = useInput('');

    const handleGalleryOpenTop = async () => {
        try {
            // const paths = top_imageUpfrom;
            const responsesImg = await fetch(`https://node.dhanvantary.com/3/upload?database=${props.imagePath[1]}&folder=${props.imagePath[0]}`).then(res => res.json())
                .then((result) => {
                    if (result.length > 0) {
                        setGalleryImg(result);
                        setGalleryImgOpen(true);
                        // console.log('xxxxxxxxx', responsesImg);
                    } else {
                        setGalleryImgOpen(false);
                        swal.fire("First Choose A App Category Then Try.");
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setUrl(null);
        setSelectedFile(e.target.files[0])
    }

    const uploadImageToserverCallTop = async (path) => {
        setProgressBar(true)
        const retyernr = await uploadImageToserver(selectedFile, path);
        setUrl(retyernr);
        setProgressBar(false)
    }

    const handleGalleryImgSelectTop = (img) => {
        let arr = galleryImgPath;
        try {
            let imgPath = "https://node.dhanvantary.com/images/" + props.imagePath[1] + "/" + props.imagePath[0] + "/" + img;
            const isinArr = arr.includes(imgPath);
            if (!isinArr) {
                arr.push(imgPath);
            } else {
                arr.splice(arr.indexOf(imgPath), 1);
            }
            setGalleryImgPath(arr);
            // console.log(arr)
        } catch (e) {
            console.error(e);
        }
    }

    const saveToDb = async () => {
        let obj = {};
        obj[props.docField] = galleryImgPath;
        let rawbody = JSON.stringify(obj);
        let jsons = {
            field: "_id",
            opt: "==",
            value: props.docId, id: true
        };
        jsons = JSON.stringify(jsons);

        try {
            fetch(`${dbPath}/${props.collectionName}?q=[${jsons}]`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: rawbody
            }).then(res => res.json()).then((result) => {
                // console.log(result)
                if (result) {
                    props.successCallBack(galleryImgPath)
                }
                let y = Math.floor(Math.random() * 100);
            });
        } catch (e) {
            console.log(e);
        }
        //-----------------
        // let body = {};
        // let json = [];

        // if (selectedFile) {
        //     json = {
        //         imgPath: getUrl
        //     }
        // } else {
        //     json = galleryImgPath.map((imgP) => {
        //         return {imgPath: `${imgP}`};
        //     })
        // }
        // body = Object.assign(json);
        // console.log(props.docField)
        // try {
        //     // const response = fetch(`http://localhost:3001/query/${collectionName}`, {
        //     const response = fetch(`${dbPath}/${props.collectionName}`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify(body)
        //     });
        //     setDialogOpen(false);
        //     swal.fire({icon: 'success', title: 'Successfully Saved', timer: 2000});
        // } catch (error) {
        //     // enter your logic for when there is an error (ex. error toast)
        //     console.log(error)
        // }
    }
    // console.log("xxxxxxxxxxxxxxxxx",galleryImgPath);
    return (
        <>
            <Dialog
                maxWidth="md"
                open={GalleryImgOpen}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title">
                    <div style={{float: 'left'}}>Choose From Our Vault</div>
                    {/*<div style={{float: 'right'}}>Try</div>*/}
                </DialogTitle>

                <DialogContent>
                    <ImageList cols={2}>
                        {GalleryImg ? GalleryImg.map((item, index) => (
                            <ImageListItem key={index}
                                           className={galleryImgPath.includes(
                                               props.imagePath[1] + "/" + props.imagePath[0] + "/" + item)
                                               ? classes.selected_galaryImgPop
                                               : classes.galaryImgPop}
                            >
                                <img
                                    src={`https://node.dhanvantary.com/images/${props.imagePath[1]}/${props.imagePath[0]}/${item}`}
                                    onError={(e) => {
                                        e.target.src = "https://node.dhanvantary.com/images/papaapp/folder_open.jpg"
                                    }}
                                    // alt={item}
                                    onClick={(e) => {
                                        handleGalleryImgSelectTop(item)
                                    }}/>
                                <ImageListItemBar title={item}/>
                                {/*<div className={classes.galaryImgPop_deleteBtn} onClick={()=>this.gallaryImgDel(this.state.top_imageUpfrom,item)}><DeleteForever /></div>*/}
                            </ImageListItem>
                        )) : <></>}
                    </ImageList>
                </DialogContent>
                <DialogActions>
                    {galleryImgPath.length > 0 ? <Button color="primary" variant="contained" onClick={() => {
                        setGalleryImgOpen(!GalleryImgOpen)
                    }}>
                        Confirm
                    </Button> : <></>}
                    <Button color="secondary" onClick={() => {
                        setGalleryImgOpen(!GalleryImgOpen)
                        setGalleryImgPath([])
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={getDialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">Upload Banners</DialogTitle>
                <DialogContent>
                    <TextField {...bindUrl} style={{width: '100%'}} value={getUrl}/>
                    <Card style={{padding: 6}}>
                        {/*<b> {top_imageUpfrom[0]} : </b>*/}
                        <CardContent style={{padding: 1}}>
                            <Grid container alignItems="center">
                                <Grid item xs={6} lg={6} spacing={2} align="center" style={{cursor: "pointer"}}>
                                    {/*<AddPhotoAlternateIcon style={{fontSize: "40px", color: "#55559f"}}/>*/}
                                    <input
                                        accept="image/*"
                                        className={classes.imginput}
                                        id="contained-button-file"
                                        // multiple
                                        type="file"
                                        onChange={onSelectFile}
                                        // onChange={handleUploadClickTop}
                                    />
                                    <label htmlFor="contained-button-file" style={{cursor: "pointer"}}>
                                        <AddPhotoAlternate style={{fontSize: "40px", color: "#305c75"}}/>
                                        <br/><i style={{fontSize: 15}}>Upload Image</i>
                                    </label>

                                </Grid>
                                <Grid item xs={6} lg={6} spacing={2} align="center"
                                      onClick={handleGalleryOpenTop}
                                      style={{cursor: "pointer"}}>
                                    <PhotoLibrary style={{fontSize: "40px", color: "#65871b"}}/>
                                    <br/><i style={{fontSize: 15}}>Choose From Folder</i></Grid>
                            </Grid>
                        </CardContent>
                        {galleryImgPath.length > 0 ? (<Grid container style={{backgroundColor: "#FAFFFF"}}>
                            <Grid item xs={12} sm={12}> Selected Images :</Grid>
                            {galleryImgPath.map((details) => (
                                <Grid item xs={6} sm={6}>
                                    <img src={details} style={{maxWidth: "250px"}}/>
                                </Grid>
                            ))}
                        </Grid>) : (selectedFile ?
                            <img src={URL.createObjectURL(selectedFile)} style={{maxWidth: "250px"}}/> : (" "))}
                    </Card>
                    {getProgressBar ? (
                        <LinearProgress/>
                    ) : ("")}
                </DialogContent>
                <DialogActions>
                    {(selectedFile && !getUrl) ? (
                        <Button onClick={() => uploadImageToserverCallTop([props.imagePath[0], props.imagePath[1]])}
                                color="primary"
                                autoFocus>
                            Upload <Publish/>
                        </Button>
                    ) : (galleryImgPath.length > 0 || getUrl ? (
                        <Button onClick={() => saveToDb(top_imageUpfrom, false)} color="primary"
                                autoFocus>
                            Save <Save/>
                        </Button>) : (''))}
                </DialogActions>
            </Dialog>
        </>
    );
}