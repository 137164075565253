import React, {useEffect,useState} from 'react';
import "../../App.css";
import Editinfo from "./Editinfo";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import DBPath from "./dbPath";
import GetSortOrder from "../hooks/inputHooK";
// import AddNew from "./newEditor";
let dbPath = DBPath();
const useStyles = makeStyles({
    root: {},
    slideLink: {
        fontFamily: 'Old Standard TT',
        fontSize: "12px",
        '&:hover': {
            // opacity: 0.5,
            color: '#446600',
            fontWeight: 'bold',
            backgroundColor: "white",
        },
    },
});

export default function SubTab(props) {
    // let language = '';
    let collection = '';
    let category = (props.data.replace(/[ -]/g, '').toLowerCase());
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    collection=category.concat(getLanguage);

    const classes = useStyles();
    // const [gotPage, getPage] = useState(0);
    // const [gotDocument, getDocument] = useState('');
    const [getDocument, setDocument] = useState('');
    const [value, setValue] = useState('');
    const [newvalue, setNewValue] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function findDoc(newDoc) {
        if (category === 'diseases' && (localStorage.getItem('diseaseDoc'))) {
            setDocument(localStorage.getItem('diseaseDoc'));
        } else if (category === 'herbs' && (localStorage.getItem('herbDoc'))) {
            setDocument(localStorage.getItem('herbDoc'));
        } else if (category === 'dietchart' && (localStorage.getItem('dietDoc'))) {
            setDocument(localStorage.getItem('dietDoc'));
        } else if (category === 'homeremedies' && (localStorage.getItem('remidiDoc'))) {
            setDocument(localStorage.getItem('remidiDoc'));
        } else if (category === 'nutrientsaz' && (localStorage.getItem('nutriDoc'))) {
            setDocument(localStorage.getItem('nutriDoc'));
        } else {
            setDocument(newDoc);
        }
    }
    useEffect(() => {
        async function fetchMyAPI(c) {
            try{
                let url = `${dbPath}/${c}`;
                let response = await fetch(url);
                response = await response.json();
                response = response.sort(GetSortOrder("name"));
                if (response.length > 0) {
                    findDoc(response[0].name);
                    // getDocument(response[0].name);
                }
                // else{
                //     getDocument("");
                // }
                setNewValue(response);
            }catch (e) {
                console.error(e);
            }
        }
        fetchMyAPI(collection);
    }, [collection]);


    function getDetails(param) {
        setDocument(param);
        if (category === 'diseases') {
            localStorage.setItem('diseaseDoc', param);
        } else if (category === 'herbs') {
            localStorage.setItem('herbDoc', param);
        } else if (category === 'dietchart') {
            localStorage.setItem('dietDoc', param);
        } else if (category === 'homeremedies') {
            localStorage.setItem('remidiDoc', param);
        } else if (category === 'nutrientsaz') {
            localStorage.setItem('nutriDoc', param);
        }
    }

    function DetailInfo(props) {
        return (
            <Editinfo txtData={props.docName} col={props.col} lang={props.lang}/>
        )
    }

    // function addNew() {
    //     getPage(1);
    // }

    return (
        <div>
            <Box sx={{maxWidth: '100%', bgcolor: 'background.paper'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {newvalue ? newvalue.map((data) => {
                        return (
                            <ul>
                                <Button className={classes.slideLink} onClick={() => getDetails(data.name)}
                                        style={getDocument === data.name ? {
                                            color: "Black",
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                            // textDecorationStyle: "double",
                                            textDecorationLine: "underline",
                                            textDecorationColor: "orange",
                                            textDecorationThickness: "3px",
                                            textShadow: "3px 3px 6px #f3fa2f",
                                        } : {color: "#446600"}}
                                >{data.name}</Button>
                            </ul>
                        );
                    }) : <></>}
                </Tabs>
            </Box>

            {getDocument?<DetailInfo docName={getDocument} col={props.data} lang={getLanguage}/>:<></>}

        </div>
    );
}