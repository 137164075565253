import React, {useState} from 'react';
import prakriti from "../bannerImage/Page_top_banner/vata_pitta_kapha.jpg";
import Container from "@material-ui/core/Container";
import {makeStyles} from '@material-ui/core/styles';
import {KnowYrBody, PrakritiQues, Result} from './prakritiQues'
import Grid from "@material-ui/core/Grid";
import MoreAyur, {MoreDropAyur} from "./moreAyur";
import DiseasesList, {DiseasesDropList} from "./diseasesList";
import HerbsList, {HerbsDropList} from "./herbsList";
import DietChartList, {DietChartDropList} from "./dietChartList";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import kyb from "../bannerImage/kyb.jpg";
import useWindowDimensions from "./hooks/utilityHooK";
// import {prakritiData} from './jsonPrakriti';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import Button from "@material-ui/core/Button";
// import {resetValidationMessage} from "sweetalert2";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from "@mui/material/FormControl";
// import FormLabel from '@mui/material/FormLabel';
// import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles({
    table: {
        Width: 650,
    },

    tbHead: {
        fontSize: '16px',
        color: '#fff',
        fontWeight: 'bold',
    },
});

export default function Prakriti(props) {
    const classes = useStyles();
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [value, setValue] = useState(localStorage.getItem('tab')?localStorage.getItem('tab'):0);
    const [getVata, setVata] = useState();
    const [getPitta, setPitta] = useState();
    const [getKapha, setKapha] = useState();
    const [getBT, setBT] = useState();
    const {height, width} = useWindowDimensions();
    const handleTab = (event, newValue) => {
        localStorage.setItem('tab',newValue);
        setValue(newValue);
    };

    const callBack=(childData)=>{
        // console.log('cccccccccccccccccccccc',childData);
        setValue(childData.tab);
        setVata(childData.vp);
        setPitta(childData.ppe);
        setKapha(childData.kp);
        setBT(childData.bt);
    }

    return (
        <>
            <div>
                <img src={prakriti} alt={prakriti} className={'topBanner'}/>
            </div>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item lg={3} xs={12}>
                        {width > 800 ?
                            <div style={{marginTop:'30px'}}>
                                <MoreAyur/>
                                <DiseasesList/>
                                <HerbsList/>
                                <DietChartList/>
                            </div>:
                            <>
                                <fieldset style={{backgroundColor: '#e2fcb8', marginTop:'20px',borderRadius: '4px', width: '98%'}}>
                                    <legend>Other Usefull Links:</legend>
                                    <MoreDropAyur/>
                                    <DiseasesDropList/>
                                    <HerbsDropList/>
                                    <DietChartDropList/>
                                </fieldset>
                            </>
                        }
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Tabs value={value} onChange={handleTab} centered style={{marginTop: '15px'}}>
                            <Tab label="Body Type in Ayurveda"
                                 style={{fontWeight: 'bold', fontFamily: 'Georgia', color: '#009fe3'}}/>
                            <Tab label="Test Your Body Type"
                                 style={{fontWeight: 'bold', fontFamily: 'Georgia', color: '#009fe3'}}/>
                            {value == 2 ?
                            <Tab label="Prakriti Analysis Result"
                                 style={{fontWeight: 'bold', fontFamily: 'Georgia', color: '#009fe3'}}/>
                                 :<></>}
                        </Tabs>
                        {value == 0 ?
                            <KnowYrBody lang={getLanguage}/> :
                            (value == 1 ?<PrakritiQues prakritiCallBack={callBack} lang={getLanguage}/>:
                                <Result vp = {getVata ? getVata:0} pp = {getPitta ? getPitta:0} kp = {getKapha ? getKapha:0} bt = {getBT ? getBT:''} lang={getLanguage}/>
                                )
                            // vp={childData.vp} pp={childData.ppe} kp={childData.kp}
                        }
                    </Grid>

                </Grid>
            </Container>
        </>
    );
}