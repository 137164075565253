import React, {useEffect, useState} from 'react';
import '../App.css';
import {makeStyles} from '@material-ui/core/styles';
import img_mam from '../bannerImage/RinkiMeurCU.png';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {fetchPage} from "./hooks/inputHooK";
import DraftEdit from "./admin/draftEdit";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {Edit} from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";

const cmd_style = makeStyles((theme) => ({
    // backgroundImage: img_sir,
    mam_xyz: {
        backgroundImage: `url(${img_mam})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // height:'85%',
        width: '85%',
        marginTop: '30px',
        float: 'right',
        // height: 'auto',
        // width:'100%',
        // paddingTop: 1000,
        [theme.breakpoints.down('sm')]: {
            float: 'left',
            width: '98%',
            // paddingTop: 220,
        },
    },
    txtStyle: {
        fontFamily: 'Cormorant Upright',
        fontSize: '20px',
        columnCount: 2,
        textAlign: 'justify',
        padding: '100px 70px 0px 70px',
        [theme.breakpoints.down('sm')]: {
            columnCount: 1,
            padding: '10px',
        },
    }
}));

export default function MD(props) {
    const classes = cmd_style();
    //------------ 'EDIT' dialog-box -----------
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    const [getPageDb, setPageDb] = useState('');
    const [getDialog, setDialog] = useState('');
    const [getBlock, setBlock] = useState('');
    let pageName = 'md';

    function callbackFunc(state, block, head, body) {
        if (!state) {
            // setPageDb((prevState) => ({ ...prevState, [`head${getLanguage}`]: head, [`body${getLanguage}`]: body }));
            setPageDb((prevState) => ({...prevState, [`head${getLanguage}`]: head}));
            // setPageDb((prevState) => ({ ...prevState, [`body${getLanguage}`]: body }));
            setDialog(false);
        }
    }

    let info = {
        collection: "pages",
        page: pageName,
        findSt: "page", // 1st field to search
        findNd: "block", // 2nd field to search
        state: getDialog, // dialog-box open-close state
        lang: getLanguage,
    }

    function handelEditor(e) {
        setDialog(true)
    }

    useEffect(() => {
        (async function () {
            try {
                const response = await fetchPage(pageName);
                const json = await response.json();
                // setPage(json.data.children.map(it => it.data));
                setPageDb(json);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    //-----------------x---------------------- #bafc03, #cfff21
    return (
        <Container maxWidth="xl" style={{marginTop: '50px', }}>
            <Grid container spacing={0}>
                <Grid item lg={4} xs={12} style={{background: "linear-gradient(to right,#cfff21, #fff)"}} className={''}>
                    <img src={img_mam} alt={'img_mam'} className={classes.mam_xyz} style={{height:'auto'}}/>
                    {/*<div className={classes.sir_xyz}></div>*/}
                </Grid>
                <Grid item lg={8} xs={12} className={` ${classes.txtStyle}`}
                      // style={{background: "linear-gradient(to right,#cfff21, #fff)"}}
                >
                    {/*<div style={{padding:'60px'}}>*/}
                    <h1 className='heading'>
                        {getPageDb[0] ? getPageDb[0][`head${getLanguage}`] : ''}
                        {((sessionStorage.getItem('okAdmin')) === 'okAdmin') ?
                            <Box display="flex" flexDirection="row-reverse" className={'floatButton'}>
                                <Tooltip title={'Edit (' + getLanguage + ')'} aria-label="Edit">
                                    <Fab size="small" style={{backgroundColor: '#caa136', color: '#fff'}}
                                         onClick={() => {
                                             handelEditor(getPageDb);
                                             setBlock(getPageDb[0].block)
                                         }}
                                    >
                                        <Edit/>
                                    </Fab>
                                </Tooltip>
                            </Box>
                            : <></>}
                    </h1>
                    <div>
                        <text className='welcomeBody'
                              dangerouslySetInnerHTML={{__html: `${getPageDb[0] ? draftToHtml(getPageDb[0][`body${getLanguage}`]) : ''}`}}></text>
                    </div>
                    {/*</div>*/}
                </Grid>
            </Grid>
            {getDialog ? <DraftEdit info={info} block={getBlock} successCallBack={callbackFunc}/> : <></>}
        </Container>

    )
}
