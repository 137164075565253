import React, {useEffect, useState} from 'react';
import "../App.css";
import Container from "@material-ui/core/Container";
import draftToHtml from "draftjs-to-html";
import Grid from "@material-ui/core/Grid";
import YogaList, {YogaDropList} from "./yogaList";
import DBPath from "./admin/dbPath";
import banner from "../bannerImage/yoga_banner.jpg";
import {makeStyles} from "@material-ui/core/styles";
import kyb from "../bannerImage/kyb.jpg";
import RecipeList, {RecipeDropList} from "./recipeList";
import DiseasesList, {DiseasesDropList} from "./diseasesList";
import HerbsList, {HerbsDropList} from "./herbsList";
import DietChartList, {DietChartDropList} from "./dietChartList";
import useWindowDimensions from "./hooks/utilityHooK";
import MoreAyur from "./moreAyur";
let dbPath = DBPath();

const useStyles = makeStyles((theme) => ({
    scrol: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "650px",
        marginTop: "-40px",
        // paddingRight:'100px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        [theme.breakpoints.down('sm')]: {
            height: "auto",
            marginTop: "180px",
        }
    },
    asana: {
        height: '400px',
        width: 'auto',
        float: 'right',
        marginTop: '-360px',
        [theme.breakpoints.down('sm')]: {
            marginTop:'-20px',
            marginRight:'20%',
            // display: 'none',
            height: '200px',
            width: 'auto',
        },
    },

    title: {
        marginTop: "30px",
        fontFamily: "Copperplate, Fantasy",
        fontSize: "35px",
        color: "#fff",
        // textShadow: "2px 2px 5px #f3fa2f",
        textAlign: "center",
        backgroundColor: '#8cd674',
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
            marginTop: "0px",
            // display:'none',
        },
    },
    // titleSM: {
    //     display:'none',
    //     [theme.breakpoints.down('sm')]: {
    //         display:'block !important',
    //         // marginBottom: "20px",
    //         fontFamily: "Copperplate, Fantasy",
    //         fontSize: "20px",
    //         color: "#fff",
    //         // textShadow: "2px 2px 5px #f3fa2f",
    //         textAlign: "center",
    //         backgroundColor: '#8cd674',
    //     },
    // },
    webOnly:{
        marginLeft: '25px',
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            display:'none',
        },
    },
}));


export default function Yoga(props) {
    const classes = useStyles();
    // let yoga = '';
    // let collection = '';
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : localStorage.getItem('language'));
    // const [gotvalue, getValue] = useState('');
    const [newvalue, setNewValue] = useState('');
    const [getTitle, setTitle] = useState('');
    const [detail, setDetail] = useState('');
    const {height, width} = useWindowDimensions();

    // if (localStorage.getItem('yoga')) {
    //     yoga = localStorage.getItem('yoga');
    // }else {
    //    yoga = 'Adho Mukha Svanasana';
    // }
    //
    // if (props.lang) {
    //     collection = 'yoga'.concat(props.lang);
    // }

    let obj ='';
    function yogaData(objBack) {
        // console.log('>>>>>>>',objBack)
        if(objBack) {
             obj = JSON.parse(objBack);
        }
        if (obj) {
            if (getLanguage === 'Ben') {
                setDetail(draftToHtml(obj.detailBen));
                setTitle(obj.title.Ben)
            } else if (getLanguage === 'Hin') {
                setDetail(draftToHtml(obj.detailHin));
                setTitle(obj.title.Hin)
            } else {
                setDetail(draftToHtml(obj.detailEng));
                setTitle(obj.title.Eng)
            }
            setNewValue(obj.banner);
            // setDetail(contentState);
        } else {
            setDetail("No data found.");
        }
    }

    return (
        <>
            <div>
                <img src={banner} alt={'image'} className={'topBanner'}/>
            </div>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item lg={4} md={6} xs={12} style={{marginTop: '30px'}}>
                        {width > 800 ?
                            <>
                                <YogaList callListYoga={yogaData} lang={getLanguage}/>
                                <div style={{marginTop: '20px'}}>
                                    <a href={'/prakriti'}><img src={kyb} alt={'kyb'}
                                                               style={{marginLeft: '25px', width: '85%'}}/></a>
                                </div>
                                {/*<DiseasesList/>*/}
                                {/*<HerbsList/>*/}
                                {/*<DietChartList/>*/}
                            </>:
                            <>
                                <YogaDropList callDropYoga={yogaData} lang={getLanguage}/>
                                <fieldset style={{backgroundColor: '#e2fcb8', marginTop:'20px', marginBottom:'20px',borderRadius: '4px', width: '98%'}}>
                                    <legend>Other Usefull Links:</legend>
                                    <DiseasesDropList/>
                                    <HerbsDropList/>
                                    <DietChartDropList/>
                                </fieldset>
                            </>
                        }
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <div>
                        <p className={`contentMobile ${classes.title}`}>{getTitle}
                            <img src={newvalue} alt={'image'} className={classes.asana}/>
                        </p>
                        </div>
                        <div >
                        <p className={`contentDesktop ${classes.title}`}>{getTitle}
                            <img src={newvalue} alt={'image'} className={classes.asana}/>
                        </p>
                        </div>
                        <div className={`welcomeBody BodyImg ${classes.scrol}`}
                             dangerouslySetInnerHTML={{__html: `${detail}`}}/>
                    </Grid>
                </Grid>
                {/*<Scroll showBelow={500}/>*/}
            </Container>
        </>
    );
}