import React, {useState} from 'react';
import "../../App.css";
import Tabs from "@mui/material/Tabs";
import diseases from "../../bannerImage/disease-virology.jpg";
import herbs from "../../bannerImage/herbs.jpg";
import diet from "../../bannerImage/Diet1.jpg";
import remedies from "../../bannerImage/homeRemedies1.jpg";
import nutrients from "../../bannerImage/nutrients.jpg";
import SubTab from "./SubTab";
import Tab from "@mui/material/Tab";


export default function EditKW(props) {
    // const classes = useStyles();

    let colN = '';
    // let docN = '';

    if (localStorage.getItem('tabName')) {
        colN = localStorage.getItem('tabName');
    } else {
        colN = 'diseases';
    }


    const [value, setValue] = useState(colN);
    const [getLanguage, setLanguage] = useState(props.lang ? props.lang : 'Eng');
    // const [getLanguage, setLanguage] = useState((sessionStorage.getItem('language')!=null)?(sessionStorage.getItem('language')):'Eng');
    // const [count, setCount] = useState(0);
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const tabList = ["Diseases", "Herbs", "Diet Chart", "Home Remedies", "Nutrients A-Z"];

    function fetchData(par) {
        setValue(par);
        localStorage.setItem('tabName', par);
    }

    function CallCollection(props) {
        return (
            <SubTab data={props.tabName} lang={getLanguage}/>
        )
    }

    // const handleChange = (event) => {
    //     setLanguage(event.target.value);
    //     sessionStorage.setItem('language', event.target.value);
    // };

    return (
        <div>
            <div>
                {value === 'diseases' ?
                    (<img src={diseases} alt={value} className='topBanner'/>) :
                    (value === 'herbs' ?
                        (<img src={herbs} alt={value} className='topBanner'/>) :
                        (value === 'dietchart' ?
                            (<img src={diet} alt={value} className='topBanner'/>) :
                            (value === 'homeremedies' ?
                                (<img src={remedies} alt={value} className='topBanner'/>) :
                                <img src={nutrients} alt={value} className='topBanner'/>)))
                }

            </div>
                <Tabs
                    // classes={{root: classes.root, scroller: classes.scroller}}
                      value={value} centered
                      // indicatorColor="primary"
                      // textColor="primary"
                      // variant={"scrollable"}
                      // scrollButtons={"on"}
                > {/* onChange={handleChange} */}
                    {tabList.map((tabName) =>(
                            <Tab
                                style={localStorage.getItem('tabName') === tabName? {
                                    color: "Black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    // textDecorationStyle: "double",
                                    textDecorationLine: "underline",
                                    textDecorationColor: "orange",
                                    textDecorationThickness: "3px",
                                    textShadow: "3px 3px 6px #f3fa2f",
                                } : {fontWeight: "bold",color: "#75b000"}}
                                label={tabName} onClick={() => fetchData(tabName)}/> // onClick={() => setValue(tabName)}
                    ))}
                </Tabs>
            {/*</Box>*/}
            <CallCollection tabName={value} />
        </div>
    );
}