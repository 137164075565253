import React, {useEffect, useState} from 'react';
import "../App.css";
import List from '@mui/material/List';
import {Link} from "react-router-dom";
import DBPath from "./admin/dbPath";
import GetSortOrder, {toTitleCase} from "./hooks/inputHooK";
import FormControl from "@mui/material/FormControl";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import {makeStyles} from "@material-ui/core/styles";

let dbPath = DBPath();
// import {makeStyles} from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import Button from '@material-ui/core/Button';
// import ListItem from '@material-ui/core/ListItem';

// const theme = createTheme({
//     typography: {
//         body1: {
//             fontSize: 12,
//         },
//     },
//     components: {
//         MuiAutocomplete: {
//             styleOverrides: {
//                 root: {
//                     '& label': {
//                         fontSize: 14,
//                     },
//                 },
//                 input: {
//                     fontSize: 12,
//                 },
//                 listbox: {
//                     fontSize: 12,
//                 },
//             },
//         },
//     },
// });

export default function DiseasesList() {
    // const classes = useStyles();
    //-------------- Fetch List -------------
    const [getList, setList] = React.useState('');
    const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    let collection = 'diseases';
    collection = collection.concat(getLanguage);
    useEffect(() => {
        async function fetchList(collection) {
            try{
                let url = `${dbPath}/${collection}`;
                let response = await fetch(url);
                const json = await response.json();
                let menu_sorted = json.sort(GetSortOrder("name"));
                setList(menu_sorted);
            }catch (e) {
                console.error(e);
            }
        }

        fetchList(collection);
    }, [collection]);
    // ------------------x------------------

    return (
        <div>
            <p className="heading" style={{
                margin: "7% 10% 5% 5%",
                padding: "2% 0% 2% 5%",
                backgroundColor: "#8cd674",
            }}>All Diseases</p>
            <List className='sideList' style={{marginLeft: '5%', marginTop: '-5%', paddingRight: '5px'}}>
                {
                    getList ? getList.map(
                        (diseaseName) => <ul>
                            {/*<button  to="/itm">{itemList}</button>*/}
                            <li style={{
                                padding: '3px 0px 3px 0px',
                                margin: '6px 0px 6px 0px',
                                backgroundColor: "#fff",
                            }}><Link
                                // className='sideLink' to={{pathname: "/knowhat", state: {docName:diseaseName, colName:'diseases'} }}
                                className='sideLink' to={{pathname: "/knowhat"}}
                                onClick={() => {
                                    localStorage.setItem('colName', 'diseases');
                                    localStorage.setItem('diseaseDoc', diseaseName.name)
                                }}
                            >{toTitleCase(diseaseName.name)}</Link></li>

                        </ul>
                    ) : <></>
                }
            </List>
        </div>
    );
}

export function DiseasesDropList() {
    // const classes = useStyles();
    const [getList, setList] = useState('');
    const [getLanguage, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'Eng');
    let collection = 'diseases';
    collection = collection.concat(getLanguage);
    useEffect(() => {
        async function fetchList(collection) {
            let url = `${dbPath}/${collection}`;
            let response = await fetch(url);
            const json = await response.json();
            let menu_sorted = json.sort(GetSortOrder("name"));
            setList(menu_sorted);
        }

        fetchList(collection);
    }, [collection]);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <FormControl sx={{width: 1}}>
                        <Autocomplete
                            options={getList}
                            getOptionLabel={(option) => toTitleCase(option.name)}
                            onChange={(event, value) => {
                                localStorage.setItem('colName', 'diseases');
                                localStorage.setItem('diseaseDoc', value.name)
                                window.location.assign("/knowhat");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Diseases"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    );
}