import React from "react";

function DBPath(){
    let dbPath = '';
        return(
            dbPath = 'https://node.dhanvantary.com/3/query/localhost/27017/dbsweb'
            // dbPath = 'http://localhost:3001/3/query/localhost/27017/dbsweb'
            // dbPath = 'http://localhost:3001/2/query/dbsweb'
            // dbPath = 'https://node.dhanvantary.com/2/query/dbsweb'
        );
    }
export default DBPath;